import './ServerError.scss';

export interface ServerErrorProps {
  error: string;
}

export const ServerError = ({ error }: ServerErrorProps) => (
  <div className="server-error-container">
    <div className="error-title">
      An error has occurred loading patient info:
    </div>
    <div className="error-description">{error}</div>
  </div>
);
