import { PDFDownloadLink } from '@react-pdf/renderer';
import { useContext, useState } from 'react';
import { Button } from 'src/components/Button/Button';
import {
  decryptObject,
  encryptObject,
} from 'src/components/Encryption/obfuscationHandler';
import { generatePDF } from 'src/components/GeneratePDF/generatePDF';
import { convertDate } from 'src/components/common/convertDateTime/convertDateTime';
import {
  LoadingSpinner,
  smallSpinner,
} from 'src/components/common/loader/LoadingSpinner';
import {
  AUDIT_TRAIL_PDF,
  CAS_BACK_END_API_URL,
  URL_VERSION,
} from 'src/constants';
import { fetchAuthenticated } from 'src/controllers';
import { Calendar } from 'src/icons/Calendar';
import { ExclamationOutlined } from 'src/icons/ExclamationOutlined';
import { v4 } from 'uuid';
import moment, { Moment } from 'moment';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { primaryBlue, white } from 'src/shared/styles/variables';
import { AduitTrailAjaxResult } from 'src/shared/types/types';
import { DownloadIcon } from 'src/icons/Download';
import { BannerMessageContext } from 'src/shared/contexts/BannerMessageContext';
import { DocumentIcon } from 'src/icons/Document';

export function ExportEventList() {
  const [isExportDatePickerActive, setIsExportDatePickerActive] =
    useState(false);
  const [downloadPDFLink, setDownloadPDFLink] = useState<any>(null);
  const [pDFName, setPDFName] = useState<string>('');
  const [exportingDataInProgress, setExportingDataInProgress] =
    useState(false);
  const [exportDateStart, setExportDateStart] =
    useState<Moment | null>(null);
  const exportDateStartId = v4();
  const [exportDateEnd, setExportDateEnd] =
    useState<Moment | null>(null);
  const exportDateEndId = v4();
  const [dateExportFocused, setExportDateFocused] =
    useState<FocusedInputShape | null>(null);

  const { token, pdfState, setPDFState } =
    useContext(GlobalUserContext);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const handleExportDateRangeClick = () => {
    setIsExportDatePickerActive(!isExportDatePickerActive);
  };

  const handleExportDateFocusChange = (
    focusedInput: FocusedInputShape | null
  ) => {
    setExportDateFocused(focusedInput);
  };

  const handleExportDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setExportDateStart(startDate);
    setExportDateEnd(endDate);
    if (startDate && endDate) {
      setIsExportDatePickerActive(false);
    }
  };

  const handleClearExportDateRange = () => {
    setExportDateStart(null);
    setExportDateEnd(null);
  };

  const exportDatePicker = (
    exportDateStart: any,
    exportDateEnd: any
  ) => (
    <div
      className={`date-picker ${!dateExportFocused ? 'compact' : ''}`}
    >
      <DateRangePicker
        startDate={exportDateStart}
        startDateId={exportDateStartId}
        endDate={exportDateEnd}
        endDateId={exportDateEndId}
        onDatesChange={handleExportDatesChange}
        focusedInput={dateExportFocused}
        onFocusChange={handleExportDateFocusChange}
        isOutsideRange={(day) => {
          const today = moment();
          if (exportDateStart !== null) {
            // If a start date is selected then it will give you the next 30 days OR up until todays date
            let endDateLimit = exportDateStart
              .clone()
              .add(30, 'days');
            endDateLimit = moment.min(endDateLimit, today);
            return (
              !day.isBetween(
                exportDateStart,
                endDateLimit,
                'day',
                '[]'
              ) || day.isAfter(today.add(30, 'days'), 'day')
            );
          } else if (exportDateEnd !== null) {
            // If an end date is selected first then it will give you the previous 30 days
            let startDateLimit = exportDateEnd
              .clone()
              .subtract(30, 'days');
            return (
              !day.isBetween(
                startDateLimit,
                exportDateEnd,
                'day',
                '[]'
              ) || day.isAfter(today.add(30, 'days'), 'day')
            );
          } else {
            return day.isAfter(today);
          }
        }}
      />
    </div>
  );

  const handleDownload = () => {
    const downloadLink = document.getElementById('downloadButton');
    if (!downloadLink) return;
    downloadLink.click();
  };

  const handleExportEventList = async () => {
    if (exportDateEnd === null || exportDateStart === null) {
      addBannerMessage({
        id: v4(),
        message:
          'Please add a Date Range in order to export the Audit Trail List!',
        type: 'error',
      });
      return;
    }

    setExportingDataInProgress(true);
    setDownloadPDFLink(null);

    const newState = JSON.parse(JSON.stringify(pdfState));
    newState.pdfStarted = true;
    setPDFState(newState);

    const startDate = exportDateStart?.startOf('day')?.toISOString();
    const endDate = exportDateEnd?.endOf('day').toISOString();

    const exportAuditTrailUrl = `${CAS_BACK_END_API_URL}${URL_VERSION}/export_audittrail`;

    const body = JSON.stringify(
      await encryptObject(
        {
          endDateTime: endDate,
          startDateTime: startDate,
        },
        token,
        null
      )
    );

    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response = await fetchAuthenticated<AduitTrailAjaxResult>(
        exportAuditTrailUrl,
        config
      );

      if (response.ok && response?.parsedBody) {
        const decryptData = await decryptObject(
          response.parsedBody?.result,
          token,
          null
        );

        const { file, fileName } = await generatePDF(
          decryptData.documents,
          AUDIT_TRAIL_PDF,
          {
            startDate: convertDate(startDate),
            endDate: convertDate(endDate),
          }
        );
        setPDFName(fileName);
        setDownloadPDFLink(file);
        setExportingDataInProgress(false);

        const newState = JSON.parse(JSON.stringify(pdfState));
        newState.pdfSucceeded = true;
        setPDFState(newState);
      }
    } catch (error) {
      const newState = JSON.parse(JSON.stringify(pdfState));
      newState.pdfStarted = true;
      setPDFState(newState);
      setDownloadPDFLink(null);
      setExportingDataInProgress(false);
    }
  };

  return (
    <div className="export-container">
      <div className="section-header">export event list</div>
      <div className="export-content">
        <div className="description">
          Click here to export the current list of audit trail events.
        </div>
        <div className="export-controls-container">
          <div className="export-date-picker-container">
            <Button
              label="Date Range"
              icon={<Calendar color={white} />}
              iconPlacement="left"
              className="date-range-button primary"
              onClick={handleExportDateRangeClick}
            />
            {isExportDatePickerActive &&
              exportDatePicker(exportDateStart, exportDateEnd)}
          </div>

          <Button
            label="Reset Date Range"
            icon={<ExclamationOutlined color={primaryBlue} />}
            width="200px"
            iconPlacement="left"
            className={'secondary'}
            onClick={handleClearExportDateRange}
          />
        </div>
        <div className="export-controls-container">
          {/* <div className="export-date-picker-container"></div> */}
          <Button
            label={exportingDataInProgress ? '' : 'Create PDF'}
            className="download-button primary"
            icon={
              exportingDataInProgress ? (
                <LoadingSpinner
                  color={white}
                  width={smallSpinner}
                  height={smallSpinner}
                />
              ) : (
                <DocumentIcon />
              )
            }
            onClick={() => handleExportEventList()}
            disabled={exportingDataInProgress}
            iconPlacement="left"
          />
          {downloadPDFLink ? (
            <PDFDownloadLink
              document={downloadPDFLink}
              fileName={pDFName}
            >
              {({ loading }) =>
                loading ? null : (
                  <Button
                    label={'Download PDF'}
                    id="downloadButton"
                    width="200px"
                    className="date-range-button secondary"
                    onClick={() => handleDownload()}
                    icon={<DownloadIcon />}
                    iconPlacement="left"
                  />
                )
              }
            </PDFDownloadLink>
          ) : null}
        </div>
      </div>
    </div>
  );
}
