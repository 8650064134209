import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useContext, useEffect, useState } from 'react';
import Sidebar from './components/Sidebar/Sidebar';
import IdlePopup from './components/IdlePopup/IdlePopup';
import useIdleTimer from './shared/hooks/useIdleTimer';
import { PrivacyContext } from './shared/contexts/PrivacyContext';
import { Section } from './shared/types/types';
import { GlobalUserContext } from './shared/contexts/GlobalUserContext';
import {
  BannerMessage,
  BannerMessageContext,
} from './shared/contexts/BannerMessageContext';
import { useAuthentication } from './shared/hooks/useAuthentication';
import { Main } from './components/Main/Main';
import './App.scss';
import { PageHeader } from './components/Header/Header';
import { ExternalUserVerifyContext } from './shared/contexts/ExternalAprrovalContext';
import { ApprovalPopUpRoutes } from './components/Authentication/popups/routes/ApprovalPopUpRoutes';
import { v4 } from 'uuid';

const App = () => {
  // *************************
  // ******** STATE **********
  // *************************
  const [stateShowIdlePopup, setStateShowIdlePopup] = useState(false);
  const [stateCloseIdlePopup, setStateCloseIdlePopup] =
    useState(false);
  const [stateshowPrivacyOverlay, setStateShowPrivacyOverlay] =
    useState(false);
  const [stateClosePrivacyOverlay, setStateClosePrivacyOverlay] =
    useState(false);
  const [bannerMessages, setBannerMessages] = useState<
    BannerMessage[]
  >([]);
  const [section, setSection] = useState<Section>('dashboard');
  const approvalRouteList = [
    '/handleauth',
    '/handlelogin',
    '/handleapprove',
  ];

  // *************************
  // ******** HOOKS **********
  // *************************
  const {
    checkAuthorization,
    loginWithRedirect,
    logOut,
    postLoginUser,
    webAuthConfig,
    webAuth,
  } = useAuthentication(); // This hook logs in the user
  const { setPatientInfo, pdfState, setPDFState } =
    useContext(GlobalUserContext);
  const location = useLocation();
  const navigate = useNavigate();

  // *************************
  // ******* HANDLERS ********
  // *************************

  //TODO: Fix to stop the re-render happening from useIdleTimer hook. To be replaced with a permanent fix inside useIdleTimer hook.
  const HandleRerender = ({
    ...props
  }: {
    closeIdlePop: boolean;
    closePrivacyOverlay: boolean;
  }) => {
    const { isAuthenticated } = useAuth0();
    const {
      showIdlePopup,
      setShowIdlePopup,
      showPrivacyOverlay,
      setShowPrivacyOverlay,
      resetPrivacyTimer,
      resetIdleTimer,
      startIdleTimer,
      stopIdleTimer,
    } = useIdleTimer();

    useEffect(() => {
      if (isAuthenticated) {
        startIdleTimer();
      } else {
        stopIdleTimer();
      }
    }, [isAuthenticated, startIdleTimer, stopIdleTimer]);

    const resetIdleHelper = () => {
      setShowIdlePopup(false);
      setStateShowIdlePopup(false);
      resetIdleTimer();
      setStateCloseIdlePopup(false);
    };

    const resetPrivacyHelper = () => {
      setShowPrivacyOverlay(false);
      setStateShowPrivacyOverlay(false);
      resetPrivacyTimer();
      setStateClosePrivacyOverlay(false);
    };

    useEffect(() => {
      showIdlePopup && setStateShowIdlePopup(showIdlePopup);
      stateCloseIdlePopup && resetIdleHelper();
      showPrivacyOverlay &&
        setStateShowPrivacyOverlay(showPrivacyOverlay);
      stateClosePrivacyOverlay && resetPrivacyHelper();

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      showIdlePopup,
      stateCloseIdlePopup,
      stateClosePrivacyOverlay,
      showPrivacyOverlay,
    ]);

    return <></>;
  };

  // set the section based on which endpoint you're hitting
  useEffect(() => {
    if (location.pathname.includes('infusion')) {
      setSection('infusion');
      setPatientInfo(null);
    } else if (location.pathname.includes('records')) {
      setSection('records');
      setPatientInfo(null);
    } else if (location.pathname.includes('commissioning')) {
      setSection('commissioning');
      setPatientInfo(null);
    } else if (location.pathname.includes('machine-spec')) {
      setSection('machine-spec');
      setPatientInfo(null);
    } else if (location.pathname.includes('service')) {
      setSection('service');
      setPatientInfo(null);
    } else if (location.pathname.includes('audit-trail')) {
      setSection('audit-trail');
      setPatientInfo(null);
    } else if (location.pathname === '/') {
      setSection('audit-trail');
      setPatientInfo(null);
    } else {
      setPatientInfo(null);
    }
  }, [location, setPatientInfo]);

  const handleResetPrivacy = () => {
    setStateClosePrivacyOverlay(true);
  };

  const handleSectionClick = (sectionName: Section) => {
    if (sectionName === 'infusion') {
      setSection('infusion');
      navigate('/infusion');
    } else if (sectionName === 'records') {
      setSection('records');
      navigate('/records');
    } else if (sectionName === 'commissioning') {
      navigate('/commissioning');
    } else if (sectionName === 'machine-spec') {
      navigate('/machine-spec');
    } else if (sectionName === 'dashboard') {
      setSection('infusion');
      navigate('/');
    } else if (sectionName === 'service') {
      navigate('/service');
    } else if (sectionName === 'audit-trail') {
      navigate('/audit-trail');
    }
  };

  // const handleAddBannerMessage = (message: BannerMessage) => {
  //   setBannerMessages([...bannerMessages, message]);
  // };

  const handleAddBannerMessage = useCallback(
    (message: BannerMessage) => {
      setBannerMessages((prevMessages) => [...prevMessages, message]);
    },
    [setBannerMessages]
  );

  useEffect(() => {
    if (pdfState.pdfFailed) {
      handleAddBannerMessage({
        id: v4(),
        message: 'Error in exporting the Audit Trail List!',
        type: 'error',
      });
      const newState = JSON.parse(JSON.stringify(pdfState));
      newState.pdfFailed = false;
      setPDFState(newState);
    } else if (pdfState.pdfStarted) {
      handleAddBannerMessage({
        id: v4(),
        message:
          'Generating a PDF can take up to 30 seconds! Please be patient.',
        type: 'info',
      });
      const newState = JSON.parse(JSON.stringify(pdfState));
      newState.pdfStarted = false;
      setPDFState(newState);
    } else if (pdfState.pdfSucceeded) {
      handleAddBannerMessage({
        id: v4(),
        message:
          'PDF successfully generated! Use Download button now!',
        type: 'success',
      });
      const newState = JSON.parse(JSON.stringify(pdfState));
      newState.pdfSucceeded = false;
      setPDFState(newState);
    }
  }, [pdfState, setPDFState, handleAddBannerMessage]);

  const handleCloseBanner = (id: string) => {
    const updatedBannerList = bannerMessages.filter(
      (message) => message.id !== id
    );
    setBannerMessages(updatedBannerList);
  };

  const handleCheckAuthorizationClick = () => {
    return checkAuthorization();
  };

  return approvalRouteList.includes(window.location.pathname) ? (
    <ApprovalPopUpRoutes
      webAuth={webAuth}
      webAuthConfig={webAuthConfig}
      postLoginUser={postLoginUser}
    />
  ) : (
    <PrivacyContext.Provider
      value={{
        active: stateshowPrivacyOverlay,
        resetPrivacy: handleResetPrivacy,
      }}
    >
      <BannerMessageContext.Provider
        value={{
          addBannerMessage: handleAddBannerMessage,
        }}
      >
        <ExternalUserVerifyContext.Provider
          value={{
            checkAuthorization: handleCheckAuthorizationClick,
          }}
        >
          <HandleRerender
            closeIdlePop={stateCloseIdlePopup}
            closePrivacyOverlay={stateClosePrivacyOverlay}
          />
          <div className="App">
            <div className="app-content">
              <Sidebar
                onSidebarSectionClick={handleSectionClick}
                onLogout={logOut}
              />
              <div className="page-content">
                <PageHeader section={section} />
                <Main
                  section={section}
                  onSectionClick={handleSectionClick}
                  onInfusionLoad={() => {
                    setSection('infusion');
                  }}
                  onLoginClick={loginWithRedirect}
                  bannerMessages={bannerMessages}
                  onCloseBanner={handleCloseBanner}
                />
              </div>
              {stateShowIdlePopup && (
                <IdlePopup
                  show={stateShowIdlePopup}
                  onClose={(isIdleClose) => {
                    setStateCloseIdlePopup(isIdleClose);
                  }}
                />
              )}
            </div>
          </div>
        </ExternalUserVerifyContext.Provider>
      </BannerMessageContext.Provider>
    </PrivacyContext.Provider>
  );
};

export default App;
