import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function HomeIcon({
  width = 25,
  height = 25,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5018 0.806327C12.046 0.370995 12.8123 0.370995 13.3564 0.806327L23.8564 9.20698C24.218 9.4963 24.4291 9.93843 24.4291 10.4064V12.1968C24.4291 12.8224 23.9311 13.3299 23.3161 13.3309C22.7011 13.3318 22.2031 13.8393 22.2031 14.4649V22.9539C22.2031 23.7966 21.5315 24.4798 20.7031 24.4798H17.1308C16.3023 24.4798 15.6308 23.7966 15.6308 22.9539V18.3902C15.6308 17.5474 14.9592 16.8642 14.1308 16.8642H10.7274C9.89902 16.8642 9.22745 17.5474 9.22745 18.3902V22.9539C9.22745 23.7966 8.55588 24.4798 7.72745 24.4798H4.1551C3.32667 24.4798 2.6551 23.7966 2.6551 22.9539V14.4649C2.6551 13.8393 2.15708 13.3318 1.5421 13.3309C0.92713 13.3299 0.429108 12.8224 0.429108 12.1968V10.4064C0.429108 9.93843 0.640221 9.4963 1.00184 9.20698L11.5018 0.806327ZM11.3355 8.08314C10.5071 8.08314 9.83554 8.76633 9.83554 9.6091V11.3807C9.83554 12.2235 10.5071 12.9067 11.3355 12.9067H13.5227C14.3511 12.9067 15.0227 12.2235 15.0227 11.3807V9.6091C15.0227 8.76633 14.3511 8.08314 13.5227 8.08314H11.3355Z"
        fill={color}
      />
    </svg>
  );
}
