const CryptoJS = require('crypto-js');

module.exports = function decrypt({ data, initVector, Securitykey }) {
  const options = {
    mode: CryptoJS.mode.CBC,
    iv: CryptoJS.enc.Hex.parse(initVector),
  };
  return CryptoJS.AES.decrypt(
    data,
    CryptoJS.enc.Hex.parse(Securitykey),
    options
  ).toString(CryptoJS.enc.Utf8);
};
