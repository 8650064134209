import React from 'react';
import './SubNav.scss';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { useContext } from 'react';
import { PatientRecord, Section } from '../../shared/types/types';
import { useNavigate } from 'react-router-dom';

const getTemporaryTabData = (patientInfo: PatientRecord | null) => {
  const initialTabValue = [{ title: 'Patients', isActive: true }];
  let tabs = initialTabValue;
  if (patientInfo !== null) {
    const label = `${patientInfo.encrypt_givenName?.slice(0, 1)}. ${
      patientInfo.encrypt_familyName
    }`;
    tabs = [
      { title: 'Patients', isActive: false },
      { title: label, isActive: true },
    ];
  }

  return tabs;
};

export interface SubNavProps {
  label?: string;
  section: Section;
}

export function SubNav({ section }: SubNavProps) {
  const globalUserState = useContext(GlobalUserContext);
  const navigate = useNavigate();

  if (section === 'records' || section === 'infusion') {
    const { patientInfo } = globalUserState;

    /*Todo: to be replaced with a more dynamic approach once we figure out which component structure is going to be abstracted
   and figure out how to stop the re-render caused by the ContextProvider updates
  */
    const tabs = getTemporaryTabData(patientInfo);
    return (
      <div
        data-testid="subnav-container"
        className="subnav-container"
      >
        {tabs.map((tab, index) => {
          const closeTab =
            tabs.length > 0 && tab.isActive ? (
              <div
                className={`subnav-tab-close`}
                onClick={() => {
                  navigate('/');
                }}
              >
                {' '}
                X
              </div>
            ) : null;
          return (
            <div
              className={`subnav-tab ${tab.isActive ? 'active' : ''}`}
              key={`tab-${index}`}
            >
              <div
                data-testid={'subnav-tab-title-' + index}
                className="subnav-tab-title"
              >
                {tab.title}
              </div>
              {closeTab}
            </div>
          );
        })}
      </div>
    );
  }

  return <></>;
}
