import { AUDIT_TRAIL_PDF } from 'src/constants';
import { exportAuditTrailPDF } from './auditTrailListPDF';

export const generatePDF = async (
  data: any[],
  template: string,
  additionalData: any
) => {
  let file = null;
  let fileName = '';

  if (template === AUDIT_TRAIL_PDF) {
    file = await exportAuditTrailPDF(data, additionalData);
    fileName = `AuditTrail-${additionalData.startDate}-${additionalData.endDate}.pdf`;
  }

  return {
    file: file,
    fileName: fileName,
  };
};
