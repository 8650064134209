import moment, { Moment } from 'moment';
import { CarrotLeft } from '../../../icons/CarrotLeft';
import { CarrotRight } from '../../../icons/CarrotRight';

export interface RenderMonthProps {
  month: Moment;
  onMonthSelect: (currentMonth: Moment, newMonthVal: string) => void;
  onYearSelect: (
    currentMonth: moment.Moment,
    newMonthVal: string
  ) => void;
}

export function NavPrevIcon() {
  return (
    <div className="date-picker-nav-button">
      <CarrotLeft />
    </div>
  );
}

export function NavNextIcon() {
  return (
    <div className="date-picker-nav-button">
      <CarrotRight />
    </div>
  );
}

export const renderMonthElement = ({
  month,
  onMonthSelect,
  onYearSelect,
}: RenderMonthProps) => {
  let i;
  let years = [];
  for (i = moment().year(); i >= moment().year() - 125; i--) {
    years.push(
      <option value={i} key={`year-${i}`}>
        {i}
      </option>
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        marginTop: '-12px',
        marginBottom: '12px',
      }}
    >
      <div>
        <select
          style={{
            padding: '5px',
            marginRight: ' 6px',
            fontSize: '16px',
          }}
          value={month.month()}
          onChange={(e) => onMonthSelect(month, e.target.value)}
        >
          {moment.months().map((label, value) => (
            <option value={value} key={value}>
              {label}
            </option>
          ))}
        </select>
      </div>
      <div>
        <select
          style={{
            padding: '5px',
            fontSize: '16px',
          }}
          value={month.year()}
          onChange={(e) => onYearSelect(month, e.target.value)}
        >
          {years}
        </select>
      </div>
    </div>
  );
};
