import './AdminEmailList.scss';
import { Table } from '../../../components/common/table/Table/Table';
import { TBody } from 'src/components/common/table/TBody/TBody';
import { AdminEmailRow } from './listRow/AdminEmailRow';
import { AdminEmailListHeader } from './listHeader/AdminEmailListHeader';
import { convertDateTime } from 'src/components/common/convertDateTime/convertDateTime';

export interface AdminEmailListProps {
  adminEmailList: any[];
  setAdminEmailList: any;
  adminListCurrentVersion: number | null;
}

export function AdminEmailList({
  adminEmailList,
  setAdminEmailList,
  adminListCurrentVersion,
}: AdminEmailListProps) {
  return (
    <div className="domain-restriction-list">
      <Table>
        <AdminEmailListHeader />
        <TBody className="domain-restriction-table-body">
          {adminEmailList.map((data, index: number) => (
            <AdminEmailRow
              email={data?.adminEmail}
              index={index}
              adminName={data?.adminName}
              createdDateTime={convertDateTime(data?.createdDateTime)}
              key={`admin_${data?.adminEmail}`}
              setAdminEmailList={setAdminEmailList}
              adminListCurrentVersion={adminListCurrentVersion}
            />
          ))}
        </TBody>
      </Table>
    </div>
  );
}
