import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function MachineSpecIcon({
  width = 24,
  height = 25,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2127_84900)">
        <path
          d="M19 6.3793V4.3793C19 3.31844 18.5786 2.30102 17.8284 1.55088C17.0783 0.80073 16.0609 0.379303 15 0.379303L9 0.379303C7.93913 0.379303 6.92172 0.80073 6.17157 1.55088C5.42143 2.30102 5 3.31844 5 4.3793V6.3793C3.67441 6.38089 2.40356 6.90819 1.46622 7.84552C0.528882 8.78286 0.00158786 10.0537 0 11.3793L0 16.3793C0.00158786 17.7049 0.528882 18.9757 1.46622 19.9131C2.40356 20.8504 3.67441 21.3777 5 21.3793C5 22.175 5.31607 22.938 5.87868 23.5006C6.44129 24.0632 7.20435 24.3793 8 24.3793H16C16.7956 24.3793 17.5587 24.0632 18.1213 23.5006C18.6839 22.938 19 22.175 19 21.3793C20.3256 21.3777 21.5964 20.8504 22.5338 19.9131C23.4711 18.9757 23.9984 17.7049 24 16.3793V11.3793C23.9984 10.0537 23.4711 8.78286 22.5338 7.84552C21.5964 6.90819 20.3256 6.38089 19 6.3793ZM7 4.3793C7 3.84887 7.21071 3.34016 7.58579 2.96509C7.96086 2.59002 8.46957 2.3793 9 2.3793H15C15.5304 2.3793 16.0391 2.59002 16.4142 2.96509C16.7893 3.34016 17 3.84887 17 4.3793V6.3793H7V4.3793ZM17 21.3793C17 21.6445 16.8946 21.8989 16.7071 22.0864C16.5196 22.2739 16.2652 22.3793 16 22.3793H8C7.73478 22.3793 7.48043 22.2739 7.29289 22.0864C7.10536 21.8989 7 21.6445 7 21.3793V17.3793C7 17.1141 7.10536 16.8597 7.29289 16.6722C7.48043 16.4847 7.73478 16.3793 8 16.3793H16C16.2652 16.3793 16.5196 16.4847 16.7071 16.6722C16.8946 16.8597 17 17.1141 17 17.3793V21.3793ZM22 16.3793C22 17.175 21.6839 17.938 21.1213 18.5006C20.5587 19.0632 19.7956 19.3793 19 19.3793V17.3793C19 16.5837 18.6839 15.8206 18.1213 15.258C17.5587 14.6954 16.7956 14.3793 16 14.3793H8C7.20435 14.3793 6.44129 14.6954 5.87868 15.258C5.31607 15.8206 5 16.5837 5 17.3793V19.3793C4.20435 19.3793 3.44129 19.0632 2.87868 18.5006C2.31607 17.938 2 17.175 2 16.3793V11.3793C2 10.5837 2.31607 9.82059 2.87868 9.25798C3.44129 8.69537 4.20435 8.3793 5 8.3793H19C19.7956 8.3793 20.5587 8.69537 21.1213 9.25798C21.6839 9.82059 22 10.5837 22 11.3793V16.3793Z"
          fill={color}
        />
        <path
          d="M18 10.3793H16C15.7348 10.3793 15.4804 10.4847 15.2929 10.6722C15.1054 10.8597 15 11.1141 15 11.3793C15 11.6445 15.1054 11.8989 15.2929 12.0864C15.4804 12.2739 15.7348 12.3793 16 12.3793H18C18.2652 12.3793 18.5196 12.2739 18.7071 12.0864C18.8946 11.8989 19 11.6445 19 11.3793C19 11.1141 18.8946 10.8597 18.7071 10.6722C18.5196 10.4847 18.2652 10.3793 18 10.3793Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2127_84900">
          <rect
            width={width}
            height={width}
            fill={color}
            transform="translate(0 0.379303)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
