export const CAS_FRONT_END_API_URL = 'http://localhost:8000'; // TODO: This needs to point to the CAS front end API when it becomes available
export const CAS_BACK_END_API_URL = process.env.REACT_APP_API_URL; // TODO: This needs to point to the CAS back end API when it becomes available
export const URL_VERSION = process.env.REACT_APP_API_URL_VERSION;
export const TEMP_AUTH = '12345';
export const REDIRECT_LOGIN_URL =
  'https://tae.onelogin.com/trust/saml2/http-post/sso/633d7d13-c3b4-4fbc-a7bf-1c19cb92885c';
//'https://tae.onelogin.com/client/apps/select/1093462198';
// dom events for adding and removing listeners
export const domEventsForIdleTimeout = [
  'load',
  'mousemove',
  'mouseDown',
  'click',
  'scroll',
  'keypress',
  'keyup',
  'keydown',
];

export const WEB_OWNER = 'CAS';
export const AUDIT_TRAIL_PDF = 'audit-trail-pdf';
