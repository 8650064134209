import { Card } from '../../../components/Card/Card';
import { useState } from 'react';
import { PatientRecord } from '../../../shared/types/types';
import { Button } from '../../../components/Button/Button';
import { Edit } from '../../../icons/Edit';
import { XClose } from '../../../icons/XClose';
import { Check } from '../../../icons/Check';
import { PatientInfoDeleteVerify } from './edit/verifyDelete/PatientInfoDeleteVerify';
import { FilingCabinet } from '../../../icons/FilingCabinet';
import './PatientInfo.scss';
import {
  LoadingSpinner,
  mediumSpinner,
} from '../../../components/common/loader/LoadingSpinner';
import { ServerError } from './serverError/ServerError';
import { EditPatientInfo } from './edit/EditPatientInfo';
import { DisplayPatientInfo } from './display/DisplayPatientInfo';

export interface UpdatePatientInfoProps {
  patientData: Partial<PatientRecord> | undefined;
}

export interface PatientInfoProps {
  patientData: PatientRecord | undefined;
  isLoading: boolean;
  profilePhotoString: string;
  error: string;
  tempPatientInfo: Partial<PatientRecord>;
  onUpdatePatientInfo: (props: UpdatePatientInfoProps) => void;
  setTempPatientInfo: (data: Partial<PatientRecord>) => void;
}

export function PatientInfo({
  patientData,
  isLoading,
  error,
  profilePhotoString,
  tempPatientInfo,
  onUpdatePatientInfo,
  setTempPatientInfo,
}: PatientInfoProps) {
  const [isArchivePatientVerify, setIsArchivePatientVerify] =
    useState(false);
  const [isEditingPatientInfo, setIsEditingPatientInfo] =
    useState(false);
  const [expanded, setIsExpanded] = useState(true);

  const [updatedPatientInfo, setUpdatedPatientInfo] =
    useState<Partial<PatientRecord> | undefined>();

  const handlePatientInfoExpand = () => {
    setIsExpanded(!expanded);
  };

  const handleEditOkClick = () => {
    setIsEditingPatientInfo(false);
    onUpdatePatientInfo({
      patientData: updatedPatientInfo,
    });
  };

  const handleEditArchiveClick = () => {
    setIsArchivePatientVerify(true);
  };

  const handlePatientDeleteCancelClick = () => {
    setIsArchivePatientVerify(false);
  };

  const handlePatientArchiveOkClick = () => {
    onUpdatePatientInfo({
      patientData: {
        archived: !patientData?.archived,
      },
    });
    setIsArchivePatientVerify(false);
  };

  const handleOnEditPatientInfoClick = () => {
    setIsEditingPatientInfo(true);
  };

  const handleEditCancelClick = () => {
    setIsEditingPatientInfo(false);
    setUpdatedPatientInfo(undefined);
  };

  const body = error ? (
    <ServerError error={error} />
  ) : isLoading ? (
    <div className="loading-container">
      <LoadingSpinner width={mediumSpinner} height={mediumSpinner} />
    </div>
  ) : (
    <div className="patient-info-container">
      <div className="patient-info-and-avatar">
        {isEditingPatientInfo ? (
          <EditPatientInfo
            archived={patientData?.archived}
            dateOfBirth={patientData?.encrypt_dateOfBirth}
            givenName={patientData?.encrypt_givenName}
            familyName={patientData?.encrypt_familyName}
            gender={patientData?.encrypt_gender}
            medicalId={patientData?.encrypt_medicalId}
            patientType={patientData?.encrypt_type}
            profilePhotoString={profilePhotoString}
            tempPatientInfo={tempPatientInfo}
            isEditingPatientInfo={isEditingPatientInfo}
            setTempPatientInfo={setTempPatientInfo}
          />
        ) : (
          <DisplayPatientInfo
            archived={patientData?.archived}
            dateOfBirth={patientData?.encrypt_dateOfBirth}
            givenName={patientData?.encrypt_givenName}
            familyName={patientData?.encrypt_familyName}
            gender={patientData?.encrypt_gender}
            medicalId={patientData?.encrypt_medicalId}
            patientType={patientData?.encrypt_type}
            profilePhotoString={profilePhotoString}
          />
        )}
      </div>
      <div className="edit-controls">
        {!isEditingPatientInfo && patientData?.archived && (
          <Button
            label={'Unarchive Patient'}
            onClick={handleEditArchiveClick}
            className={'edit-unarchive-button'}
            icon={<FilingCabinet color={'white'} />}
            iconPlacement="left"
          />
        )}

        {!isEditingPatientInfo && !patientData?.archived && (
          <Button
            label={''}
            onClick={handleEditArchiveClick}
            className="edit-archive-button"
            icon={<FilingCabinet color={'white'} />}
            iconPlacement="left"
          />
        )}

        {!isEditingPatientInfo && !patientData?.archived && (
          <Button
            label={'Edit'}
            onClick={handleOnEditPatientInfoClick}
            className="edit-button"
            icon={<Edit />}
            iconPlacement="left"
          />
        )}

        {isEditingPatientInfo && (
          <div className={'edit-save-cancel-container'}>
            <Button
              label={'Cancel'}
              onClick={handleEditCancelClick}
              className="edit-cancel-button"
              icon={<XClose />}
              iconPlacement="left"
            />
            <Button
              label={'Save'}
              onClick={handleEditOkClick}
              className="edit-save-button"
              icon={<Check color={'white'} />}
              iconPlacement="left"
            />
          </div>
        )}
      </div>
      {isArchivePatientVerify && (
        <PatientInfoDeleteVerify
          onCancelClick={handlePatientDeleteCancelClick}
          onOkClick={handlePatientArchiveOkClick}
          archived={patientData?.archived}
        />
      )}
    </div>
  );

  return (
    <div className="patient-info-container">
      <Card
        title={'PATIENT INFORMATION'}
        body={expanded ? body : <></>}
        onExpand={handlePatientInfoExpand}
        expandable={true}
        expanded={expanded}
      />
    </div>
  );
}
