import { IconTypeProps } from '../shared/types/types';

export function DownloadIcon({
  width = 16,
  height = 16,
  color = 'currentColor',
  className,
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_639_12086)">
        <path
          d="M6.58534 12.0813C6.77108 12.2672 6.99161 12.4146 7.23435 12.5152C7.47708 12.6158 7.73726 12.6676 8.00001 12.6676C8.26276 12.6676 8.52294 12.6158 8.76567 12.5152C9.00841 12.4146 9.22894 12.2672 9.41468 12.0813L11.5553 9.94066C11.6701 9.81373 11.7317 9.64751 11.7273 9.47644C11.7229 9.30536 11.6529 9.14252 11.5317 9.02164C11.4106 8.90076 11.2476 8.8311 11.0765 8.82707C10.9055 8.82304 10.7394 8.88495 10.6127 9L8.66201 10.9513L8.66668 0.666666C8.66668 0.489855 8.59644 0.320286 8.47141 0.195262C8.34639 0.0702379 8.17682 0 8.00001 0V0C7.8232 0 7.65363 0.0702379 7.5286 0.195262C7.40358 0.320286 7.33334 0.489855 7.33334 0.666666L7.32734 10.9387L5.38734 9C5.26225 8.87499 5.09262 8.8048 4.91577 8.80486C4.73893 8.80492 4.56935 8.87524 4.44434 9.00033C4.31934 9.12542 4.24914 9.29505 4.24921 9.4719C4.24927 9.64875 4.31958 9.81833 4.44467 9.94333L6.58534 12.0813Z"
          fill={color}
        />
        <path
          d="M15.3333 10.6667C15.1565 10.6667 14.9869 10.7369 14.8619 10.8619C14.7369 10.987 14.6667 11.1565 14.6667 11.3334V14C14.6667 14.1768 14.5964 14.3464 14.4714 14.4714C14.3464 14.5965 14.1768 14.6667 14 14.6667H2C1.82319 14.6667 1.65362 14.5965 1.52859 14.4714C1.40357 14.3464 1.33333 14.1768 1.33333 14V11.3334C1.33333 11.1565 1.2631 10.987 1.13807 10.8619C1.01305 10.7369 0.843477 10.6667 0.666666 10.6667C0.489855 10.6667 0.320286 10.7369 0.195262 10.8619C0.0702379 10.987 0 11.1565 0 11.3334L0 14C0 14.5305 0.210714 15.0392 0.585786 15.4142C0.960859 15.7893 1.46957 16 2 16H14C14.5304 16 15.0391 15.7893 15.4142 15.4142C15.7893 15.0392 16 14.5305 16 14V11.3334C16 11.1565 15.9298 10.987 15.8047 10.8619C15.6797 10.7369 15.5101 10.6667 15.3333 10.6667Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_639_12086">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
