import { SortButton } from '../../../../components/common/buttons/iconButtons/SortButton';
import { Header } from '../../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../../components/common/table/HeaderCell/HeaderCell';
import { SortDirection } from '../../../../shared/types/types';
import '../EventList.scss';

export interface EventListHeaderProps {
  onDateSort: () => void;
  dateSortDirection: SortDirection;
  onActivitySort: () => void;
  activitySortDirection: SortDirection;
  onUserSort: () => void;
  userSortDirection: SortDirection;
  onApplicationSort: () => void;
  applicationSortDirection: SortDirection;
  onRoomSort: () => void;
  roomSortDirection: SortDirection;
}

export function EventListHeader({
  onDateSort,
  onActivitySort,
  onUserSort,
  onApplicationSort,
  onRoomSort,
  dateSortDirection,
  activitySortDirection,
  userSortDirection,
  applicationSortDirection,
  roomSortDirection,
}: EventListHeaderProps) {
  return (
    <Header>
      <HeaderCell>
        {`event date `}
        <SortButton
          sortDirection={dateSortDirection}
          onClick={onDateSort}
        />
      </HeaderCell>
      <HeaderCell>
        {`event description `}
        <SortButton
          sortDirection={activitySortDirection}
          onClick={onActivitySort}
        />
      </HeaderCell>
      <HeaderCell>
        {`user `}
        <SortButton
          sortDirection={userSortDirection}
          onClick={onUserSort}
        />
      </HeaderCell>
      <HeaderCell>
        {`application `}
        <SortButton
          sortDirection={applicationSortDirection}
          onClick={onApplicationSort}
        />
      </HeaderCell>
      <HeaderCell className="rooms-column">
        {`Location `}
        <SortButton
          sortDirection={roomSortDirection}
          onClick={onRoomSort}
        />
      </HeaderCell>
      <HeaderCell className="details-column">details</HeaderCell>
    </Header>
  );
}
