import { useNavigate, useParams } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { AngleLeft } from '../../icons/AngleLeft';
import {
  PatientRecord,
  PatientsAjaxResult,
  PatientsServiceResult,
  Section,
} from '../../shared/types/types';
import { TreatmentPlans } from './treatmentPlans/TreatmentPlans';
import { PatientInfo } from './info/PatientInfo';

import './Patient.scss';
import { PatientInfusion } from './infusion/PatientInfusion';
import { useCallback, useContext, useState, useEffect } from 'react';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { fetchAuthenticated } from '../../controllers';
import {
  CAS_BACK_END_API_URL,
  URL_VERSION,
  WEB_OWNER,
} from '../../constants';
import {
  decryptObject,
  encryptObject,
} from '../../components/Encryption/obfuscationHandler';
import { useTreatmentPlanList } from '../../controllers/treatmentPlan';

export interface PatientProps {
  section: Section;
}

export interface PatientIdInfo {
  id: string | undefined;
  token: string | undefined;
  version?: string;
}

export interface PatientDataProps {
  patientIdInfo: PatientIdInfo;
}

export interface PatchPatientProps {
  patientIdInfo: PatientIdInfo;
  payload: Partial<PatientRecord> | undefined;
}

export const Patient = ({ section }: PatientProps) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const patientId = typeof id === 'string' ? id : '';
  const { token } = useContext(GlobalUserContext);

  const [patientInfo, setPatientInfo] = useState<PatientRecord>();
  const [patientInfoIsLoading, setPatientInfoIsLoading] =
    useState(false);
  const [patientInfoError, setPatientInfoError] = useState<any>();

  const [profilePhotoUrl, setProfilePhotoUrl] = useState<string>('');
  const [imageString, setImageString] = useState<string>('');
  const [tempPatientInfo, setTempPatientInfo] = useState<
    Partial<PatientRecord>
  >({});

  const [isUpdatingTreatmentPlan, setIsUpdatingTreatmentPlan] =
    useState(false);

  const decryptAndSavePatientInfo = useCallback(
    async (
      encryptedPatientInfo: PatientsServiceResult,
      profilePhotoS3Url: string
    ) => {
      const decryptedData = { ...encryptedPatientInfo };
      await decryptObject(decryptedData, token, null);
      setProfilePhotoUrl(profilePhotoS3Url);
      setPatientInfo(decryptedData.patients[0]);
    },
    [token]
  );

  const getPatientInfo = useCallback(
    async ({
      patientIdInfo: { id: patientId, token },
    }: PatientDataProps) => {
      const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}?archived=true`;
      try {
        setPatientInfoIsLoading(true);
        const config: RequestInit = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await fetchAuthenticated<PatientsAjaxResult>(
          url,
          config
        );

        if (response.ok) {
          if (response.parsedBody?.result?.patients) {
            await decryptAndSavePatientInfo(
              response.parsedBody.result,
              response.parsedBody.result?.profilePhotoUrl
            );
          }
        }

        setPatientInfoIsLoading(false);
      } catch (e) {
        setPatientInfoIsLoading(false);
        setPatientInfoError(e);
      }
    },
    [decryptAndSavePatientInfo]
  );

  const patchPatientInfo = async ({
    patientIdInfo: { id: patientId, token, version },
    payload,
  }: PatchPatientProps) => {
    if (!payload) return;
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}?archived=true`;
    try {
      setPatientInfoIsLoading(true);
      const config: RequestInit = {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(
          await encryptObject(
            {
              _version: version,
              ...tempPatientInfo,
              owner: WEB_OWNER,
            },
            token,
            null
          )
        ),
      };
      const response = await fetchAuthenticated<PatientsAjaxResult>(
        url,
        config
      );

      if (response.ok) {
        if (response.parsedBody?.result?.patients) {
          await decryptAndSavePatientInfo(
            response.parsedBody.result,
            response?.parsedBody?.result?.profilePhotoUrl
          );
        }
      }
      setPatientInfoIsLoading(false);
    } catch (e) {
      setPatientInfoIsLoading(false);
    }
  };

  const getProfilePhotoUrl = useCallback(async (photoUrl: string) => {
    if (!photoUrl || photoUrl === '') return;
    try {
      await fetch(photoUrl)
        .then((response) => response.text())
        .then((result) => {
          setImageString(result);
        });
    } catch (error) {
      setImageString('');
    }
  }, []);

  useEffect(() => {
    if (!profilePhotoUrl || profilePhotoUrl === '') return;
    getProfilePhotoUrl(profilePhotoUrl);
  }, [profilePhotoUrl, getProfilePhotoUrl]);

  // get patient info when the component mounts

  useEffect(() => {
    getPatientInfo({ patientIdInfo: { id, token } });
  }, [getPatientInfo, id, token]);

  const {
    treatmentPlanListData,
    treatmentPlanListDataIsLoading,
    treatmentPlanListDataError,
    treatmentListMutate,
  } = useTreatmentPlanList({
    patientId,
  });

  const handleUpdateTreatmentPlan = async () => {
    if (treatmentListMutate) {
      setIsUpdatingTreatmentPlan(true);
      await treatmentListMutate();
      setIsUpdatingTreatmentPlan(false);
    }
  };

  const handleUpdatePatientInfo = async () => {
    if (!patientInfo) return;
    await patchPatientInfo({
      patientIdInfo: {
        id: patientId,
        token,
        version: patientInfo._version,
      },
      payload: tempPatientInfo,
    });
  };

  return (
    <div className="patient-container">
      <PatientInfo
        patientData={patientInfo}
        isLoading={patientInfoIsLoading}
        error={patientInfoError}
        profilePhotoString={imageString}
        onUpdatePatientInfo={handleUpdatePatientInfo}
        setTempPatientInfo={setTempPatientInfo}
        tempPatientInfo={tempPatientInfo}
      />

      <PatientInfusion patientData={patientInfo} section={section} />

      <TreatmentPlans
        onUpdateTreatmentPlan={handleUpdateTreatmentPlan}
        patientData={patientInfo}
        treatmentPlanData={treatmentPlanListData ?? []}
        isLoading={treatmentPlanListDataIsLoading}
        isUpdatingTreatmentPlan={isUpdatingTreatmentPlan}
        error={treatmentPlanListDataError}
      />

      <div className="patient-controls">
        <Button
          label={'Back'}
          className={'go-back-button'}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
};
