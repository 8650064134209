import { IconTypeProps } from '../shared/types/types';

export function Upload({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_882_25987)">
        <path
          d="M11.007 2.578L11 18.016C11 18.2812 11.1054 18.5356 11.2929 18.7231C11.4804 18.9106 11.7348 19.016 12 19.016C12.2652 19.016 12.5196 18.9106 12.7071 18.7231C12.8946 18.5356 13 18.2812 13 18.016L13.007 2.595L15.919 5.508C16.1065 5.69547 16.3608 5.80078 16.626 5.80078C16.8912 5.80078 17.1455 5.69547 17.333 5.508C17.5205 5.32047 17.6258 5.06616 17.6258 4.801C17.6258 4.53583 17.5205 4.28152 17.333 4.094L14.122 0.878994C13.8434 0.600214 13.5126 0.379064 13.1485 0.228179C12.7844 0.0772949 12.3941 -0.000366211 12 -0.000366211C11.6059 -0.000366211 11.2156 0.0772949 10.8515 0.228179C10.4874 0.379064 10.1566 0.600214 9.87799 0.878994L6.66699 4.091C6.47952 4.27852 6.37421 4.53283 6.37421 4.798C6.37421 5.06316 6.47952 5.31747 6.66699 5.505C6.85452 5.69247 7.10883 5.79778 7.37399 5.79778C7.63916 5.79778 7.89347 5.69247 8.08099 5.505L11.007 2.578Z"
          fill={color}
        />
        <path
          d="M22 17V21C22 21.2652 21.8946 21.5196 21.7071 21.7071C21.5196 21.8947 21.2652 22 21 22H3C2.73478 22 2.48043 21.8947 2.29289 21.7071C2.10536 21.5196 2 21.2652 2 21V17C2 16.7348 1.89464 16.4804 1.70711 16.2929C1.51957 16.1054 1.26522 16 1 16C0.734783 16 0.480429 16.1054 0.292893 16.2929C0.105357 16.4804 0 16.7348 0 17L0 21C0 21.7957 0.31607 22.5587 0.878679 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7957 24 21V17C24 16.7348 23.8946 16.4804 23.7071 16.2929C23.5196 16.1054 23.2652 16 23 16C22.7348 16 22.4804 16.1054 22.2929 16.2929C22.1053 16.4804 22 16.7348 22 17Z"
          fill={color}
        />
      </g>
    </svg>
  );
}
