import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';
import { grey, primaryBlue } from '../shared/styles/variables';

export function RadioButtonSelected({
  width = 21,
  height = 21,
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10.5" cy="10.5" r="10" stroke={grey} />
      <circle cx="10.5" cy="10.5" r="7.5" fill={primaryBlue} />
    </svg>
  );
}
