import { IconTypeProps } from '../shared/types/types';

export function UserAvatarEmpty({
  width = 101,
  height = 101,
  color = '#A7A7A7',
  className = '',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 101 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <circle
        cx="50.3988"
        cy="50.3988"
        r="49.3988"
        fill="white"
        stroke={color}
        strokeWidth="2"
      />
      <path
        d="M50.3988 47.2847C47.6272 47.2847 44.9178 46.4628 42.6133 44.923C40.3088 43.3832 38.5127 41.1946 37.4521 38.6339C36.3914 36.0733 36.1139 33.2557 36.6546 30.5373C37.1953 27.819 38.53 25.322 40.4898 23.3622C42.4496 21.4024 44.9466 20.0677 47.6649 19.527C50.3833 18.9863 53.2009 19.2638 55.7615 20.3245C58.3221 21.3851 60.5108 23.1813 62.0506 25.4858C63.5904 27.7903 64.4123 30.4996 64.4123 33.2712C64.4081 36.9866 62.9304 40.5486 60.3033 43.1757C57.6761 45.8028 54.1141 47.2806 50.3988 47.2847ZM70.6405 81.5398H30.1571C29.3312 81.5398 28.5391 81.2117 27.9551 80.6277C27.3711 80.0437 27.043 79.2516 27.043 78.4257V76.8687C27.043 70.6743 29.5037 64.7337 33.8838 60.3536C38.2638 55.9736 44.2045 53.5129 50.3988 53.5129C56.5931 53.5129 62.5338 55.9736 66.9138 60.3536C71.2939 64.7337 73.7546 70.6743 73.7546 76.8687V78.4257C73.7546 79.2516 73.4265 80.0437 72.8425 80.6277C72.2585 81.2117 71.4664 81.5398 70.6405 81.5398Z"
        fill={color}
      />
    </svg>
  );
}
