import { format } from 'date-fns';
import { useState } from 'react';
import { Button } from '../../../../../../components/Button/Button';
import { AlertIcon } from '../../../../../../icons/AlertIcon';
import { Edit } from '../../../../../../icons/Edit';
import { Floppy } from '../../../../../../icons/Floppy';
import { XClose } from '../../../../../../icons/XClose';
import {
  Beam,
  PatientRecord,
  TreatmentPlanRecord,
} from '../../../../../../shared/types/types';
import { HorizontalRule } from '../../common/HorizontalRule';
import { RSApprovalFail } from '../../common/RSApprovalFail';
import './PlanOverview.scss';
import '../../../../../../shared/styles/Common.scss';
import {
  LoadingSpinner,
  smallSpinner,
} from '../../../../../../components/common/loader/LoadingSpinner';
import { white } from '../../../../../../shared/styles/variables';

export type TreatmentStatusType = 'Active' | 'Completed';

export interface PlanOverviewProps {
  patientData: PatientRecord;
  planData: TreatmentPlanRecord;
  onStatusChange: (status: TreatmentStatusType) => void;
  onCourseNameChange: (name: string) => void;
  onApproveClick: () => void;
  onUnapproveClick: () => void;
  isLoadingApproval: boolean;
  isUpdatingTreatmentPlan: boolean;
}

export function PlanOverview({
  patientData,
  planData,
  onApproveClick,
  onUnapproveClick,
  isLoadingApproval,
  isUpdatingTreatmentPlan,
}: PlanOverviewProps) {
  const [editing, setEditing] = useState(false);
  const [courseName, setCourseName] = useState(
    planData.encrypt_planCourse
  );
  const [courseNameEdit, setCourseNameEdit] = useState('');

  const handleCourseNameEditChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setCourseNameEdit(e.target.value);
  };
  const handleEditClick = () => {
    setEditing(true);
  };

  const handleSaveClick = () => {
    setEditing(false);
    setCourseName(courseNameEdit);
    setCourseNameEdit(courseNameEdit);
  };

  const handleCancelClick = () => {
    setEditing(false);
    setCourseNameEdit('');
  };

  const handleApproveClick = () => {
    onApproveClick();
  };

  const handleUnapproveClick = () => {
    onUnapproveClick();
  };

  return (
    <div className="plan-overview-container" data-cy="plan-overview">
      <div className="title">
        Plan Name: {planData?.encrypt_planName ?? 'ERROR'}
      </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Plan Type:</div>
            <div className="data">
              {patientData?.encrypt_type ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Plan Status:</div>
            <div className="data">
              {planData?.planStatus ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Prescription Dose:</div>
            <div className="data lower-case">
              {`${planData?.prescription.targetPrescriptionDose_cGy} cGy` ??
                'ERROR'}
            </div>
          </div>
        </div>
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Treatment Site</div>
            <div className="data">
              {planData?.encrypt_treatmentSite ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Course Name:</div>
            {editing ? (
              <input
                value={courseNameEdit}
                onChange={handleCourseNameEditChange}
                className={'input'}
                placeholder={courseName}
                data-cy="course-name-edit-field"
              />
            ) : (
              <div className="data">{courseName}</div>
            )}
          </div>
          <div className="label-and-data">
            <div className="label">Treatment Status:</div>
            <div className="data">
              {planData.casApproval?.approved &&
              planData.rsApproval.approved
                ? 'Approved'
                : 'Not Approved' ?? 'ERROR'}
            </div>
          </div>
        </div>
      </div>
      <HorizontalRule dashed />
      <div className="subtitle">BEAMS</div>
      {planData?.beamList.map((data: Beam, index: number) => (
        <div className="beam-data" key={`${data._id}_${index}`}>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Beam Name:</div>
              <div className="data">{data.beamName}</div>
            </div>
            <div className="label-and-data">
              <div className="label">Collimator:</div>
              <div className="data lower-case">
                {`${data.beamCollimator.collimatorSize_mm} mm`}
              </div>
            </div>
          </div>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Assumed Boron 10:</div>
              <div className="data lower-case">
                {`${data.beamOutput.plannedBoronConcentration_mgPerKg} mg/kg`}
              </div>
            </div>
            <div className="label-and-data">
              <div className="label">Monitor Unit:</div>
              <div className="data">{`${data.beamOutput.beamMU} MU`}</div>
            </div>
          </div>
        </div>
      ))}

      <HorizontalRule dashed />
      <div className="subtitle">approvals </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Raystation approval:</div>
            <div className="data">
              {planData.rsApproval.approved ? (
                format(
                  new Date(
                    planData.rsApproval.approvedDateTime ?? 'ERROR'
                  ),
                  'dd-MMM-yyyy HH:mm:ss'
                )
              ) : (
                <RSApprovalFail />
              )}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">CAS approval:</div>
            <div
              className={`data ${
                planData.casApproval == null
                  ? 'approval-required'
                  : ''
              }`}
              data-cy="cas-approval-field"
            >
              {planData.casApproval != null &&
              planData.casApproval?.approved
                ? format(
                    new Date(planData.casApproval?.approvedDateTime),
                    'dd-MMM-yyyy HH:mm:ss'
                  )
                : 'REQUIRES APPROVAL'}
            </div>
          </div>
        </div>
      </div>
      <div className="controls">
        {!planData.casApproval?.approved && (
          <Button
            label={
              isLoadingApproval || isUpdatingTreatmentPlan
                ? ''
                : 'Approve'
            }
            icon={
              isLoadingApproval || isUpdatingTreatmentPlan ? (
                <LoadingSpinner
                  color={white}
                  width={smallSpinner}
                  height={smallSpinner}
                />
              ) : (
                <AlertIcon />
              )
            }
            iconPlacement={'left'}
            className={'button-primary'}
            onClick={handleApproveClick}
            data-cy="approve-button"
            type="primary"
            disabled={isLoadingApproval || isUpdatingTreatmentPlan}
          />
        )}
        {planData.casApproval?.approved && (
          <Button
            label={
              isLoadingApproval || isUpdatingTreatmentPlan
                ? ''
                : 'Unapprove'
            }
            icon={
              isLoadingApproval || isUpdatingTreatmentPlan ? (
                <LoadingSpinner
                  color={white}
                  width={smallSpinner}
                  height={smallSpinner}
                />
              ) : (
                <AlertIcon />
              )
            }
            iconPlacement={'left'}
            className={'unapprove-button'}
            onClick={handleUnapproveClick}
            data-cy="approve-button"
            disabled={isLoadingApproval || isUpdatingTreatmentPlan}
          />
        )}
        {!editing && (
          <Button
            label={'Edit'}
            icon={<Edit />}
            iconPlacement={'left'}
            className={'edit-button'}
            onClick={handleEditClick}
            data-cy="edit-button"
            type="secondary"
          />
        )}
        {editing && (
          <>
            <Button
              label={'Save'}
              icon={<Floppy />}
              iconPlacement={'left'}
              className={'save-button'}
              onClick={handleSaveClick}
              data-cy="save-button"
              type="primary"
            />
            <Button
              label={'Cancel'}
              icon={<XClose />}
              iconPlacement={'left'}
              className={'cancel-button'}
              onClick={handleCancelClick}
              data-cy="cancel-button"
              type="secondary"
            />
          </>
        )}
      </div>
    </div>
  );
}
