import clsx from 'clsx';
import moment, { Moment } from 'moment';
import 'react-dates/constants';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import { parseISO, isValid } from 'date-fns';
import { SingleDatePicker } from 'react-dates';
import {
  BloodSample,
  BloodSamplesAjaxResult,
} from '../PatientBloodSamples';
import { useCallback, useContext, useEffect, useState } from 'react';
import { Check } from '../../../../../shared/assets/check';
import { Pencil } from '../../../../../shared/assets/pencil';
import { XClose } from '../../../../../shared/assets/x-close';
import { fetchAuthenticated } from '../../../../../controllers';
import { Button } from '../../../../../components/Button/Button';
import { Row } from '../../../../../components/common/table/Row/Row';
import {
  CAS_BACK_END_API_URL,
  URL_VERSION,
  WEB_OWNER,
} from '../../../../../constants';
import { Cell } from '../../../../../components/common/table/Cell/Cell';
import { GlobalUserContext } from '../../../../../shared/contexts/GlobalUserContext';
import { FlexContainer } from '../../../../../components/FlexContainer/FlexContainer';
import {
  convertDate,
  convertTime,
} from '../../../../../components/common/convertDateTime/convertDateTime';
import './react_dates_overrides.scss';
import {
  LoadingSpinner,
  smallSpinner,
} from '../../../../../components/common/loader/LoadingSpinner';
import './SampleHistoryRow.scss';
import {
  neutral300,
  neutral50,
  primaryBlue400,
  warning,
} from '../../../../../shared/styles/variables';
import { TextInput } from '../../../../../components/TextInput/TextInput';
import '../../../../../components/TextInput/TextInput.scss';
import {
  decryptObject,
  encryptObject,
} from '../../../../../components/Encryption/obfuscationHandler';

export interface SampleHistoryRowProps {
  sample: BloodSample;
  index: number;
  patientId: string | undefined;
  updateApiData: any;
}

export const SampleHistoryRow = ({
  sample,
  index,
  patientId,
  updateApiData,
}: SampleHistoryRowProps) => {
  const [edit, setEdit] = useState(false);
  const [status, setStatus] = useState('');
  const { token } = useContext(GlobalUserContext);
  const [tempDrawDate, setTempDrawDate] = useState<any>(null);
  const [drawDate, setDrawDate] = useState<string>(
    convertDate(sample.encrypt_bloodSampleCollectionDateTime)
  );
  const [tempDrawTime, setTempDrawTime] = useState<any>(null);
  const [drawTime, setDrawTime] = useState<string>(
    convertTime(sample.encrypt_bloodSampleCollectionDateTime)
  );
  const [sampleId, setSampleId] = useState<string>(
    sample.encrypt_bloodSampleCollectionId
  );
  const [editId, setEditId] = useState<string | null>();
  const [focused, setFocused] = useState<boolean>(false);
  const [enableButton, setEnableButton] = useState<boolean>(true);

  const getISOTime = useCallback(() => {
    const [sampleDay, sampleMonth, sampleYear] = convertDate(
      sample.encrypt_bloodSampleCollectionDateTime
    ).split('-');
    const [sampleHour, sampleMinutes, sampleSeconds] = convertTime(
      sample.encrypt_bloodSampleCollectionDateTime
    ).split(':');

    const year = tempDrawDate ? tempDrawDate.get('year') : sampleYear;
    const month = tempDrawDate
      ? tempDrawDate.get('month')
      : 'JanFebMarAprMayJunJulAugSepOctNovDec'.indexOf(sampleMonth) /
        3;
    const days = tempDrawDate ? tempDrawDate.get('date') : sampleDay;
    const hours = tempDrawTime
      ? tempDrawTime.slice(0, 2)
      : sampleHour;
    const minutes = tempDrawTime
      ? tempDrawTime.slice(3, 5)
      : sampleMinutes;
    const seconds = tempDrawTime
      ? tempDrawTime.slice(6)
      : sampleSeconds;

    const time = new Date(year, month, days, hours, minutes, seconds);
    let timeISO;
    try {
      timeISO = time.toISOString();
    } catch (e) {
      timeISO = 'ERROR';
    }

    return timeISO;
  }, [
    tempDrawDate,
    tempDrawTime,
    sample.encrypt_bloodSampleCollectionDateTime,
  ]);

  useEffect(() => {
    if (
      tempDrawTime &&
      (tempDrawTime.length !== 8 ||
        tempDrawTime.split(':').length !== 3)
    )
      return setEnableButton(false);
    setEnableButton(validTime(getISOTime()));
  }, [tempDrawDate, tempDrawTime, getISOTime]);

  const validTime = (timeToCheck: string) => {
    const unixTimeToCheck = new Date(timeToCheck).getTime();

    const currentUnixTime = new Date().getTime();

    return unixTimeToCheck < currentUnixTime ? true : false;
  };

  const handleBloodSampleUpdate = async () => {
    if (!tempDrawDate && !tempDrawTime && !editId)
      return setStatus('');
    setStatus('Uploading Data');

    const url =
      CAS_BACK_END_API_URL +
      `${URL_VERSION}/patients/${patientId}/blood-samples/${sample._id}`;

    const updatedId = editId ? editId : sampleId;

    let utcFormatedDrawDateTime =
      !tempDrawDate && !tempDrawTime
        ? sample.encrypt_bloodSampleCollectionDateTime
        : getISOTime();

    if (
      !isValid(parseISO(utcFormatedDrawDateTime)) ||
      !validTime(utcFormatedDrawDateTime)
    )
      return setStatus('Date/Time Error');

    setSampleId(updatedId);
    setDrawDate(convertDate(utcFormatedDrawDateTime));
    setDrawTime(convertTime(utcFormatedDrawDateTime));

    const body = {
      _version: sample._version,
      encrypt_bloodSampleCollectionId: updatedId,
      encrypt_bloodSampleCollectionDateTime: utcFormatedDrawDateTime,
      bloodSampleCollectionTime: utcFormatedDrawDateTime,
      owner: WEB_OWNER,
    };
    const config: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(await encryptObject(body, token, null)),
      headers: { Authorization: `Bearer ${token}` },
    };

    try {
      const response =
        await fetchAuthenticated<BloodSamplesAjaxResult>(url, config);

      if (response?.ok) {
        const data = await decryptObject(
          response.parsedBody?.result,
          token,
          null
        );
        setStatus('Added!');
        updateApiData(data?.sample);
      }
    } catch (error) {
      if (error) setStatus('Error in Upload');
    }
  };

  const dayAdjustment =
    new Date().getUTCHours() - new Date().getHours() + 1;

  return (
    <Row
      index={index}
      key={sample._id}
      style={{ paddingRight: '24px' }}
      classes={'sample-history-row-container'}
    >
      <Cell>
        {status === 'Editing' ? (
          <SingleDatePicker
            placeholder={drawDate}
            date={tempDrawDate}
            onDateChange={(tempDrawDate: Moment | null) => {
              setTempDrawDate(tempDrawDate);
              setFocused(!focused);
            }}
            appendToBody={true}
            focused={focused}
            onFocusChange={() => setFocused(!focused)}
            id="singleDatePicker"
            numberOfMonths={1}
            displayFormat="dd-MMM-yyyy"
            isOutsideRange={(day: Moment) =>
              day.isAfter(moment().add(dayAdjustment, 'hours'))
            }
            noBorder
            small
          />
        ) : (
          drawDate
        )}
      </Cell>
      <Cell>
        {status === 'Editing' ? (
          <TextInput
            value={tempDrawTime || drawTime}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTempDrawTime(e.target.value)
            }
            width={95}
            borderColor={!enableButton ? warning : ''}
          />
        ) : (
          drawTime
        )}
      </Cell>
      <Cell>
        {status === 'Editing' ? (
          <TextInput
            value={editId || sampleId}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setEditId(e.target.value)
            }
          />
        ) : (
          sampleId || 'N/A'
        )}
      </Cell>
      <Cell>
        {sample.encrypt_result ? sample.encrypt_result : 'N/A'}
      </Cell>
      <Cell>
        {sample?.user?.name
          ? sample?.user?.name
          : sample?.user?.id
          ? sample?.user?.id
          : 'Unknown'}
      </Cell>
      <Cell>
        <FlexContainer justifyContent="center">
          <>
            {edit && (
              <div className="actionButtons">
                <div className="cancel-button-container">
                  <Button
                    icon={<XClose fill={primaryBlue400} />}
                    onClick={() => {
                      setEdit(false);
                      setStatus('');
                    }}
                    width={'40px'}
                    height={'40px'}
                    className={clsx(
                      'square-icon-button',
                      'cancel-button'
                    )}
                  ></Button>
                </div>
                <Button
                  icon={<Check fill={neutral50} />}
                  onClick={() => {
                    setEdit(false);
                    handleBloodSampleUpdate();
                  }}
                  width={'40px'}
                  height={'40px'}
                  className={clsx(
                    'square-icon-button',
                    'save-button'
                  )}
                  background-color={!enableButton ? neutral300 : null}
                  disabled={!enableButton}
                ></Button>
              </div>
            )}
          </>
          <div>
            {status === 'Uploading Data' ? (
              <LoadingSpinner
                width={smallSpinner}
                height={smallSpinner}
              />
            ) : (
              !edit && (
                <Button
                  icon={<Pencil fill={primaryBlue400} />}
                  onClick={() => {
                    setEdit(true);
                    setStatus('Editing');
                  }}
                  width={'40px'}
                  height={'40px'}
                  className={clsx(
                    'square-icon-button',
                    'edit-button-enabled'
                  )}
                ></Button>
              )
            )}
          </div>
        </FlexContainer>
      </Cell>
    </Row>
  );
};
