import React, { ReactNode } from 'react';
import './Cell.scss';

export interface CellProps {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
  capitalized?: boolean;
  style?: React.CSSProperties;
}

export function Cell({
  children,
  onClick,
  className = '',
  capitalized,
  style,
  ...rest
}: CellProps) {
  return (
    <td
      className={`cell-container ${className} ${
        capitalized ? 'capitalized' : ''
      }`}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {children}
    </td>
  );
}
