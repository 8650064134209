import { FilterButton } from '../../../components/common/buttons/iconButtons/FilterButton';
import { SortButton } from '../../../components/common/buttons/iconButtons/SortButton';
import { Header } from '../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../components/common/table/HeaderCell/HeaderCell';
import {
  PatientListSortType,
  Sort,
} from '../../../shared/types/types';

export interface PatientListHeaderProps {
  onFilterDateClick: () => void;
  onSortClick: (type: PatientListSortType) => void;
  sort: Sort;
  showArchived: boolean;
}

export function PatientListHeader({
  onFilterDateClick,
  onSortClick,
  sort,
  showArchived,
}: PatientListHeaderProps) {
  return (
    <Header>
      <HeaderCell>
        <div>recent infusion date</div>
        <FilterButton
          onClick={() => onFilterDateClick()}
          width={24}
          height={24}
        />
      </HeaderCell>
      <HeaderCell>
        <div>first name</div>
        <SortButton
          onClick={() => onSortClick('firstName')}
          width={24}
          height={24}
          sortDirection={
            sort.type === 'firstName' ? sort.direction : 'none'
          }
        />
      </HeaderCell>
      <HeaderCell>
        <div>last name</div>
        <SortButton
          onClick={() => onSortClick('lastName')}
          width={24}
          height={24}
          sortDirection={
            sort.type === 'lastName' ? sort.direction : 'none'
          }
        />
      </HeaderCell>
      <HeaderCell>
        <div>medical ID</div>
        <SortButton
          onClick={() => onSortClick('patientId')}
          width={24}
          height={24}
          sortDirection={
            sort.type === 'patientId' ? sort.direction : 'none'
          }
        />
      </HeaderCell>
      {showArchived && (
        <HeaderCell>
          <div>archived</div>
          <SortButton
            onClick={() => onSortClick('archived')}
            width={24}
            height={24}
            sortDirection={
              sort.type === 'archived' ? sort.direction : 'none'
            }
          />
        </HeaderCell>
      )}
      <HeaderCell>action</HeaderCell>
    </Header>
  );
}
