import { ReactNode } from 'react';
import './TBody.scss';

export interface TbodyProps {
  children: ReactNode | JSX.Element | string | undefined;
  className?: string;
  onScroll?: React.EventHandler<React.UIEvent>;
}

export function TBody({ children, className, ...rest }: TbodyProps) {
  return (
    <tbody className={`body-container ${className}`} {...rest}>
      {children}
    </tbody>
  );
}
