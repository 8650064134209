import { Section } from '../../shared/types/types';
import Navbar from '../Navbar/Navbar';
import { SubNav } from '../SubNav/SubNav';

export interface PageHeaderProps {
  section: Section;
}

export function PageHeader({ section }: PageHeaderProps) {
  return (
    <header>
      <Navbar section={section} />
      <SubNav section={section} />
    </header>
  );
}
