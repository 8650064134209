import { Upload } from '../../../../icons/Upload';
import './PatientAvatar.scss';
import * as imageConversion from 'image-conversion';
import { createRef, useState } from 'react';
import {
  PatientRecord,
  UploadErrorType,
} from 'src/shared/types/types';
import { Button } from '../../../../components/Button/Button';
import { UserAvatarEmpty } from '../../../../icons/UserAvatarEmpty';

export interface PatientAvatarProps {
  editing?: boolean;
  profilePhotoString: string | undefined;
  tempPatientInfo?: Partial<PatientRecord>;
  isEditingPatientInfo?: boolean;
  onUploadImageClick?: (
    dataKey: string | undefined,
    valueToUpdate: any
  ) => void;
}

export function PatientAvatar({
  editing,
  onUploadImageClick,
  profilePhotoString,
  isEditingPatientInfo,
  tempPatientInfo,
}: PatientAvatarProps) {
  const [uploadError, setUploadError] =
    useState<UploadErrorType | null>(null);

  const inputRef = createRef<HTMLInputElement>();

  const handleUploadImageClick = () => {
    if (!inputRef.current) return;
    inputRef.current.click();
  };

  const handleUploadImage = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();
    if (
      !e.target.files ||
      !e.target.files[0] ||
      e.target.files[0].name === ''
    )
      return;

    setUploadError(null);

    const image = e.target.files[0];
    const imageType = image.type;

    if (imageType.indexOf('image') === -1) {
      setUploadError('invalid-file-type');
      return;
    }
    let resizedImage;
    if (image.size >= 10000000) {
      // 10MB
      setUploadError('file-size-too-big');
      return;
    } else if (image.size >= 200000) {
      // 200kB
      // resizes Images greater than maxImageSize (200kB)
      resizedImage = await imageConversion.compressAccurately(
        image,
        200
      );
    } else {
      resizedImage = image;
    }

    let reader = new FileReader();
    reader.readAsDataURL(resizedImage);
    reader.onloadend = async function () {
      if (onUploadImageClick) {
        await onUploadImageClick(
          'encrypt_profilePhotoImageString',
          reader.result
        );
      }
    };
  };

  const errorText =
    uploadError === 'invalid-file-type'
      ? 'Invalid File Type'
      : uploadError === 'file-size-too-big'
      ? 'File Size Exceeded 10MB!'
      : '';

  const avatarImage =
    isEditingPatientInfo &&
    tempPatientInfo?.encrypt_profilePhotoImageString ? (
      <img
        className="avatar-image"
        src={
          tempPatientInfo?.encrypt_profilePhotoImageString
            ? tempPatientInfo?.encrypt_profilePhotoImageString
            : profilePhotoString
        }
        alt="Avatar"
      />
    ) : !profilePhotoString ? (
      <UserAvatarEmpty className="avatar-image" />
    ) : (
      <img
        className="avatar-image"
        src={profilePhotoString}
        alt="Avatar"
      />
    );

  return (
    <div className="avatar-container">
      {avatarImage}
      {editing && (
        <div className="avatar-input-container">
          <input
            className="avatar-file-input"
            onChange={(e) => handleUploadImage(e)}
            type="file"
            ref={inputRef}
          />
          <Button
            label={'Upload'}
            onClick={handleUploadImageClick}
            className="avatar-upload-button"
            icon={<Upload color={'white'} />}
            iconPlacement="left"
          />

          {errorText && (
            <div className={'upload-error'}>{errorText}</div>
          )}
        </div>
      )}
    </div>
  );
}
