import { IconTypeProps } from '../shared/types/types';

export function FilingCabinet({
  width = 24,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.8588 14.3065C5.8588 13.7542 6.30651 13.3065 6.8588 13.3065H13.0468C13.5991 13.3065 14.0468 13.7542 14.0468 14.3065V15.4467C14.0468 15.999 13.5991 16.4467 13.0468 16.4467H6.8588C6.30651 16.4467 5.8588 15.999 5.8588 15.4467V14.3065ZM6.8588 14.3065H13.0468V15.4467H6.8588V14.3065Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.16862 5.10603C5.61633 5.10603 5.16862 5.55374 5.16862 6.10603V8.4622C5.16862 9.01448 5.61633 9.4622 6.16862 9.4622H13.7425C14.2948 9.4622 14.7425 9.01448 14.7425 8.4622V6.10603C14.7425 5.55374 14.2948 5.10603 13.7425 5.10603H6.16862ZM13.7425 6.10603H6.16862V8.4622H13.7425V6.10603Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.838226 2.73672C0.838226 1.63215 1.73366 0.736725 2.83823 0.736725H22.8382C23.9428 0.736725 24.8382 1.63216 24.8382 2.73672V22.7367C24.8382 23.8413 23.9428 24.7367 22.8382 24.7367H2.83823C1.73366 24.7367 0.838226 23.8413 0.838226 22.7367V2.73672ZM17.925 2.73672H22.8382V22.7367H17.925V2.73672ZM16.925 2.73672H2.83823L2.83823 19.7367L16.925 19.7367V2.73672ZM2.83823 20.7367V22.7367H16.925V20.7367L2.83823 20.7367Z"
        fill={color}
      />
    </svg>
  );
}
