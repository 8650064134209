import { DeleteNoteProps, UpdateNoteProps } from '..';
import {
  LoadingSpinner,
  mediumSpinner,
} from '../../../../../../components/common/loader/LoadingSpinner';
import { NoteDTO } from '../../../../../../components/WysiwygEditor/note';
import { Note } from '../../../../../../components/WysiwygEditor/note/Note';
import { SetupNoteRecord } from '../../../../../../shared/types/types';

export interface SetupNoteListProps {
  data: SetupNoteRecord[];
  isLoading: boolean;
  error: any;
  onUpdateNote: (props: UpdateNoteProps) => void;
  onDeleteNote: (props: DeleteNoteProps) => void;
  isUpdatingNote: boolean;
}

export function SetupNoteList({
  data,
  isLoading,
  error,
  onDeleteNote,
  onUpdateNote,
  isUpdatingNote,
}: SetupNoteListProps) {
  const handleSetupNoteEdit = (
    noteId: string,
    version: number,
    noteBody: string
  ) => {
    return new Promise<boolean>((resolve) => {
      onUpdateNote({
        id: noteId,
        newContent: noteBody,
        version: version,
      });
      resolve(true);
    });
  };

  const handleSetupNoteDelete = (noteId: string, version: number) => {
    return new Promise<boolean>((resolve) => {
      onDeleteNote({
        id: noteId,
        version: version,
      });
      resolve(true);
    });
  };

  return (
    <div className="setup-notes-list-container">
      {isLoading ? (
        <div className="loading-container">
          <LoadingSpinner
            width={mediumSpinner}
            height={mediumSpinner}
          />
        </div>
      ) : (
        <>
          {data.map((note) => {
            const noteData: NoteDTO = {
              key: note._id,
              _version: note._version,
              author: note.user.name,
              createdDateTime: note.createdDateTime,
              editedDateTime: note.lastModifiedDateTime,
              encrypt_note: note.encrypt_setupNote,
            };
            return (
              <Note
                key={note._id}
                data={noteData}
                isLoadingEditNote={isUpdatingNote}
                onNoteEdit={handleSetupNoteEdit}
                onNoteDelete={handleSetupNoteDelete}
              />
            );
          })}
        </>
      )}
    </div>
  );
}
