import { ListItem } from './listItem/ListItem';
import './AuditTrailFilter.scss';
import { Search } from '../../../components/common/search/Search';
import { useState } from 'react';
import { sortStrings } from '../../../shared/util';

export interface AuditTrailFilterProps {
  category: string;
  values: string[];
  selectedValues: string[];
  className?: string;
  onSelectValue: (values: string) => void;
  capitalized?: boolean;
  onClearSelectedValues: () => void;
  searchEnabled?: boolean;
}

export function AuditTrailFilter({
  category,
  values,
  className,
  capitalized,
  onSelectValue,
  selectedValues,
  onClearSelectedValues,
  searchEnabled = false
}: AuditTrailFilterProps) {
  const [searchValue, setSearchValue] = useState<string>("");
  
  const handleListItemClick = (value: string) => {
    onSelectValue(value);
  };

  const handleClearClick = () => {
    onClearSelectedValues();
    setSearchValue("");
  };

  const handleSearchChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.currentTarget.value)
  }

  const listItems = values.sort((aa, bb) => sortStrings(aa, bb, "descending")).filter(item => item.includes(searchValue)).map((item, index) => (
    <ListItem
      key={`listItem${index}`}
      name={item}
      selected={selectedValues.includes(item)}
      onClick={() => handleListItemClick(item)}
      capitalized={capitalized}
    />
  ));

  const searchInput = <Search className="search" onChange={handleSearchChange} value={ searchValue} />

  return (
    <div className={`audit-trail-filter-container ${className}`}>
      <div className="title">{category}</div>
      { searchEnabled && searchInput}
      <div className="list-and-button">
        <div className="list-container">{listItems}</div>
        <div className="clear-button" onClick={handleClearClick}>
          Clear
        </div>
      </div>
    </div>
  );
}
