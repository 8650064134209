import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  Font,
} from '@react-pdf/renderer';
import { convertDateTime } from '../common/convertDateTime/convertDateTime';
import Roboto from './DownloadedFonts/Roboto/Roboto-Black.ttf';

export const exportAuditTrailPDF = async (
  auditTrailList: any[],
  data: any
) => {
  Font.register({
    family: 'Roboto',
    src: Roboto,
  });

  const styles = StyleSheet.create({
    page: {
      fontFamily: 'Roboto',
      flexDirection: 'column',
      padding: 20,
      marginBottom: '55px',
    },
    introContainer: {
      marginBottom: 20,
      padding: 10,
      fontSize: '20px',
      textAlign: 'center',
    },
    table: {
      fontSize: 11,
    },
    row: {
      flexDirection: 'row',
      textAlign: 'center',
    },
    cellZero: {
      padding: 8,
      width: '5%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cellOne: {
      padding: 8,
      width: '25%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cellTwo: {
      padding: 8,
      width: '24%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cellThree: {
      padding: 8,
      width: '20%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cellFour: {
      padding: 8,
      width: '14%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    cellFive: {
      padding: 8,
      width: '11%',
      borderStyle: 'solid',
      borderWidth: 1,
    },
    header: {
      flexGrow: 1,
      padding: 8,
      fontSize: 12,
      bold: true,
      flexDirection: 'row',
    },
    pageNumbers: {
      position: 'absolute',
      bottom: 20,
      left: 0,
      right: 0,
      textAlign: 'center',
      paddingTop: '10px',
    },
    svgImage: {
      width: '100%', // Adjust the width as needed
      height: 'auto',
      marginBottom: 20,
    },
  });

  return (
    <Document>
      <Page size="A4" style={styles.page} wrap={true}>
        <Text style={styles.introContainer}>
          Dear Alphabeam System Administrators and Service Users, We
          are pleased to present the comprehensive audit trail report
          detailing all auditable events that transpired between
          {data?.startDate} and {data?.endDate} for The Alphabeam
          System. This document serves can contain Patient Sensitive
          Data and as a result requires proper authorization before
          viewing.
        </Text>
        <View style={styles.table}>
          <View key={0} style={styles.row}>
            <Text style={styles.cellZero}></Text>
            <Text style={styles.cellOne}>Event Date</Text>
            <Text style={styles.cellTwo}>Event Description</Text>
            <Text style={styles.cellThree}>User</Text>
            <Text style={styles.cellFour}>Application</Text>
            <Text style={styles.cellFive}>Location</Text>
          </View>
          {auditTrailList.map((rowData, rowIndex) => (
            <View key={rowIndex + 1} style={styles.row}>
              <Text style={styles.cellZero}>{rowIndex}</Text>
              <Text style={styles.cellOne}>
                {convertDateTime(rowData.createdDateTime)}
              </Text>
              <Text style={styles.cellTwo}>
                {rowData.eventDescription}
              </Text>
              <Text style={styles.cellThree}>
                {rowData.eventDescription}
              </Text>
              <Text style={styles.cellFour}>
                {rowData.application}
              </Text>
              <Text style={styles.cellFive}>{rowData.room}</Text>
            </View>
          ))}
        </View>
        <Text
          style={styles.pageNumbers}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};
