import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function CommissioningIcon({
  width = 24,
  height = 25,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2127_84857)">
        <path
          d="M19 2.3793H18V1.3793C18 1.11409 17.8946 0.859733 17.7071 0.672196C17.5196 0.48466 17.2652 0.379303 17 0.379303C16.7348 0.379303 16.4804 0.48466 16.2929 0.672196C16.1054 0.859733 16 1.11409 16 1.3793V2.3793H8V1.3793C8 1.11409 7.89464 0.859733 7.70711 0.672196C7.51957 0.48466 7.26522 0.379303 7 0.379303C6.73478 0.379303 6.48043 0.48466 6.29289 0.672196C6.10536 0.859733 6 1.11409 6 1.3793V2.3793H5C3.67441 2.38089 2.40356 2.90819 1.46622 3.84552C0.528882 4.78286 0.00158786 6.05371 0 7.3793L0 19.3793C0.00158786 20.7049 0.528882 21.9757 1.46622 22.9131C2.40356 23.8504 3.67441 24.3777 5 24.3793H19C20.3256 24.3777 21.5964 23.8504 22.5338 22.9131C23.4711 21.9757 23.9984 20.7049 24 19.3793V7.3793C23.9984 6.05371 23.4711 4.78286 22.5338 3.84552C21.5964 2.90819 20.3256 2.38089 19 2.3793ZM2 7.3793C2 6.58365 2.31607 5.82059 2.87868 5.25798C3.44129 4.69537 4.20435 4.3793 5 4.3793H19C19.7956 4.3793 20.5587 4.69537 21.1213 5.25798C21.6839 5.82059 22 6.58365 22 7.3793V8.3793H2V7.3793ZM19 22.3793H5C4.20435 22.3793 3.44129 22.0632 2.87868 21.5006C2.31607 20.938 2 20.175 2 19.3793V10.3793H22V19.3793C22 20.175 21.6839 20.938 21.1213 21.5006C20.5587 22.0632 19.7956 22.3793 19 22.3793Z"
          fill={color}
        />
        <path
          d="M12 16.8793C12.8284 16.8793 13.5 16.2077 13.5 15.3793C13.5 14.5509 12.8284 13.8793 12 13.8793C11.1716 13.8793 10.5 14.5509 10.5 15.3793C10.5 16.2077 11.1716 16.8793 12 16.8793Z"
          fill={color}
        />
        <path
          d="M7 16.8793C7.82843 16.8793 8.5 16.2077 8.5 15.3793C8.5 14.5509 7.82843 13.8793 7 13.8793C6.17157 13.8793 5.5 14.5509 5.5 15.3793C5.5 16.2077 6.17157 16.8793 7 16.8793Z"
          fill={color}
        />
        <path
          d="M17 16.8793C17.8284 16.8793 18.5 16.2077 18.5 15.3793C18.5 14.5509 17.8284 13.8793 17 13.8793C16.1716 13.8793 15.5 14.5509 15.5 15.3793C15.5 16.2077 16.1716 16.8793 17 16.8793Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2127_84857">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0 0.379303)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
