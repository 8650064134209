import { RadioButtonDeselected } from '../../../../icons/RadioButtonDeselected';
import { RadioButtonSelected } from '../../../../icons/RadioButtonSelected';
import './ListItem.scss';

export interface ListItemProps {
  name: string;
  selected: boolean;
  onClick: () => void;
  capitalized?: boolean;
}

export function ListItem({
  name,
  selected,
  onClick,
  capitalized = false,
}: ListItemProps) {
  return (
    <div
      className={`list-item-container ${selected ? 'selected' : ''}`}
      onClick={onClick}
    >
      <div className={`name ${capitalized ? 'capitalized' : ''}`}>
        {name}
      </div>
      <div className="radio-button">
        {selected ? (
          <RadioButtonSelected />
        ) : (
          <RadioButtonDeselected />
        )}
      </div>
    </div>
  );
}
