import { Cell } from '../../../../components/common/table/Cell/Cell';
import { Row } from '../../../../components/common/table/Row/Row';
import { CarrotRight } from '../../../../icons/CarrotRight';
import './EventListRow.scss';
import '../EventList.scss';
import { format } from 'date-fns';
import { UserInfo } from 'src/shared/types/types';

export interface EventListRowProps {
  id: string;
  createdDateTime: string;
  eventDescription: string;
  userInfo: UserInfo;
  application: string;
  room: string;
  onDetailsClick: (id: string) => void;
  index: number;
}

export function EventListRow({
  id,
  createdDateTime,
  eventDescription,
  userInfo,
  application,
  room,
  onDetailsClick,
  index,
}: EventListRowProps) {
  const handleDetailsClick = () => {
    onDetailsClick(id);
  };

  return (
    <Row index={index} classes="row-container">
      <Cell>
        {format(new Date(createdDateTime), 'yyyy-MM-dd HH:MM:SS')}
      </Cell>
      <Cell capitalized>{eventDescription}</Cell>
      <Cell>{userInfo.email}</Cell>
      <Cell capitalized>{application}</Cell>
      <Cell className="rooms-column">{room}</Cell>
      <Cell className="details-column" onClick={handleDetailsClick}>
        <CarrotRight />
      </Cell>
    </Row>
  );
}
