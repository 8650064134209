import { Check } from '../../../../../icons/Check';
import './SuccessIcon.scss';

export function SuccessIcon() {
  return (
    <div className={`success-icon-container`}>
      <Check width={15} height={15} />
    </div>
  );
}
