import { BannerMessage } from '../../../../shared/contexts/BannerMessageContext';
import { CloseButton } from './buttons/CloseButton';
import { SuccessIcon } from './icons/SuccessIcon';
import './Banner.scss';
import { InfoIcon } from './icons/InfoIcon';
import { ErrorIcon } from './icons/ErrorIcon';
import { WarningIcon } from './icons/WarningIcon';

export interface BannerProps {
  bannerId: string;
  bannerMessage: BannerMessage;
  onCloseClick: (id: string) => void;
}

export function Banner({
  bannerId,
  bannerMessage,
  onCloseClick,
}: BannerProps) {
  const { message, type } = bannerMessage;

  const handleCloseClick = () => {
    onCloseClick(bannerId);
  };

  const icon =
    bannerMessage.type === 'error' ? (
      <ErrorIcon />
    ) : bannerMessage.type === 'info' ? (
      <InfoIcon />
    ) : bannerMessage.type === 'success' ? (
      <SuccessIcon />
    ) : bannerMessage.type === 'warning' ? (
      <WarningIcon />
    ) : (
      ''
    );

  return (
    <div className={`banner-message-container ${type}`}>
      <div className={'icon-container'}>{icon}</div>
      <div className="banner-message-text">{message}</div>
      <div className={'controls-container'}>
        <CloseButton onClick={handleCloseClick} />
      </div>
    </div>
  );
}
