import { SpinnerIcon } from '../../../icons/Spinner';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  width?: number;
  height?: number;
  color?: string;
  classes?: string;
}

export function LoadingSpinner({
  color,
  width,
  height,
  classes,
  ...rest
}: LoadingSpinnerProps) {
  return (
    <SpinnerIcon
      className={`spinner-icon rotate ${classes}`}
      width={width}
      height={height}
      color={color}
      {...rest}
    />
  );
}

export const largeSpinner = 60;

export const mediumSpinner = 50;

export const smallSpinner = 30;
