import { useEffect, useState } from 'react';
import { IdToken } from '@auth0/auth0-react';
import { WebAuthConfig } from '../../../../shared/types/types';
import { Auth0Error } from 'auth0-js';
import {
  LoadingSpinner,
  mediumSpinner,
} from '../../../common/loader/LoadingSpinner';
import './PopupApproval.scss';
import { TAELogoIcon } from '../../../../icons/TAELogo';

export interface PopupApprovalProps {
  webAuth: auth0.WebAuth;
  webAuthConfig: WebAuthConfig;
  postLoginUser: (result: IdToken, isApproval: boolean) => void;
}

export function PopupApproval({
  webAuth,
  webAuthConfig,
  postLoginUser,
}: PopupApprovalProps) {
  const [title, setTitle] = useState('Processing');
  const [errors, setErrors] = useState<Auth0Error | null>();

  // just check the session once with this useEffect
  useEffect(() => {
    webAuth.checkSession(
      {
        audience: webAuthConfig.audience,
        scope: 'read:current_user read:token',
        redirectUri: webAuthConfig.redirectUri,
      },
      (error, result: IdToken) => {
        if (result) {
          postLoginUser(result, true);
          setTitle('Approved');
          window.close();
        } else {
          setTitle('Error');
          setErrors(error);
        }
      }
    );
  }, [webAuth, webAuthConfig, postLoginUser]);

  return (
    <div className="popup-approval-container">
      <TAELogoIcon className="logo" />
      <div className="title">{title}</div>
      {errors && (
        <div className="error-container">
          {errors?.error && (
            <div className="error-item">
              <div className="header">Error Type:</div>
              <div className="">{`${errors?.error}`}</div>
            </div>
          )}
          {errors?.errorDescription && (
            <div className="error-item">
              <div className="header">Error Description:</div>
              <div className="">{`${errors?.errorDescription}`}</div>
            </div>
          )}
          {errors?.error_description && (
            <div className="error-item">
              <div className="header">Error Description:</div>
              <div className="">{`${errors?.error_description}`}</div>
            </div>
          )}
          {errors?.code && (
            <div className="error-item">
              <div className="header">Code:</div>
              <div className="">{`${errors?.code}`}</div>
            </div>
          )}
          {errors?.description && (
            <div className="error-item">
              <div className="header">Description:</div>
              <div className="">{`${errors?.description}`}</div>
            </div>
          )}
          {errors?.name && (
            <div className="error-item">
              <div className="header">Name:</div>
              <div className="">{`${errors?.name}`}</div>
            </div>
          )}
          {errors?.policy && (
            <div className="error-item">
              <div className="header">Policy:</div>
              <div className="">{`${errors?.policy}`}</div>
            </div>
          )}
          {errors?.original && (
            <div className="error-item">
              <div className="header">Original:</div>
              <div className="">{`${errors?.original}`}</div>
            </div>
          )}
          {errors?.statusCode && (
            <div className="error-item">
              <div className="header">Status Code:</div>
              <div className="">{`${errors?.statusCode}`}</div>
            </div>
          )}
          {errors?.statusText && (
            <div className="error-item">
              <div className="header">Status Text:</div>
              <div className="">{`${errors?.statusText}`}</div>
            </div>
          )}
        </div>
      )}

      {!errors && (
        <>
          <div className="description">
            This pop up will automatically close.
          </div>
          <div className="description">
            Redirecting back to BAC...
          </div>
        </>
      )}

      <LoadingSpinner width={mediumSpinner} height={mediumSpinner} />
    </div>
  );
}
