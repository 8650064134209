import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import { Card } from '../../components/Card/Card';
import {
  LoadingSpinner,
  mediumSpinner,
} from '../../components/common/loader/LoadingSpinner';
import { Section } from '../../shared/types/types';
import { BreakGlassLogin } from './breakGlassLogin/BreakGlassLogin';
import './Dashboard.scss';
import { DashboardFooter } from './footer/DashboardFooter';
import { Login } from './login/Login';
import { DashboardMain } from './main/DashboardMain';

export interface DashboardProps {
  onSectionClick: (section: Section) => void;
  onLoginClick: () => void;
}

export function Dashboard({
  onSectionClick,
  onLoginClick,
}: DashboardProps) {
  const { user, isAuthenticated } = useAuth0();
  const [breakGlassLoginIsActive, setBreakGlassLoginIsActive] =
    useState(false);

  const handleBreakGlassSelect = () => {
    setBreakGlassLoginIsActive(true);
  };

  const handleBreakGlassLoginClick = (email: string) => {};

  const handleBreakGlassCancelClick = () => {
    setBreakGlassLoginIsActive(false);
  };

  const content = (
    <div className="dashboard-container">
      <div className="dashboard-content">
        {breakGlassLoginIsActive ? (
          <BreakGlassLogin
            onBreakGlassLoginClick={handleBreakGlassLoginClick}
            onCancelClick={handleBreakGlassCancelClick}
          />
        ) : !user || !isAuthenticated ? (
          <Login
            onBreakGlassClick={handleBreakGlassSelect}
            onLoginClick={onLoginClick}
          />
        ) : user && isAuthenticated ? (
          <DashboardMain onSectionClick={onSectionClick} />
        ) : (
          <div className="loading-user-spinner">
            <LoadingSpinner width={mediumSpinner} />
            <div className="sub-header">Authenticating User</div>
          </div>
        )}
      </div>
      <DashboardFooter userAuthenticated={isAuthenticated} />
    </div>
  );

  return <Card title="Home" body={content} fillContent />;
}
