import { Cell } from '../../../../components/common/table/Cell/Cell';
import { Row } from '../../../../components/common/table/Row/Row';
import './AdminEmailRow.scss';
import '../AdminEmailList.scss';
import { Button } from 'src/components/Button/Button';
import { Pencil } from 'src/shared/assets/pencil';
import {
  neutral300,
  primaryBlue400,
} from 'src/shared/styles/variables';
import { useContext, useState } from 'react';
import { XClose } from 'src/icons/XClose';
import { Trash } from 'src/icons/Trash';
import {
  LoadingSpinner,
  smallSpinner,
} from 'src/components/common/loader/LoadingSpinner';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import {
  decryptObject,
  encryptObject,
} from 'src/components/Encryption/obfuscationHandler';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { fetchAuthenticated } from 'src/controllers';

export interface AdminEmailRowProps {
  email: string;
  index: number;
  adminName: string;
  createdDateTime: string;
  setAdminEmailList: (list: any) => void;
  adminListCurrentVersion: number | null;
}

export function AdminEmailRow({
  email,
  index,
  adminName,
  createdDateTime,
  setAdminEmailList,
  adminListCurrentVersion,
}: AdminEmailRowProps) {
  const [edit, setEdit] = useState(false);
  const [deleteAdminEmailSpinner, setDeleteAdminEmailSpinner] =
    useState<boolean>(false);

  const { token } = useContext(GlobalUserContext);

  const handleDeleteAdminEmail = async () => {
    setDeleteAdminEmailSpinner(true);
    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/firecall_remove_admin`;

    const body = JSON.stringify(
      await encryptObject(
        {
          _version: adminListCurrentVersion,
          adminEmailToRemove: email,
        },
        token,
        null
      )
    );

    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      setAdminEmailList(decryptData?.newAdminList?.adminList);
      setDeleteAdminEmailSpinner(false);
    }
  };

  return (
    <Row index={index} classes="row-container">
      <Cell capitalized>{adminName}</Cell>
      <Cell>{email}</Cell>
      <Cell capitalized>{createdDateTime}</Cell>

      <Cell capitalized>
        {!edit ? (
          <Button
            icon={<Pencil fill={primaryBlue400} />}
            onClick={() => {
              setEdit(true);
            }}
            width={'40px'}
            height={'40px'}
            className={'square-icon-button edit-button-enabled'}
          ></Button>
        ) : deleteAdminEmailSpinner ? (
          <LoadingSpinner
            width={smallSpinner}
            height={smallSpinner}
          />
        ) : (
          <div className="firecall-email-action-buttons">
            <div className="cancel-button-container">
              <Button
                icon={<XClose color={primaryBlue400} />}
                onClick={() => {
                  setEdit(false);
                }}
                width={'40px'}
                height={'40px'}
                className={'square-icon-button cancel-button'}
              ></Button>
            </div>
            <Button
              className="trash-button"
              icon={<Trash color={neutral300} />}
              onClick={() => {
                handleDeleteAdminEmail();
              }}
              width={'40px'}
              height={'40px'}
            ></Button>
          </div>
        )}
      </Cell>
    </Row>
  );
}
