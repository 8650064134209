import { Header } from '../../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../../components/common/table/HeaderCell/HeaderCell';
import '../AdminEmailList.scss';

export interface AdminEmailListHeaderProps {}

export function AdminEmailListHeader() {
  return (
    <Header>
      <HeaderCell>{`administrator name`}</HeaderCell>
      <HeaderCell>{`email`}</HeaderCell>
      <HeaderCell>{`date created`}</HeaderCell>
      <HeaderCell>{`action `}</HeaderCell>
    </Header>
  );
}
