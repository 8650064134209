import { useAuth0 } from '@auth0/auth0-react';
import { format } from 'date-fns';
import { TAELogoIcon } from '../../../icons/TAELogo';
import './DashboardFooter.scss';
import { useContext } from 'react';
import { GlobalUserContext } from '../../../shared/contexts/GlobalUserContext';

export interface DashboardFooterProps {
  userAuthenticated: boolean;
}

export function DashboardFooter({
  userAuthenticated: userLoggedIn,
}: DashboardFooterProps) {
  const auth = useAuth0();
  const { userInfo } = useContext(GlobalUserContext);

  return (
    <div className="dashboard-footer-container">
      <div className="session-information">
        {userLoggedIn && (
          <>
            <div className="session-title">Session Information</div>
            <div className="label-and-data">
              <div className="label">Name:</div>
              <div className="data">{auth.user?.name}</div>
            </div>
            <div className="label-and-data">
              <div className="label">Role:</div>
              <div className="data">
                {(userInfo as string[]) ?? 'Loading role...'}
              </div>
            </div>
            <div className="label-and-data">
              <div className="label">Logged In:</div>
              <div className="data">
                {auth.user?.updated_at
                  ? format(
                      new Date(auth.user?.updated_at),
                      'MMM dd, yyyy HH:mm:ss'
                    )
                  : 'Invalid date.'}
              </div>
            </div>
          </>
        )}
      </div>
      <div className="right-content">
        <TAELogoIcon className="tae-logo" width={200} height={70} />
        <div className="version-number">
          v.{process.env.REACT_APP_CAS_VERSION}
        </div>
      </div>
    </div>
  );
}
