import React, {
  createContext,
  useState,
  useMemo,
  useCallback,
} from 'react';
import {
  GlobalUserStateInterface,
  UserContextProps,
} from '../types/types';

export const initialState: GlobalUserStateInterface = {
  firstName: '',
  setFirstName: () => {},
  lastName: '',
  setLastName: () => {},
  userName: '',
  setUserName: () => {},
  userInfo: null,
  setUserInfo: () => {},
  userId: '',
  setUserId: () => {},
  isLoggedIn: false,
  setIsLoggedIn: () => {},
  token: '',
  setToken: () => {},
  patientInfo: null,
  setPatientInfo: () => {},
  clearPatientInfo: () => {},
  pdfState: {
    pdfFailed: false,
    pdfStarted: false,
    pdfSucceeded: false,
  },
  setPDFState: () => {},
};

export const GlobalUserContext =
  createContext<GlobalUserStateInterface>(initialState);

export const GlobalStateProvider: React.FC<UserContextProps> = ({
  children,
}) => {
  const [firstName, setFirstName] = useState(initialState.firstName);
  const [lastName, setLastName] = useState(initialState.lastName);
  const [userName, setUserName] = useState(initialState.userName);
  const [userInfo, setUserInfo] = useState(initialState.userInfo);
  const [userId, setUserId] = useState(initialState.userId);
  const [isLoggedIn, setIsLoggedIn] = useState(
    initialState.isLoggedIn
  );
  const [token, setToken] = useState(initialState.token);
  const [patientInfo, setPatientInfo] = useState(
    initialState.patientInfo
  );
  const [pdfState, setPDFState] = useState(initialState.pdfState);

  const clearPatientInfo = useCallback(() => {
    setPatientInfo(null);
  }, []);
  return (
    <GlobalUserContext.Provider
      value={useMemo(
        () => ({
          firstName,
          setFirstName,
          lastName,
          setLastName,
          userName,
          setUserName,
          userInfo,
          setUserInfo,
          userId,
          setUserId,
          isLoggedIn,
          setIsLoggedIn,
          token,
          setToken,
          patientInfo,
          setPatientInfo,
          clearPatientInfo,
          pdfState,
          setPDFState,
        }),
        [
          firstName,
          lastName,
          userName,
          userInfo,
          userId,
          isLoggedIn,
          token,
          patientInfo,
          pdfState,
          clearPatientInfo,
        ]
      )}
    >
      {children}
    </GlobalUserContext.Provider>
  );
};
