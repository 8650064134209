import { format } from 'date-fns';
import { Button } from '../../../../../../components/Button/Button';
import { CarrotDown } from '../../../../../../icons/CarrotDown';
import {
  Beam,
  PatientRecord,
  TreatmentPlanRecord,
} from '../../../../../../shared/types/types';
import { HorizontalRule } from '../../common/HorizontalRule';
import { RSApprovalFail } from '../../common/RSApprovalFail';
import '../Plan.scss';

export interface TreaatmentReportProps {
  patientData: PatientRecord;
  planData: TreatmentPlanRecord;
}

export function TreatmentReport({
  patientData,
  planData,
}: TreaatmentReportProps) {
  const courseName = planData.encrypt_planCourse;
  const casApproval = planData.casApproval;

  const handleSavePDFClick = () => {};

  return (
    <div className="plan-container" data-cy="treatment-report">
      <div className="title">
        Plan Name: {planData?.encrypt_planName ?? 'ERROR'}
      </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Plan Type:</div>
            <div className="data">
              {patientData?.encrypt_type ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Plan Status:</div>
            <div className="data">
              {planData?.planStatus ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Prescription Dose:</div>
            <div className="data lower-case">
              {`${planData?.prescription.targetPrescriptionDose_cGy} cGy` ??
                'ERROR'}
            </div>
          </div>
        </div>
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Treatment Site</div>
            <div className="data">
              {planData?.encrypt_treatmentSite ?? 'ERROR'}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">Course Name:</div>

            <div className="data">{courseName}</div>
          </div>
          <div className="label-and-data">
            <div className="label">Treatment Status:</div>
            <div className="data">
              {planData.casApproval.approved &&
              planData.rsApproval.approved
                ? 'Approved'
                : 'Not Approved' ?? 'ERROR'}
            </div>
          </div>
        </div>
      </div>
      <HorizontalRule dashed />
      <div className="subtitle">BEAMS</div>
      {planData?.beamList.map((data: Beam, index: number) => (
        <div className="beam-data" key={`${data._id}_${index}`}>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Beam Name:</div>
              <div className="data">{data.beamName}</div>
            </div>
            <div className="label-and-data">
              <div className="label">Collimator:</div>
              <div className="data lower-case">
                {`${data.beamCollimator.collimatorSize_mm} mm`}
              </div>
            </div>
          </div>
          <div className="data-group">
            <div className="label-and-data">
              <div className="label">Assumed Boron 10:</div>
              <div className="data lower-case">
                {`${data.beamOutput.plannedBoronConcentration_mgPerKg} mg/kg`}
              </div>
            </div>
            <div className="label-and-data">
              <div className="label">Monitor Unit:</div>
              <div className="data">{`${data.beamOutput.beamMU} MU`}</div>
            </div>
          </div>
        </div>
      ))}

      <HorizontalRule dashed />
      <div className="subtitle">approvals </div>
      <div className="plan-data">
        <div className="data-group">
          <div className="label-and-data">
            <div className="label">Raystation approval:</div>
            <div className="data">
              {planData.rsApproval.approved ? (
                format(
                  new Date(
                    planData.rsApproval.approvedDateTime ?? 'ERROR'
                  ),
                  'dd-MMM-yyyy HH:mm:ss'
                )
              ) : (
                <RSApprovalFail />
              )}
            </div>
          </div>
          <div className="label-and-data">
            <div className="label">CAS approval:</div>
            <div className={`data `} data-cy="cas-approval-field">
              {casApproval != null && casApproval.approved
                ? format(
                    new Date(casApproval.approvedDateTime),
                    'dd-MMM-yyyy HH:mm:ss'
                  )
                : 'REQUIRES APPROVAL'}
            </div>
          </div>
        </div>
      </div>
      <div className="controls">
        <Button
          label={'Save PDF'}
          icon={<CarrotDown color="#FFFFFF" />}
          iconPlacement={'left'}
          className={'save-button'}
          onClick={handleSavePDFClick}
          data-cy="save-pdf-button"
        />
      </div>
    </div>
  );
}
