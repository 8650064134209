import { IconTypeProps } from '../shared/types/types';

export function Floppy({
  width = '24',
  height = '23',
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.99836 3.34286C8.86567 3.34286 8.73843 3.39557 8.64461 3.48939C8.55079 3.58321 8.49808 3.71046 8.49808 3.84314C8.49808 3.97582 8.55079 4.10307 8.64461 4.19689C8.73843 4.29071 8.86567 4.34342 8.99836 4.34342H14.4997C14.6324 4.34342 14.7597 4.29071 14.8535 4.19689C14.9473 4.10307 15 3.97582 15 3.84314C15 3.71046 14.9473 3.58321 14.8535 3.48939C14.7597 3.39557 14.6324 3.34286 14.4997 3.34286H8.99836Z"
        fill={color}
      />
      <path
        d="M8.64461 6.48939C8.73843 6.39557 8.86567 6.34286 8.99836 6.34286H14.4997C14.6324 6.34286 14.7597 6.39557 14.8535 6.48939C14.9473 6.58321 15 6.71046 15 6.84314C15 6.97582 14.9473 7.10307 14.8535 7.19689C14.7597 7.29071 14.6324 7.34342 14.4997 7.34342H8.99836C8.86567 7.34342 8.73843 7.29071 8.64461 7.19689C8.55079 7.10307 8.49808 6.97582 8.49808 6.84314C8.49808 6.71046 8.55079 6.58321 8.64461 6.48939Z"
        fill={color}
      />
      <path
        d="M9.84661 17.7037H8.15338V19.7927H9.84661V17.7037Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.08197e-06 6C2.08197e-06 0.0298615 -0.025598 0.0130793 5.60065 0.0130793V0H18.3994V0.0130808C23.9433 0.0130808 24 -0.0605245 24 4V16.5566C24 22.5164 23.9318 22.5436 18.3994 22.5436V22.5566H5.60065V22.5436C0.0663371 22.5436 2.08178e-06 22.5164 2.08197e-06 16.5566V6ZM22 4C22 1.92565 21.9094 2.01969 18.3994 2.01969V9.81137C18.3994 10.9159 17.5039 11.8114 16.3994 11.8114H7.60065C6.49608 11.8114 5.60065 10.9159 5.60065 9.81137V2.01969C1.97852 2.01969 2 2.08913 2 6V16.5566C2 20.5123 1.97852 20.5369 5.60065 20.5369V14.9398H18.3994V20.5369C21.9433 20.5369 22 20.4473 22 16.5566V4ZM17.3994 2H6.60065V9.81137C6.60065 10.3637 7.04836 10.8114 7.60065 10.8114H16.3994C16.9517 10.8114 17.3994 10.3637 17.3994 9.81137V2ZM17.3994 20.5566H6.60065V15.9398H17.3994V20.5566Z"
        fill={color}
      />
    </svg>
  );
}
