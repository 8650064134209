import { Button } from '../../../components/Button/Button';
import { BreakGlassIcon } from '../../../icons/BreakGlass';
import { DownloadIcon } from '../../../icons/Download';
import './Login.scss';
import '../../../shared/styles/Common.scss';

export interface LoginProps {
  onBreakGlassClick: () => void;
  onLoginClick: () => void;
}

export function Login({
  onBreakGlassClick,
  onLoginClick,
}: LoginProps) {
  const handleLoginClick = () => {
    onLoginClick();
  };
  const handleBreakGlassClick = () => {
    onBreakGlassClick();
  };

  return (
    <div className="login-container">
      <div className="title">Welcome!</div>
      <div className="sub-header">Please log in to continue.</div>
      <div className="controls">
        <Button
          className="login-button primary"
          label="login"
          onClick={handleLoginClick}
          icon={<DownloadIcon />}
          iconPlacement="left"
        />
        <Button
          className="break-glass-button secondary"
          icon={<BreakGlassIcon />}
          onClick={handleBreakGlassClick}
        />
      </div>
    </div>
  );
}
