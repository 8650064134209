import { useContext, useEffect, useState } from 'react';
import { CAS_BACK_END_API_URL, URL_VERSION } from '../../constants';
import { fetchAuthenticated } from '..';
import {
  PatientRecord,
  PatientsAjaxResult,
} from '../../shared/types/types';
import { decryptObject } from '../../components/Encryption/obfuscationHandler';
import useSWR from 'swr';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { BannerMessageContext } from '../../shared/contexts/BannerMessageContext';
import { v4 } from 'uuid';

export interface PatientDataFetchProps {
  patientId: string;
  token: string | null;
}

export interface RefreshOptionProps {
  showArchived: boolean;
}
export interface PatientDataFetchReturnProps {
  data: PatientRecord | null;
  loading: boolean;
  startRefresh: (props: RefreshOptionProps) => {};
  error: any;
}

export const usePatientInfoFetcher = ({
  patientId,
  token,
}: PatientDataFetchProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<PatientRecord>();
  const [error, setError] = useState<any>(null);

  // To refresh this, call the returned startRefresh function with the object {showArchived:true/false}
  const [refresh, startRefresh] = useState<RefreshOptionProps>({
    showArchived: true,
  });

  useEffect(() => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}?archived=${
      refresh.showArchived ?? true
    }`;
    const config: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const getPatientInfo = async () => {
      setLoading(true);
      try {
        // get the patient data from the backend
        const response = await fetchAuthenticated<PatientsAjaxResult>(
          url,
          config
        );

        if (response.ok) {
          const apiData = response.parsedBody;

          // now decrypt the data
          if (
            apiData?.result?.patients &&
            apiData?.result?.patients.length > 0
          ) {
            try {
              let decryptedData = { ...apiData.result.patients[0] };
              await decryptObject(decryptedData, token, null);
              setData(decryptedData);
            } catch (error) {
              setError(
                `An error occurred getting patient data from the server: ${error}`
              );
              setData(undefined);
            }
          } else {
            setData(undefined);
          }
        }
        setLoading(false);
      } catch (e: any) {
        setError(
          `An error occurred getting patient data from the server: ${error}`
        );
      }
    };

    getPatientInfo();
  }, [refresh, patientId, token, error]);

  return { data, loading, startRefresh, error } as const;
};

export const usePatientList = (showArchived: boolean) => {
  const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients?archived=${showArchived}`;
  const { token } = useContext(GlobalUserContext);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const fetchWithToken = async (url: string, token: string) => {
    const config: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetchAuthenticated<PatientsAjaxResult>(
      url,
      config
    );

    if (response?.ok) {
      // decrypt patient data
      const apiData = response.parsedBody;

      let decryptedData;

      // now decrypt the data
      if (
        apiData &&
        apiData?.result &&
        apiData?.result?.patients &&
        apiData?.result?.patients.length > 0
      ) {
        try {
          decryptedData = apiData.result;
          await decryptObject(decryptedData, token, null);
          return decryptedData.patients;
        } catch (e: any) {
          const error = new Error(
            'An error occurred getting patient data from the server: ',
            e
          );
          addBannerMessage({
            id: v4(),
            message:
              'Uh oh! An error occurred downloading the patient list!',
            type: 'error',
          });
          throw error;
        }
      }

      return decryptedData;
    }
  };

  const { data, error, isLoading } = useSWR(
    [url, token],
    ([_url, _token]) => fetchWithToken(_url, _token ?? ''),
    { shouldRetryOnError: false }
  );

  return {
    patientListData: data,
    patientListDataError: error,
    patientListDataIsLoading: isLoading,
  };
};
