import clsx from 'clsx';
import { Card } from '../../../components/Card/Card';
import {
  CAS_BACK_END_API_URL,
  URL_VERSION,
  WEB_OWNER,
} from '../../../constants';
import { fetchAuthenticated } from '../../../controllers';
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import './PatientInfusion.scss';
import { TextInput } from '../../../components/TextInput/TextInput';
import { Button } from '../../../components/Button/Button';
import { Pencil } from '../../../shared/assets/pencil';
import { Clock } from '../../../shared/assets/clock';
import { Plus } from '../../../shared/assets/plus';
import { Header } from '../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../components/common/table/HeaderCell/HeaderCell';
import { FilterButton } from '../../../components/common/buttons/iconButtons/FilterButton';
import { InfusionHistoryRow } from './InfusionHistoryRow/InfusionHistoryRow';
import { XClose } from '../../../shared/assets/x-close';
import { Check } from '../../../shared/assets/check';
import {
  PatientProps,
  PatientRecord,
  Section,
  UserProps,
} from '../../../shared/types/types';
import { differenceInSeconds, format } from 'date-fns';
import { GlobalUserContext } from '../../../shared/contexts/GlobalUserContext';
import {
  neutral400,
  neutral50,
  primaryBlue400,
} from '../../../shared/styles/variables';
import {
  LoadingSpinner,
  mediumSpinner,
  smallSpinner,
} from '../../../components/common/loader/LoadingSpinner';
import { PatientBloodSamples } from './PatientBloodSamples/PatientBloodSamples';
import { InfusionNotes } from './InfusionNotes/InfusionNotes';
import {
  encryptObject,
  decryptObject,
} from '../../../components/Encryption/obfuscationHandler';

interface PatientInfusionProps {
  patientData: PatientRecord | undefined;
  section: Section;
}

export interface InfusionSession {
  _id: string;
  _version: number;
  user: UserProps;
  patient: PatientProps;
  createdDateTime: string;
  lastModifiedDateTime: string | null;
  lastModifiedBy: string | null; // user ID
  archived: boolean;
  archivedDateTime: string | null;
  archivedBy: string | null; // user ID
  encrypt_infusionStartDateTime: string;
  encrypt_infusionEndDateTime: string | null;
}

export interface InfusionSessionsServiceResult {
  status: string;
  total: number;
  items: Array<InfusionSession>;
  errors: string[];
}

export interface InfusionSessionsAjaxResult {
  result: InfusionSessionsServiceResult;
}

export const PatientInfusion = ({
  patientData,
  section,
}: PatientInfusionProps) => {
  const url =
    CAS_BACK_END_API_URL +
    `${URL_VERSION}/patients/${patientData?._id}/infusions`;
  // NOTE: can use the url below to get mock data
  // const url = CAS_BACK_END_API_URL + `/w1/patients/10002/infusions`;
  const [infusionSessions, setInfusionSessions] =
    useState<InfusionSession[] | null>(null);
  const [startInput, setStartInput] = useState<string>('');
  const [endInput, setEndInput] = useState<string>('');
  const [startDisabled, setStartDisabled] = useState<boolean>(false);
  const [endDisabled, setEndDisabled] = useState<boolean>(true);
  const [editStartDisabled, setEditStartDisabled] =
    useState<boolean>(true);
  const [editEndDisabled, setEditEndDisabled] =
    useState<boolean>(true);
  const [editStartTime, setEditStartTime] = useState<boolean>(false);
  const [editEndTime, setEditEndTime] = useState<boolean>(false);

  const [isInfusionHistoryLoading, setInfusionHistoryIsLoading] =
    useState<boolean>();
  const [apiData, setApiData] =
    useState<InfusionSessionsAjaxResult>();
  const [serverError, setServerError] = useState();

  const [currentTime, setCurrentTime] = useState(new Date());
  const [isInfusionStarted, setIsInfusionStarted] = useState(false);
  const [infusionStartTime, setInfusionStartTime] = useState<Date>();
  const [currentSessionId, setCurrentSessionId] = useState<string>();
  const [currentVersion, setCurrentVersion] = useState<number>(1);
  const [prevSessionStartTime, setPrevSessionStartTime] =
    useState<string>();
  const [prevSessionEndTime, setPrevSessionEndTime] =
    useState<string>();
  const [isLoadingStartInfusion, setIsLoadingStartInfusion] =
    useState<boolean>(false);
  const [isLoadingEndInfusion, setIsLoadingEndInfusion] =
    useState<boolean>(false);
  const [isLoadingStartInfusionEdit, setIsLoadingStartInfusionEdit] =
    useState<boolean>(false);
  const [isLoadingEndInfusionEdit, setIsLoadingEndInfusionEdit] =
    useState<boolean>(false);

  const CURRENT_PATIENT_INFUSION = 'currentPatientInfusion';

  const { token } = useContext(GlobalUserContext);

  const getPatientInfusionData = useCallback(async () => {
    const config: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };

    setInfusionHistoryIsLoading(true);
    const response =
      await fetchAuthenticated<InfusionSessionsAjaxResult>(
        url,
        config
      );
    setInfusionHistoryIsLoading(false);

    if (response.ok) {
      setApiData(response.parsedBody);
    }
  }, [url, token]);

  useEffect(() => {
    if (!patientData) return;
    try {
      getPatientInfusionData();
    } catch (error: any) {
      setServerError(error);
    }
  }, [getPatientInfusionData, patientData]);

  const [expanded, setExpanded] = useState(true);

  const handleOnExpand = () => {
    setExpanded(!expanded);
  };

  const ascendingDateSort = (
    infusionA: InfusionSession,
    infusionB: InfusionSession
  ) => {
    return (
      Date.parse(infusionB.createdDateTime) -
      Date.parse(infusionA.createdDateTime)
    );
  };

  useEffect(() => {
    async function decryptData() {
      if (apiData?.result?.items) {
        try {
          await decryptObject(apiData.result, token, null);
          setInfusionSessions(
            apiData?.result.items.sort(ascendingDateSort)
          );
        } catch {
          setInfusionSessions(null);
        }
      }
    }
    decryptData();
  }, [apiData, token]);

  const showAdd = useMemo(() => {
    if (startInput !== '' && endInput !== '') return true;
    else return false;
  }, [startInput, endInput]);

  useEffect(() => {
    // when we mount this component, check local storage for infusionStartTime in case this site has idled out
    const previousInfusionSessionData = window.localStorage.getItem(
      CURRENT_PATIENT_INFUSION
    );

    if (
      previousInfusionSessionData &&
      previousInfusionSessionData !== ''
    ) {
      const {
        startInfusionTime,
        infusionSessionId,
        infusionVersion,
      } = JSON.parse(previousInfusionSessionData);

      const parsedStartTime = new Date(Date.parse(startInfusionTime));
      setInfusionStartTime(parsedStartTime);
      setStartInput(format(parsedStartTime, 'HH:mm:ss'));
      setCurrentSessionId(infusionSessionId);
      setCurrentVersion(infusionVersion);

      setEditStartDisabled(false);
      setStartDisabled(true);
      setEndDisabled(false);
      setIsInfusionStarted(true);
    }
  }, []);

  const updateTime = () => {
    const now = new Date();
    setCurrentTime(now);
  };

  const infusionTime = () => {
    if (!isInfusionStarted || infusionStartTime == null)
      return [0, 0, 0];
    const diff = differenceInSeconds(currentTime, infusionStartTime);
    return [
      Math.floor(diff / 3600),
      Math.floor((diff / 60) % 60),
      diff % 60,
    ];
  };

  useEffect(() => {
    const timeInterval = setInterval(updateTime, 1000);
    return () => {
      clearInterval(timeInterval);
    };
  }, []);

  interface InfusionPutResponse {
    result: {
      session: InfusionSession;
    };
  }

  const handleStartInfusionClick = async () => {
    setIsLoadingStartInfusion(true);
    const now = new Date();

    const body = {
      encrypt_infusionStartDateTime: now.toISOString(),
      owner: WEB_OWNER,
    };

    const config: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(await encryptObject(body, token, null)),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetchAuthenticated<InfusionPutResponse>(
      url,
      config
    );

    setIsLoadingStartInfusion(false);
    if (response.ok) {
      const data = response.parsedBody;
      if (!data) return;
      await decryptObject(data.result, token, null);
      setCurrentSessionId(data.result.session._id);
      setCurrentVersion(data.result.session._version);

      setEditStartDisabled(false);
      setStartDisabled(true);
      setEndDisabled(false);

      setStartInput(format(now, 'HH:mm:ss'));

      setInfusionStartTime(now);
      setIsInfusionStarted(true);

      // update the list with new infusion session
      if (infusionSessions) {
        const newInfusionSessions: InfusionSession[] = [
          ...infusionSessions,
          data.result.session,
        ].sort(ascendingDateSort);
        setInfusionSessions(newInfusionSessions);
      }

      // update local storage

      const savedSessionData = {
        startInfusionTime: now.toISOString(),
        infusionSessionId: data.result.session._id,
        infusionVersion: data.result.session._version,
      };

      window.localStorage.setItem(
        CURRENT_PATIENT_INFUSION,
        JSON.stringify(savedSessionData)
      );
    }
  };

  const handleEndInfusionClick = async () => {
    setIsLoadingEndInfusion(true);
    const now = new Date();
    const body = {
      _version: currentVersion,
      encrypt_infusionStartDateTime: infusionStartTime,
      encrypt_infusionEndDateTime: now.toISOString(),
      owner: WEB_OWNER,
    };
    const config: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(await encryptObject(body, token, null)),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetchAuthenticated<InfusionPutResponse>(
      `${url}/${currentSessionId}`,
      config
    );

    setIsLoadingEndInfusion(false);
    if (response.ok) {
      const data = response.parsedBody?.result;
      await decryptObject(data, token, null);

      setEditEndDisabled(false);
      setEndDisabled(true);
      setEndInput(format(now, 'HH:mm:ss'));
      setIsInfusionStarted(false);

      if (data?.session && infusionSessions) {
        setInfusionSessions(
          infusionSessions.map((session) => {
            if (session._id === data.session._id) {
              return data.session;
            } else {
              return session;
            }
          })
        );
      }

      window.localStorage.removeItem(CURRENT_PATIENT_INFUSION);
    }
  };

  const handleAddSessionClick = () => {
    setEditStartDisabled(true);
    setStartDisabled(false);
    setEndDisabled(true);
    setEditEndDisabled(true);
    setStartInput('');
    setEndInput('');
    setCurrentSessionId('');
    setEditStartTime(false);
    setEditEndTime(false);
  };

  const updateCurrentSessionTimes = async (
    onCompleteCallBack: () => void
  ) => {
    const body = {
      _version: currentVersion,
      encrypt_infusionStartDateTime: convertToDateISO(startInput),
      encrypt_infusionEndDateTime: convertToDateISO(endInput),
      owner: WEB_OWNER,
    };
    const config: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(await encryptObject(body, token, null)),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await fetchAuthenticated<InfusionPutResponse>(
      `${url}/${currentSessionId}`,
      config
    );
    if (response.ok) {
      if (response.parsedBody && infusionSessions) {
        await decryptObject(response.parsedBody, token, null);
        // update the session
        const newData = response.parsedBody.result.session;
        setInfusionSessions(
          infusionSessions.map((session) => {
            if (session._id === newData._id) {
              return newData;
            } else {
              return session;
            }
          })
        );

        onCompleteCallBack();
      }
    }
  };

  // Edit session end time

  const handleEditEndInfusionTimeClick = () => {
    setEditEndTime(true);
    setPrevSessionEndTime(endInput);
  };

  const handleEditEndInfusionTimeOkClick = async () => {
    const onCompleteCallback = () => {
      setEditEndTime(false);
      setEndDisabled(true);
      setPrevSessionEndTime('');
      setIsLoadingEndInfusionEdit(false);
    };

    setIsLoadingEndInfusionEdit(true);
    updateCurrentSessionTimes(onCompleteCallback);
  };

  const handleEditEndInfusionTimeCancelClick = async () => {
    setEndInput(prevSessionEndTime ?? '');
    setEditEndTime(false);
  };

  // Edit session start time

  const handleEditStartInfusionTimeClick = () => {
    setEditStartTime(true);
    setStartDisabled(true);
    setPrevSessionStartTime(startInput);
  };

  const handleEditStartInfusionTimeOkClick = async () => {
    const onCompleteCallback = () => {
      setEditStartTime(false);
      setPrevSessionStartTime('');
      setIsLoadingStartInfusionEdit(false);
    };

    setIsLoadingStartInfusionEdit(true);
    updateCurrentSessionTimes(onCompleteCallback);
  };

  const handleEditStartInfusionTimeCancelClick = () => {
    setEditStartTime(false);
    setStartInput(prevSessionStartTime ?? '');
    setPrevSessionStartTime('');
  };

  const body = expanded ? (
    <div className="patient-infusion-content-container">
      {section === 'infusion' && (
        <div className="infusion-tracker-container">
          <div className="infusion-time-container">
            <div className="clock">
              {format(currentTime, 'HH:mm:ss')}
            </div>
            <div className="timer">
              {infusionTime()[0]} Hours, {infusionTime()[1]} Minutes,{' '}
              {infusionTime()[2]} Seconds
            </div>
          </div>
          <div className="infusion-controls-container">
            <div className="start-container">
              <TextInput
                value={startInput}
                onChange={(e) => setStartInput(e.target.value)}
                width={329}
                placeHolder={"Please click 'Start' to log time"}
                disabled={editStartDisabled}
                readOnly={!editStartTime}
                highlightBorder={editStartTime}
              />
              <div className="edit-container">
                {!editStartTime && (
                  <Button
                    icon={
                      <Pencil
                        fill={
                          editStartDisabled
                            ? neutral400
                            : primaryBlue400
                        }
                      />
                    }
                    onClick={handleEditStartInfusionTimeClick}
                    width={'40px'}
                    height={'40px'}
                    disabled={editStartDisabled}
                    className={clsx(
                      'square-icon-button',
                      !editStartDisabled && 'edit-button-enabled'
                    )}
                  ></Button>
                )}
                {editStartTime && !editStartDisabled && (
                  <div className="edit-start-time-container">
                    <div className="cancel-button-container">
                      {!isLoadingStartInfusionEdit && (
                        <Button
                          icon={<XClose fill={primaryBlue400} />}
                          onClick={
                            handleEditStartInfusionTimeCancelClick
                          }
                          width={'40px'}
                          height={'40px'}
                          className={clsx(
                            'square-icon-button',
                            'cancel-button'
                          )}
                        ></Button>
                      )}
                    </div>
                    <Button
                      icon={
                        isLoadingStartInfusionEdit ? (
                          <LoadingSpinner
                            width={smallSpinner}
                            height={smallSpinner}
                            color={'white'}
                          />
                        ) : (
                          <Check fill={neutral50} />
                        )
                      }
                      onClick={handleEditStartInfusionTimeOkClick}
                      width={'40px'}
                      height={'40px'}
                      className={clsx(
                        'square-icon-button',
                        'save-button'
                      )}
                      disabled={isLoadingStartInfusionEdit}
                    ></Button>
                  </div>
                )}
              </div>
              <Button
                label={isLoadingStartInfusion ? '' : 'Start Infusion'}
                onClick={handleStartInfusionClick}
                icon={
                  isLoadingStartInfusion ? (
                    <LoadingSpinner
                      width={smallSpinner}
                      height={smallSpinner}
                      color={'white'}
                    />
                  ) : (
                    <Clock
                      fill={startDisabled ? neutral400 : neutral50}
                    />
                  )
                }
                iconPlacement="left"
                disabled={startDisabled}
                width={'157px'}
                height={'40px'}
                className={clsx(
                  'infusion-button',
                  !startDisabled && 'infusion-button-enabled'
                )}
              ></Button>
            </div>
            <div className="end-container">
              <div
                className={clsx(
                  showAdd && 'show-add-button',
                  'end-inputs-container'
                )}
              >
                <TextInput
                  value={endInput}
                  onChange={(e) => setEndInput(e.target.value)}
                  width={329}
                  placeHolder={"Please click 'End' to log time"}
                  disabled={editEndDisabled}
                  readOnly={!editEndTime}
                  highlightBorder={editEndTime}
                />
                <div className="edit-container">
                  {!editEndTime && (
                    <Button
                      icon={
                        <Pencil
                          fill={
                            editEndDisabled
                              ? neutral400
                              : primaryBlue400
                          }
                        />
                      }
                      onClick={handleEditEndInfusionTimeClick}
                      width={'40px'}
                      height={'40px'}
                      disabled={editEndDisabled}
                      className={clsx(
                        'square-icon-button',
                        !editEndDisabled && 'edit-button-enabled'
                      )}
                    ></Button>
                  )}

                  {editEndTime && !editEndDisabled && (
                    <div className="edit-end-time-container">
                      <div className="cancel-button-container">
                        {!isLoadingEndInfusionEdit && (
                          <Button
                            icon={<XClose fill={primaryBlue400} />}
                            onClick={
                              handleEditEndInfusionTimeCancelClick
                            }
                            width={'40px'}
                            height={'40px'}
                            className={clsx(
                              'square-icon-button',
                              'cancel-button'
                            )}
                          />
                        )}
                      </div>
                      <Button
                        icon={
                          isLoadingEndInfusionEdit ? (
                            <LoadingSpinner
                              width={smallSpinner}
                              height={smallSpinner}
                              color={'white'}
                            />
                          ) : (
                            <Check fill={neutral50} />
                          )
                        }
                        onClick={() => {
                          handleEditEndInfusionTimeOkClick();
                        }}
                        width={'40px'}
                        height={'40px'}
                        className={clsx(
                          'square-icon-button',
                          'save-button'
                        )}
                        disabled={isLoadingEndInfusionEdit}
                      />
                    </div>
                  )}
                </div>
                <Button
                  label={isLoadingEndInfusion ? '' : 'End Infusion'}
                  onClick={handleEndInfusionClick}
                  icon={
                    isLoadingEndInfusion ? (
                      <LoadingSpinner
                        width={25}
                        height={25}
                        color={'white'}
                      />
                    ) : (
                      <Clock
                        fill={endDisabled ? neutral400 : neutral50}
                      />
                    )
                  }
                  iconPlacement="left"
                  disabled={endDisabled}
                  width={'157px'}
                  height={'40px'}
                  className={clsx(
                    'infusion-button',
                    !endDisabled && 'infusion-button-enabled'
                  )}
                ></Button>
              </div>
            </div>

            {showAdd && (
              <div className="add-session-container">
                <Button
                  label="Add Session"
                  width="149px"
                  height="48px"
                  icon={<Plus fill={primaryBlue400} />}
                  iconPlacement="left"
                  className="add-session-button"
                  onClick={handleAddSessionClick}
                ></Button>
              </div>
            )}
          </div>
        </div>
      )}

      <div className="title">history</div>

      <div className={'history-table-container'}>
        <table className="table-container">
          <Header>
            <HeaderCell>
              <div>infusion date</div>
              <FilterButton
                onClick={() => {}}
                width={24}
                height={24}
              />
            </HeaderCell>
            <HeaderCell>
              <div>start time</div>
              <FilterButton
                onClick={() => {}}
                width={24}
                height={24}
              />
            </HeaderCell>
            <HeaderCell>
              <div>end time</div>
              <FilterButton
                onClick={() => {}}
                width={24}
                height={24}
              />
            </HeaderCell>
            <HeaderCell>
              <div>duration</div>
              <FilterButton
                onClick={() => {}}
                width={24}
                height={24}
              />
            </HeaderCell>
            <HeaderCell>
              <div>last updated</div>
              <FilterButton
                onClick={() => {}}
                width={24}
                height={24}
              />
            </HeaderCell>
            <HeaderCell align="center">action</HeaderCell>
          </Header>
          <tbody className="body-container">
            {infusionSessions &&
              infusionSessions.length > 0 &&
              infusionSessions.map((session, index) => (
                <InfusionHistoryRow
                  session={session}
                  index={index}
                  key={session._id}
                />
              ))}
          </tbody>
        </table>
      </div>
      <div className="loader-container">
        {isInfusionHistoryLoading && (
          <LoadingSpinner width={mediumSpinner} />
        )}
        {serverError && <p>Error:</p>}
      </div>

      <div className="title">Blood Samples</div>
      <PatientBloodSamples patientId={patientData?._id} />

      <div className="title">Infusion Notes</div>
      <InfusionNotes patientId={patientData?._id} />
    </div>
  ) : (
    <></>
  );

  return (
    <div
      className="patient-infusion-container"
      data-testid="patient-infusion-card"
    >
      <Card
        title={'INFUSION ACTIVITIES'}
        body={body}
        expandable
        expanded={expanded}
        onExpand={handleOnExpand}
      />
    </div>
  );
};

function convertToDateISO(dateString: string): string {
  if (dateString === '' || dateString === null) return '';
  // this converts a date string of HH:mm:ss to a Date ISO string

  let d = new Date();

  let [hours, minutes, seconds] = dateString.split(':');

  d.setHours(+hours); // Set the hours, using implicit type coercion
  d.setMinutes(+minutes);
  d.setSeconds(+seconds);

  return d.toISOString();
}
