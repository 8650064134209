import { useContext, useEffect, useState } from 'react';
import {
  PatientRecord,
  TreatmentPlanRecord,
  UserRole,
} from '../../../../shared/types/types';
import { TreatmentPlansNavBar } from './navBar/TreatmentPlansNavBar';
import { PlanOverview } from './plan/overview/PlanOverview';
import { SetupNotes } from './setupNotes/SetupNotes';
import './TreatmentPlansContent.scss';
import { TreatmentReport } from './plan/treatmentReport/TreatmentReport';
import { ExternalUserVerifyContext } from '../../../../shared/contexts/ExternalAprrovalContext';
import { fetchAuthenticated } from '../../../../controllers';
import {
  CAS_BACK_END_API_URL,
  URL_VERSION,
} from '../../../../constants';
import { GlobalUserContext } from '../../../../shared/contexts/GlobalUserContext';
import { BannerMessageContext } from '../../../../shared/contexts/BannerMessageContext';
import { v4 as uuidv4 } from 'uuid';
import { encryptObject } from '../../../../components/Encryption/obfuscationHandler';

export type TreatmentPlansSection =
  | 'plans-overview'
  | 'setup-notes'
  | 'treatment-report';

export interface TreatmentPlansContentProps {
  patientData: PatientRecord;
  planData: TreatmentPlanRecord;
  onUpdateTreatmentPlan: () => {};
  isUpdatingTreatmentPlan: boolean;
}

export function TreatmentPlansContent({
  patientData,
  planData,
  onUpdateTreatmentPlan,
  isUpdatingTreatmentPlan,
}: TreatmentPlansContentProps) {
  const [currentSection, setCurrentSection] =
    useState<TreatmentPlansSection>('plans-overview');
  const [isLoadingApproval, setIsLoadingApproval] = useState(false);
  const { token } = useContext(GlobalUserContext);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const acceptableRoles: UserRole[] = ['Nurse', 'Physician'];

  const { checkAuthorization } = useContext(
    ExternalUserVerifyContext
  );

  const handlePlanOverviewClick = () => {
    setCurrentSection('plans-overview');
  };

  const handleSetupNotesClick = () => {
    setCurrentSection('setup-notes');
  };

  const handleTreatmentReportClick = () => {
    setCurrentSection('treatment-report');
  };

  const handleApproveClick = async () => {
    if (!checkAuthorization) return;

    setIsLoadingApproval(true);
    const result = await checkAuthorization();

    if (result?.verified) {
      if (
        result.userInfo.some((session) => {
          return session.loginInfo.groups.some((group) => {
            return acceptableRoles.includes(group as UserRole);
          });
        })
      ) {
        const patientId = patientData._id;
        const treatmentPlanId = planData._id;
        const treatmentPlanVersion = planData._version;

        const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/treatment-plan-approve/${treatmentPlanId}`;

        const result = await fetchAuthenticated(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(
            await encryptObject(
              {
                _version: treatmentPlanVersion,
              },
              token,
              null
            )
          ),
        });

        if (result.ok) {
          addBannerMessage({
            id: uuidv4(),
            message: 'Treatment plan approved.',
            type: 'success',
          });
          onUpdateTreatmentPlan();
        } else {
          addBannerMessage({
            id: uuidv4(),
            message:
              'An error occurred when trying to approve the treatment plan.',
            type: 'error',
          });
        }
      } else {
        addBannerMessage({
          id: uuidv4(),
          message:
            'Sorry, you are not authorized to approve or unapprove a treatment plan.',
          type: 'error',
        });
      }
    } else {
      addBannerMessage({
        id: uuidv4(),
        message: 'Sorry, this user was not verified.',
        type: 'error',
      });
    }

    setIsLoadingApproval(false);
  };

  const handleUnapproveClick = async () => {
    if (!checkAuthorization) return;

    setIsLoadingApproval(true);
    const result = await checkAuthorization();

    if (result?.verified) {
      if (
        result.userInfo.some((session) => {
          return session.loginInfo.groups.some((group) => {
            return acceptableRoles.includes(group as UserRole);
          });
        })
      ) {
        const patientId = patientData._id;
        const treatmentPlanId = planData._id;
        const treatmentPlanVersion = planData._version;

        const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/treatment-plan-unapprove/${treatmentPlanId}`;

        const result = await fetchAuthenticated(url, {
          method: 'PATCH',
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(
            await encryptObject(
              {
                _version: treatmentPlanVersion,
              },
              token,
              null
            )
          ),
        });

        if (result.ok) {
          addBannerMessage({
            id: uuidv4(),
            message: 'Treatment plan unapproved.',
            type: 'success',
          });
          onUpdateTreatmentPlan();
        } else {
          addBannerMessage({
            id: uuidv4(),
            message:
              'An error occurred when trying to unapprove the treatment plan.',
            type: 'error',
          });
        }
      } else {
        addBannerMessage({
          id: uuidv4(),
          message:
            'Sorry, you are not authorized to approve or unapprove a treatment plan.',
          type: 'error',
        });
      }
    } else {
      addBannerMessage({
        id: uuidv4(),
        message: 'Sorry, this user was not verified.',
        type: 'error',
      });
    }

    setIsLoadingApproval(false);
  };

  useEffect(() => {
    // if another plan is selected, change the section back to plans-overview
    setCurrentSection('plans-overview');
  }, [planData]);

  return (
    <div
      className="treatment-plans-content-container"
      data-cy="treatment-plans-content"
    >
      <TreatmentPlansNavBar
        onPlanOverviewClick={handlePlanOverviewClick}
        onSetupNotesClick={handleSetupNotesClick}
        onTreatmentReportClick={handleTreatmentReportClick}
        currentSection={currentSection}
        reportAvailable={
          planData.casApproval.approved &&
          planData.rsApproval.approved
        }
      />
      {currentSection === 'plans-overview' ? (
        <PlanOverview
          patientData={patientData}
          planData={planData}
          onApproveClick={handleApproveClick}
          onCourseNameChange={() => {}}
          onStatusChange={() => {}}
          onUnapproveClick={handleUnapproveClick}
          isLoadingApproval={isLoadingApproval}
          isUpdatingTreatmentPlan={isUpdatingTreatmentPlan}
        />
      ) : currentSection === 'setup-notes' ? (
        <SetupNotes patientId={patientData._id} />
      ) : currentSection === 'treatment-report' ? (
        <TreatmentReport
          patientData={patientData}
          planData={planData}
        />
      ) : (
        'Nav Error'
      )}
    </div>
  );
}
