import React from 'react';
import clsx from 'clsx';
import './button.scss';

/**
 * Primary UI component for user interaction
 */

export type ButtonType = 'primary' | 'secondary';
export interface ButtonProps {
  id?: string;
  height?: string;
  width?: string;
  className?: string;
  label?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  iconPlacement?: string;
  onClick?: () => void;
  title?: string;
  type?: ButtonType;
}

export const Button = ({
  label,
  height,
  width,
  onClick,
  disabled,
  icon,
  className,
  iconPlacement,
  title,
  type,
  id,
  ...rest
}: ButtonProps) => {
  return (
    <button
      id={id}
      type="button"
      className={clsx(
        'button',
        disabled && 'disabled',
        type ?? '',
        className
      )}
      onClick={onClick}
      disabled={disabled}
      style={{ width: width, height: height }}
      title={title}
      {...rest}
    >
      {iconPlacement === 'left' && icon}
      {label && label}
      {!iconPlacement && icon && icon}
      {iconPlacement === 'right' && icon}
    </button>
  );
};
