import './Card.scss';

/*@desc what this task is */
import { ToggleExpand } from '../ToggleExpand/ToggleExpand';

export interface CardProps {
  title: string;
  body: JSX.Element;
  alignTitle?: 'center' | 'start' | 'end';
  expandable?: boolean;
  expanded?: boolean;
  onExpand?: (expanded: boolean) => void;
  fillContent?: boolean;
  centerContent?: boolean;
  show?: () => {};
}

export const Card = ({
  title,
  body,
  alignTitle,
  expandable = false,
  expanded = true,
  onExpand = () => {},
  fillContent = false,
  centerContent = false,
}: CardProps) => {
  const titleAlignment = !alignTitle ? 'start' : alignTitle;

  const handleToggleExpand = () => {
    const newExpanded = !expanded;
    onExpand(newExpanded);

    if (!onExpand) return;
    onExpand(newExpanded);
  };

  return (
    <div className={`card ${fillContent ? 'fill-content' : ''}`}>
      <div className="card-header">
        <div
          className="card-title-top"
          style={{
            alignSelf: titleAlignment,
          }}
        >
          {title.toUpperCase()}
        </div>
        {expandable && (
          <ToggleExpand
            open={expanded}
            onClick={handleToggleExpand}
          />
        )}
      </div>
      {expanded && (
        <div
          className={`card-body ${
            centerContent ? 'center-content' : ''
          }`}
        >
          {body}
        </div>
      )}
    </div>
  );
};
