import { Button } from '../../../../../components/Button/Button';
import { FilingCabinet } from '../../../../../icons/FilingCabinet';
import './PatientInfoDeleteVerify.scss';

export interface PatientInfoDeleteVerifyProps {
  onCancelClick: () => void;
  onOkClick: () => void;
  archived: boolean | undefined;
}

export function PatientInfoDeleteVerify({
  onCancelClick,
  onOkClick,
  archived,
}: PatientInfoDeleteVerifyProps) {
  const handleCancelClick = () => {
    onCancelClick();
  };

  const handleOkClick = () => {
    onOkClick();
  };

  return (
    <div className="patient-info-delete-verify-container">
      <div className="patient-info-delete-verify-title">
        {`${archived ? 'Unarchive' : 'Archive'} this patient?`}
      </div>
      <div className="patient-info-delete-verify-controls">
        <Button
          className={`ok-button ${
            archived ? 'unarchived' : 'archived'
          }`}
          label={`${archived ? 'Unarchive' : 'Archive'}`}
          onClick={handleOkClick}
          icon={<FilingCabinet />}
          iconPlacement={'left'}
        />
        <Button
          className="cancel-button"
          label="Cancel"
          onClick={handleCancelClick}
        />
      </div>
    </div>
  );
}
