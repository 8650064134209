import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AngleLeft } from '../../icons/AngleLeft';
import './Commissioning.scss';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { decryptObject } from 'src/components/Encryption/obfuscationHandler';
import { fetchAuthenticated } from 'src/controllers';

export interface CommissioningProps {}
interface PerfCheckState {
  timeToGetToController: string;
  timeToEncryptData: string;
  timeToRunService: string;
  timeToGetAllMongoDBRecords: string;
  timeToReturnData: string;
  timeForSubsequentMongoDBRecords: string;
  timeForMongoDBFilteredRequest: string;
  timeToAppendDataToObject: string;
  timeToCreateSuccessResponse: string;
  data: string;
}

export function Commissioning(props: CommissioningProps) {
  const { token } = useContext(GlobalUserContext);
  const [isExpanded, setIsExpanded] = useState(true);
  const [perfCheck1, setPerfCheck1] = useState<PerfCheckState>({
    timeToGetToController: '',
    timeToEncryptData: '',
    timeToRunService: '',
    timeToGetAllMongoDBRecords: '',
    timeToReturnData: '',
    timeForSubsequentMongoDBRecords: '', // Checks for caching
    timeForMongoDBFilteredRequest: '',
    timeToAppendDataToObject: '',
    timeToCreateSuccessResponse: '',
    data: '',
  });
  const [perfCheck2, setPerfCheck2] = useState<PerfCheckState>({
    timeToGetToController: '',
    timeToEncryptData: '',
    timeToRunService: '',
    timeToGetAllMongoDBRecords: '',
    timeToReturnData: '',
    timeForSubsequentMongoDBRecords: '', // Checks for caching
    timeForMongoDBFilteredRequest: '',
    timeToAppendDataToObject: '',
    timeToCreateSuccessResponse: '',
    data: '',
  });
  const [perfCheck3, setPerfCheck3] = useState<PerfCheckState>({
    timeToGetToController: '',
    timeToEncryptData: '',
    timeToRunService: '',
    timeToGetAllMongoDBRecords: '',
    timeToReturnData: '',
    timeForSubsequentMongoDBRecords: '', // Checks for caching
    timeForMongoDBFilteredRequest: '',
    timeToAppendDataToObject: '',
    timeToCreateSuccessResponse: '',
    data: '',
  });

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const handlePerformanceCheckOne = async () => {
    try {
      const config: RequestInit = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const time = Date.now();
      const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/performancecheck/${time}`;
      const response = await fetchAuthenticated<any>(url, config);

      if (response.ok) {
        if (response.parsedBody?.result) {
          await decryptObject(response.parsedBody.result, null, null);
          const data = response.parsedBody.result;

          const time = Date.now() - data.timeToReturnData;

          data.timeToReturnData = time;
          data.data = '';
          setPerfCheck1(data);
        }
      }
    } catch (error) {
      throw new Error('Error: Perf 1');
    }
  };
  const handlePerformanceCheckTwo = async () => {
    try {
      const config: RequestInit = {
        method: 'PUT',
        body: JSON.stringify({}),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const time = Date.now();
      const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/performancechecktwo/${time}`;
      const response = await fetchAuthenticated<any>(url, config);

      if (response.ok) {
        if (response.parsedBody?.result) {
          await decryptObject(response.parsedBody.result, null, null);
          const data = response.parsedBody.result;
          const time = Date.now() - data.timeToReturnData;
          data.timeToReturnData = time;
          data.data = '';
          setPerfCheck2(data);
        }
      }
    } catch (error) {
      throw new Error('Error: Perf 2');
    }
  };
  const handlePerformanceCheckThree = async () => {
    try {
      const config: RequestInit = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const time = Date.now();
      const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/performancecheckthree/${time}`;
      const response = await fetchAuthenticated<any>(url, config);

      if (response.ok) {
        if (response.parsedBody?.result) {
          await decryptObject(response.parsedBody.result, null, null);
          const data = response.parsedBody.result;
          const time = Date.now() - data.timeToReturnData;
          data.timeToReturnData = time;
          data.data = '';
          setPerfCheck3(data);
        }
      }
    } catch (error) {
      throw new Error('Error: Perf 3');
    }
  };

  const navigate = useNavigate();

  const body = <div>This is Commissioning content</div>;

  return (
    <div
      className="commissioning-container"
      data-testid="commissioning-container"
    >
      <Card
        title={'Commissioning'}
        body={isExpanded ? body : <></>}
        onExpand={handleExpanded}
        expandable={true}
        expanded={isExpanded}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Button
          label={'Performance Check One'}
          className={''}
          onClick={() => {
            handlePerformanceCheckOne();
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h1>Performance Check One Data</h1>
        {Object.keys(perfCheck1).map((key) => (
          <div key={key}>
            <strong>{key}:</strong>{' '}
            {JSON.stringify(perfCheck1[key as keyof PerfCheckState])}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Button
          label={'Performance Check Two'}
          className={''}
          onClick={() => {
            handlePerformanceCheckTwo();
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h1>Performance Check Two Data</h1>
        {Object.keys(perfCheck2).map((key) => (
          <div key={key}>
            <strong>{key}:</strong>{' '}
            {JSON.stringify(perfCheck2[key as keyof PerfCheckState])}
          </div>
        ))}
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Button
          label={'Performance Check Three'}
          className={''}
          onClick={() => {
            handlePerformanceCheckThree();
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <h1>Performance Check Three Data</h1>
        {Object.keys(perfCheck3).map((key) => (
          <div key={key}>
            <strong>{key}:</strong>{' '}
            {JSON.stringify(perfCheck3[key as keyof PerfCheckState])}
          </div>
        ))}
      </div>
      <div className="page-controls">
        <Button
          label={'Back'}
          className={'go-back-button'}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
}
