import { BannerMessage } from '../../../shared/contexts/BannerMessageContext';
import './BannerMessages.scss';
import { Banner } from './banner/Banner';

export interface BannerMessagesProps {
  messages: BannerMessage[];
  onCloseBanner: (id: string) => void;
}

export function BannerMessages({
  messages,
  onCloseBanner,
}: BannerMessagesProps) {
  return (
    <div className="banner-message-collection">
      {messages.map((message) => (
        <Banner
          key={message.id}
          bannerId={message.id}
          bannerMessage={message}
          onCloseClick={() => onCloseBanner(message.id)}
        />
      ))}
    </div>
  );
}
