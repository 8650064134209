import { useContext } from 'react';
import { CAS_BACK_END_API_URL, URL_VERSION } from '../../constants';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { BannerMessageContext } from '../../shared/contexts/BannerMessageContext';
import useSWR, { KeyedMutator } from 'swr';
import { fetchAuthenticated } from '..';
import { decryptObject } from '../../components/Encryption/obfuscationHandler';
import {
  TreatmentPlanRecord,
  TreatmentPlansAjaxResult,
} from '../../shared/types/types';
import { v4 } from 'uuid';

export interface UseTreatmentPlanListProps {
  patientId: string | undefined;
}

export interface UseTreatmentPlanListResponse {
  treatmentPlanListData: TreatmentPlanRecord[] | undefined;
  treatmentPlanListDataError: any;
  treatmentPlanListDataIsLoading: boolean;
  treatmentListMutate: KeyedMutator<TreatmentPlanRecord[]> | null;
}

export const useTreatmentPlanList = ({
  patientId,
}: UseTreatmentPlanListProps): UseTreatmentPlanListResponse => {
  const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/treatment-plans`;
  const { token } = useContext(GlobalUserContext);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const fetchWithToken = async (
    url: string,
    token: string | undefined
  ) => {
    let data: TreatmentPlanRecord[] = [];

    const response =
      await fetchAuthenticated<TreatmentPlansAjaxResult>(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token ?? 'NONE'}`,
        },
      });

    if (response?.ok) {
      if (response.parsedBody?.result?.treatmentPlans) {
        const decryptedObject = await decryptObject(
          response.parsedBody.result,
          token,
          null
        );
        data = [...decryptedObject?.treatmentPlans];
      }
    } else {
      addBannerMessage({
        id: v4(),
        message:
          'Uh oh! An error occurred downloading the treatment plan list!',
        type: 'error',
      });
      throw new Error(
        `An error occured when trying to download treatment plans: ${response.status}, ${response.statusText}`
      );
    }
    return data;
  };

  const { data, error, isLoading, mutate } = useSWR(
    [url, token],
    ([url, token]) => fetchWithToken(url, token ?? ''),
    { shouldRetryOnError: false }
  );

  return {
    treatmentPlanListData: data,
    treatmentPlanListDataError: error,
    treatmentPlanListDataIsLoading: isLoading,
    treatmentListMutate: mutate,
  };
};
