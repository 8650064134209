import { Route, Routes } from 'react-router-dom';
import { PopupApproval } from '../approval/PopupApproval';
import { PopupAuthenticating } from '../authenticating/PopupAuthenticating';
import { PopupLogin } from '../login/PopupLogin';
import { WebAuthConfig } from '../../../../shared/types/types';
import { IdToken } from '@auth0/auth0-react';

export interface ApprovalPopUpRoutesProps {
  webAuth: auth0.WebAuth;
  webAuthConfig: WebAuthConfig;
  postLoginUser: (result: IdToken, isApproval: boolean) => void;
}

export function ApprovalPopUpRoutes({
  webAuth,
  webAuthConfig,
  postLoginUser,
}: ApprovalPopUpRoutesProps) {
  return (
    <>
      <Routes>
        {window.location.pathname === '/handleapprove' && (
          <Route
            path={window.location.pathname}
            element={
              <PopupApproval
                webAuth={webAuth}
                webAuthConfig={webAuthConfig}
                postLoginUser={postLoginUser}
              />
            }
          />
        )}
        {window.location.pathname === '/handleauth' && (
          <Route
            path={window.location.pathname}
            element={
              <PopupAuthenticating
                webAuth={webAuth}
                webAuthConfig={webAuthConfig}
              />
            }
          />
        )}
        {window.location.pathname === '/handlelogin' && (
          <Route
            path={window.location.pathname}
            element={
              <PopupLogin
                webAuth={webAuth}
                webAuthConfig={webAuthConfig}
              />
            }
          />
        )}
      </Routes>
    </>
  );
}
