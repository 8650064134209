import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function TAELogoIcon({
  width = 299,
  height = 97,
  color = 'currentColor',
  className,
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 299 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        x="135.552"
        y="79.122"
        width="2.99475"
        height="16.8521"
        fill="#4F5758"
      />
      <path
        d="M124.08 79.122H121.085V95.9741H132.611V92.9793H124.08V79.122Z"
        fill="#4F5758"
      />
      <path
        d="M142.772 79.122H154.921V82.1167H145.767V86.2181H153.993V89.2129H145.767V95.9741H142.772V79.122Z"
        fill="#4F5758"
      />
      <path
        d="M158.255 79.122V95.9741H170.403V92.9793H161.25V89.2129H169.476V86.2181H161.25V82.1167H170.403V79.122H158.255Z"
        fill="#4F5758"
      />
      <path
        d="M235.825 79.122H238.82V79.1295L247.515 90.875V79.122H250.51V95.9741H247.515V95.9278L238.82 84.1823V95.9741H235.825V79.122Z"
        fill="#4F5758"
      />
      <rect
        x="212.882"
        y="79.122"
        width="2.99475"
        height="16.8521"
        fill="#4F5758"
      />
      <path
        d="M180.567 84.2424C180.51 86.4961 182.742 89.0452 186.116 89.0452C188.231 89.0452 189.484 89.5845 189.484 91.3275C189.484 92.8093 188.552 93.1258 186.116 93.1258C183.766 93.1258 183.17 92.413 181.498 91.2391L179.709 93.6408C182.1 95.2568 183.885 96.114 186.116 96.1205C190.585 96.1205 192.478 93.8031 192.478 91.3275C192.478 88.8188 190.983 86.0505 186.116 86.0505C184.317 86.0505 183.562 85.0895 183.562 84.2424C183.629 82.5901 184.121 82.1167 186.116 82.1167C188.456 82.1167 188.941 82.6196 190.135 83.4739L191.924 81.0722C190.037 79.6768 188.588 79.1219 186.116 79.1219C183.361 79.1151 180.574 80.6504 180.567 84.2424Z"
        fill="#4F5758"
      />
      <path
        d="M287.089 84.2424C287.031 86.4961 289.263 89.0452 292.638 89.0452C294.752 89.0452 296.005 89.5845 296.005 91.3275C296.005 92.8093 295.073 93.1258 292.638 93.1258C290.288 93.1258 289.691 92.413 288.02 91.2391L286.231 93.6408C288.621 95.2568 290.407 96.114 292.638 96.1205C297.107 96.1205 299 93.8031 299 91.3275C299 88.8188 297.504 86.0505 292.638 86.0505C290.838 86.0505 290.083 85.0895 290.083 84.2424C290.151 82.5901 290.642 82.1167 292.638 82.1167C294.978 82.1167 295.463 82.6196 296.657 83.4739L298.446 81.0722C296.559 79.6768 295.109 79.1219 292.638 79.1219C289.882 79.1151 287.095 80.6504 287.089 84.2424Z"
        fill="#4F5758"
      />
      <path
        d="M220.485 79.122V95.9741H232.633V92.9793H223.479V89.2129H231.705V86.2181H223.479V82.1167H232.633V79.122H220.485Z"
        fill="#4F5758"
      />
      <path
        d="M271.843 79.122V95.9741H283.991V92.9793H274.838V89.2129H283.064V86.2181H274.838V82.1167H283.991V79.122H271.843Z"
        fill="#4F5758"
      />
      <path
        d="M210.104 92.8939C209.006 94.2288 207.523 95.1948 205.856 95.6611C204.189 96.1274 202.418 96.0716 200.784 95.5011C199.15 94.9306 197.731 93.8731 196.72 92.4717C195.708 91.0702 195.152 89.3926 195.128 87.6658C195.104 85.9391 195.612 84.2466 196.584 82.8175C197.556 81.3884 198.945 80.2917 200.563 79.6759C202.18 79.0601 203.948 78.9549 205.628 79.3746C207.307 79.7942 208.817 80.7185 209.952 82.0222L207.832 83.8592C207.074 82.9889 206.066 82.3719 204.945 82.0917C203.824 81.8116 202.644 81.8818 201.564 82.2929C200.484 82.704 199.557 83.4361 198.908 84.3901C198.259 85.3441 197.92 86.4739 197.936 87.6267C197.952 88.7794 198.323 89.8993 198.999 90.8349C199.674 91.7704 200.621 92.4764 201.712 92.8572C202.803 93.238 203.985 93.2753 205.098 92.964C206.21 92.6527 207.2 92.0078 207.934 91.1167L210.104 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M210.104 92.8939C209.006 94.2288 207.523 95.1948 205.856 95.6611C204.189 96.1274 202.418 96.0716 200.784 95.5011C199.15 94.9306 197.731 93.8731 196.72 92.4717C195.708 91.0702 195.152 89.3926 195.128 87.6658C195.104 85.9391 195.612 84.2466 196.584 82.8175C197.556 81.3884 198.945 80.2917 200.563 79.6759C202.18 79.0601 203.948 78.9549 205.628 79.3746C207.307 79.7942 208.817 80.7185 209.952 82.0222L207.832 83.8592C207.074 82.9889 206.066 82.3719 204.945 82.0917C203.824 81.8116 202.644 81.8818 201.564 82.2929C200.484 82.704 199.557 83.4361 198.908 84.3901C198.259 85.3441 197.92 86.4739 197.936 87.6267C197.952 88.7794 198.323 89.8993 198.999 90.8349C199.674 91.7704 200.621 92.4764 201.712 92.8572C202.803 93.238 203.985 93.2753 205.098 92.964C206.21 92.6527 207.2 92.0078 207.934 91.1167L210.104 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M210.104 92.8939C209.006 94.2288 207.523 95.1948 205.856 95.6611C204.189 96.1274 202.418 96.0716 200.784 95.5011C199.15 94.9306 197.731 93.8731 196.72 92.4717C195.708 91.0702 195.152 89.3926 195.128 87.6658C195.104 85.9391 195.612 84.2466 196.584 82.8175C197.556 81.3884 198.945 80.2917 200.563 79.6759C202.18 79.0601 203.948 78.9549 205.628 79.3746C207.307 79.7942 208.817 80.7185 209.952 82.0222L207.832 83.8592C207.074 82.9889 206.066 82.3719 204.945 82.0917C203.824 81.8116 202.644 81.8818 201.564 82.2929C200.484 82.704 199.557 83.4361 198.908 84.3901C198.259 85.3441 197.92 86.4739 197.936 87.6267C197.952 88.7794 198.323 89.8993 198.999 90.8349C199.674 91.7704 200.621 92.4764 201.712 92.8572C202.803 93.238 203.985 93.2753 205.098 92.964C206.21 92.6527 207.2 92.0078 207.934 91.1167L210.104 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M210.104 92.8939C209.006 94.2288 207.523 95.1948 205.856 95.6611C204.189 96.1274 202.418 96.0716 200.784 95.5011C199.15 94.9306 197.731 93.8731 196.72 92.4717C195.708 91.0702 195.152 89.3926 195.128 87.6658C195.104 85.9391 195.612 84.2466 196.584 82.8175C197.556 81.3884 198.945 80.2917 200.563 79.6759C202.18 79.0601 203.948 78.9549 205.628 79.3746C207.307 79.7942 208.817 80.7185 209.952 82.0222L207.832 83.8592C207.074 82.9889 206.066 82.3719 204.945 82.0917C203.824 81.8116 202.644 81.8818 201.564 82.2929C200.484 82.704 199.557 83.4361 198.908 84.3901C198.259 85.3441 197.92 86.4739 197.936 87.6267C197.952 88.7794 198.323 89.8993 198.999 90.8349C199.674 91.7704 200.621 92.4764 201.712 92.8572C202.803 93.238 203.985 93.2753 205.098 92.964C206.21 92.6527 207.2 92.0078 207.934 91.1167L210.104 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M210.104 92.8939C209.006 94.2288 207.523 95.1948 205.856 95.6611C204.189 96.1274 202.418 96.0716 200.784 95.5011C199.15 94.9306 197.731 93.8731 196.72 92.4717C195.708 91.0702 195.152 89.3926 195.128 87.6658C195.104 85.9391 195.612 84.2466 196.584 82.8175C197.556 81.3884 198.945 80.2917 200.563 79.6759C202.18 79.0601 203.948 78.9549 205.628 79.3746C207.307 79.7942 208.817 80.7185 209.952 82.0222L207.832 83.8592C207.074 82.9889 206.066 82.3719 204.945 82.0917C203.824 81.8116 202.644 81.8818 201.564 82.2929C200.484 82.704 199.557 83.4361 198.908 84.3901C198.259 85.3441 197.92 86.4739 197.936 87.6267C197.952 88.7794 198.323 89.8993 198.999 90.8349C199.674 91.7704 200.621 92.4764 201.712 92.8572C202.803 93.238 203.985 93.2753 205.098 92.964C206.21 92.6527 207.2 92.0078 207.934 91.1167L210.104 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M268.669 92.8939C267.57 94.2288 266.087 95.1948 264.42 95.6611C262.753 96.1274 260.983 96.0716 259.348 95.5011C257.714 94.9306 256.295 93.8731 255.284 92.4717C254.272 91.0702 253.716 89.3926 253.692 87.6658C253.668 85.9391 254.176 84.2466 255.148 82.8175C256.12 81.3884 257.509 80.2917 259.127 79.6759C260.744 79.0601 262.512 78.9549 264.192 79.3746C265.871 79.7942 267.381 80.7185 268.516 82.0222L266.396 83.8592C265.638 82.9889 264.63 82.3719 263.509 82.0917C262.388 81.8116 261.208 81.8818 260.128 82.2929C259.048 82.704 258.121 83.4361 257.472 84.3901C256.823 85.3441 256.484 86.4739 256.5 87.6267C256.516 88.7794 256.887 89.8993 257.563 90.8349C258.238 91.7704 259.185 92.4764 260.276 92.8572C261.367 93.238 262.549 93.2753 263.662 92.964C264.775 92.6527 265.765 92.0078 266.498 91.1167L268.669 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M268.669 92.8939C267.57 94.2288 266.087 95.1948 264.42 95.6611C262.753 96.1274 260.983 96.0716 259.348 95.5011C257.714 94.9306 256.295 93.8731 255.284 92.4717C254.272 91.0702 253.716 89.3926 253.692 87.6658C253.668 85.9391 254.176 84.2466 255.148 82.8175C256.12 81.3884 257.509 80.2917 259.127 79.6759C260.744 79.0601 262.512 78.9549 264.192 79.3746C265.871 79.7942 267.381 80.7185 268.516 82.0222L266.396 83.8592C265.638 82.9889 264.63 82.3719 263.509 82.0917C262.388 81.8116 261.208 81.8818 260.128 82.2929C259.048 82.704 258.121 83.4361 257.472 84.3901C256.823 85.3441 256.484 86.4739 256.5 87.6267C256.516 88.7794 256.887 89.8993 257.563 90.8349C258.238 91.7704 259.185 92.4764 260.276 92.8572C261.367 93.238 262.549 93.2753 263.662 92.964C264.775 92.6527 265.765 92.0078 266.498 91.1167L268.669 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M268.669 92.8939C267.57 94.2288 266.087 95.1948 264.42 95.6611C262.753 96.1274 260.983 96.0716 259.348 95.5011C257.714 94.9306 256.295 93.8731 255.284 92.4717C254.272 91.0702 253.716 89.3926 253.692 87.6658C253.668 85.9391 254.176 84.2466 255.148 82.8175C256.12 81.3884 257.509 80.2917 259.127 79.6759C260.744 79.0601 262.512 78.9549 264.192 79.3746C265.871 79.7942 267.381 80.7185 268.516 82.0222L266.396 83.8592C265.638 82.9889 264.63 82.3719 263.509 82.0917C262.388 81.8116 261.208 81.8818 260.128 82.2929C259.048 82.704 258.121 83.4361 257.472 84.3901C256.823 85.3441 256.484 86.4739 256.5 87.6267C256.516 88.7794 256.887 89.8993 257.563 90.8349C258.238 91.7704 259.185 92.4764 260.276 92.8572C261.367 93.238 262.549 93.2753 263.662 92.964C264.775 92.6527 265.765 92.0078 266.498 91.1167L268.669 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M268.669 92.8939C267.57 94.2288 266.087 95.1948 264.42 95.6611C262.753 96.1274 260.983 96.0716 259.348 95.5011C257.714 94.9306 256.295 93.8731 255.284 92.4717C254.272 91.0702 253.716 89.3926 253.692 87.6658C253.668 85.9391 254.176 84.2466 255.148 82.8175C256.12 81.3884 257.509 80.2917 259.127 79.6759C260.744 79.0601 262.512 78.9549 264.192 79.3746C265.871 79.7942 267.381 80.7185 268.516 82.0222L266.396 83.8592C265.638 82.9889 264.63 82.3719 263.509 82.0917C262.388 81.8116 261.208 81.8818 260.128 82.2929C259.048 82.704 258.121 83.4361 257.472 84.3901C256.823 85.3441 256.484 86.4739 256.5 87.6267C256.516 88.7794 256.887 89.8993 257.563 90.8349C258.238 91.7704 259.185 92.4764 260.276 92.8572C261.367 93.238 262.549 93.2753 263.662 92.964C264.775 92.6527 265.765 92.0078 266.498 91.1167L268.669 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M268.669 92.8939C267.57 94.2288 266.087 95.1948 264.42 95.6611C262.753 96.1274 260.983 96.0716 259.348 95.5011C257.714 94.9306 256.295 93.8731 255.284 92.4717C254.272 91.0702 253.716 89.3926 253.692 87.6658C253.668 85.9391 254.176 84.2466 255.148 82.8175C256.12 81.3884 257.509 80.2917 259.127 79.6759C260.744 79.0601 262.512 78.9549 264.192 79.3746C265.871 79.7942 267.381 80.7185 268.516 82.0222L266.396 83.8592C265.638 82.9889 264.63 82.3719 263.509 82.0917C262.388 81.8116 261.208 81.8818 260.128 82.2929C259.048 82.704 258.121 83.4361 257.472 84.3901C256.823 85.3441 256.484 86.4739 256.5 87.6267C256.516 88.7794 256.887 89.8993 257.563 90.8349C258.238 91.7704 259.185 92.4764 260.276 92.8572C261.367 93.238 262.549 93.2753 263.662 92.964C264.775 92.6527 265.765 92.0078 266.498 91.1167L268.669 92.8939Z"
        fill="#4F5758"
      />
      <path
        d="M65.7364 65.3265C65.7364 56.6032 60.2699 48.4411 46.2798 48.4777C36.1916 48.4777 29.7686 51.877 29.7686 51.877L31.1656 57.5953C31.1656 57.5953 35.4264 54.1515 46.2798 54.1515C53.5872 54.1515 59.0826 57.9502 59.0826 65.3265V68.5914V73.1826V79.2022V88.2316V94.9513H65.7364V65.3265Z"
        fill="#4F5758"
      />
      <path
        d="M45.067 66.3325V72.0062C49.2389 72.0062 59.0826 73.1826 59.0826 73.1826V68.5914C59.0826 68.5914 53.6854 66.3325 45.067 66.3325Z"
        fill="#4F5758"
      />
      <path
        d="M45.067 72.0062V66.3325C36.5039 66.3325 25.7138 69.5154 25.7137 81.4752C25.7137 97.261 43.4359 95.8361 43.4359 95.8361V89.9496C43.4359 89.9496 32.5186 89.3539 32.3676 81.4752C32.2166 73.5965 38.6383 72.0062 45.067 72.0062Z"
        fill="#4F5758"
      />
      <path
        d="M43.4359 95.8361V89.9496C53.4341 89.9496 57.0051 85.936 59.0826 79.2022V88.2316C54.6585 92.9759 50.7508 95.0651 43.4359 95.8361Z"
        fill="#4F5758"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.585 74.2301L78.1875 74.2713C78.1875 86.0565 91.1457 90.3509 95.101 90.3509C101.317 90.3509 106.919 87.4195 109.03 83.8528L113.409 87.1019C110.466 92.075 103.238 96.284 95.2988 96.284C82.3025 96.284 71.9056 86.7063 71.9056 71.9301C71.9057 64.8669 76.2564 48.1413 93.2929 48.1413C111.488 48.1413 115.585 64.6127 115.585 74.2301ZM78.2906 69.2744C78.2906 66.4491 80.1836 53.6789 93.3211 53.6789C104.481 53.6789 108.691 63.313 108.691 69.2461L78.2906 69.2744Z"
        fill="#4F5758"
      />
      <path
        d="M0.039917 35.1863H6.69373V48.9565H21.6627V54.8431H6.69373V84.5612H0.039917V35.1863Z"
        fill="#4F5758"
      />
      <path
        d="M0.039917 84.5612H6.69373C6.69373 87.7635 10.4203 89.6613 14.5055 89.6613C17.5534 89.8711 21.0297 88.384 21.0297 88.384V94.0446C21.0297 94.0446 19.041 95.6765 12.865 95.6765C5.30576 95.6765 0.039917 91.2053 0.039917 84.5612Z"
        fill="#4F5758"
      />
      <path
        d="M138.743 15.4502C148.896 18.1837 151.017 27.4413 150.808 31.7646C150.808 31.7646 150.755 32.4 151.261 31.5743C151.767 30.7486 154.844 23.7182 151.959 16.0085C150.736 13.7008 146.443 3.99055 134.984 3.1229C124.783 2.35048 118.75 7.83627 116.033 10.6064C113.316 13.3765 112.624 17.5644 114.127 19.0854C115.631 20.6063 117.431 19.7169 120.334 18.1C123.237 16.4832 128.59 12.7167 138.743 15.4502Z"
        fill="url(#paint0_radial_1720_25254)"
      />
      <path
        d="M149.287 41.0452C141.843 48.4712 132.765 45.6791 129.126 43.3362C129.126 43.3362 128.602 42.9724 129.064 43.8235C129.526 44.6747 134.076 50.8549 142.195 52.2116C144.805 52.3057 155.361 53.4436 161.842 43.9536C167.611 35.5051 165.877 27.5374 164.837 23.7995C163.796 20.0615 160.515 17.3681 158.446 17.9097C156.377 18.4513 156.247 20.4553 156.196 23.7774C156.145 27.0996 156.73 33.6192 149.287 41.0452Z"
        fill="url(#paint1_radial_1720_25254)"
      />
      <path
        d="M121.832 37.0796C119.123 26.9201 126.08 20.4547 129.929 18.4742C129.929 18.4742 130.506 18.2027 129.537 18.1772C128.569 18.1518 120.942 19.0019 115.708 25.3549C114.321 27.5686 108.058 36.1409 113.036 46.4986C117.468 55.7195 125.235 58.2013 128.993 59.1692C132.75 60.137 136.723 58.6426 137.288 56.58C137.854 54.5174 136.183 53.4029 133.332 51.6976C130.48 49.9922 124.542 47.2391 121.832 37.0796Z"
        fill="url(#paint2_radial_1720_25254)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1720_25254"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(134.634 26.4381) rotate(2.75008) scale(50.5337 38.2993)"
        >
          <stop stopColor="#A3D283" />
          <stop offset="0.339421" stopColor="#A3D283" />
          <stop offset="0.508694" stopColor="#1B8EAD" />
          <stop offset="1" stopColor="#0081B5" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_1720_25254"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(141.826 31.9924) rotate(122.75) scale(50.5337 38.2993)"
        >
          <stop stopColor="#A3D283" />
          <stop offset="0.339421" stopColor="#A3D283" />
          <stop offset="0.508694" stopColor="#1B8EAD" />
          <stop offset="1" stopColor="#0081B5" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_1720_25254"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(133.403 35.1446) rotate(-117.25) scale(50.5337 38.2993)"
        >
          <stop stopColor="#A3D283" />
          <stop offset="0.339421" stopColor="#A3D283" />
          <stop offset="0.508694" stopColor="#1B8EAD" />
          <stop offset="1" stopColor="#0081B5" />
        </radialGradient>
      </defs>
    </svg>
  );
}
