import { format } from 'date-fns';
import { PatientAvatar } from '../avatar/PatientAvatar';
import './DisplayPatientInfo.scss';

export interface DisplayInfoProps {
  archived: boolean | undefined;
  dateOfBirth: string | undefined;
  familyName: string | undefined;
  gender: string | undefined;
  givenName: string | undefined;
  medicalId: string | undefined;
  patientType: string | undefined;
  profilePhotoString: string | undefined;
}

export function DisplayPatientInfo({
  archived,
  dateOfBirth,
  familyName,
  gender,
  givenName,
  medicalId,
  patientType,
  profilePhotoString,
}: DisplayInfoProps) {
  return (
    <div className="display-patient-info-container">
      <PatientAvatar profilePhotoString={profilePhotoString} />
      <div className="patient-info">
        <div className="column short">
          <div className="item strong">{givenName}</div>
          <div className="item strong">{familyName}</div>
        </div>

        <div className="column">
          <div className="item">
            <div className="label-and-data">
              <div className="label">profile type:</div>
              <div className="data">{patientType}</div>
            </div>
          </div>

          <div className="item">
            <div className="label-and-data">
              <div className="label">patient status:</div>
              <div className="data">
                {archived ? 'Archived' : 'Active'}
              </div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="item">
            <div className="label-and-data">
              <div className="label">gender:</div>
              <div className="data">{gender}</div>
            </div>
          </div>

          <div className="item">
            <div className="label-and-data">
              <div className="label">date of birth:</div>
              <div className="data">
                {dateOfBirth
                  ? format(new Date(dateOfBirth), 'dd-MMM-yyyy')
                  : ''}
              </div>
            </div>
          </div>
        </div>

        <div className="column">
          <div className="item">
            <div className="label-and-data">
              <div className="label">medical ID:</div>
              <div className="data">{medicalId}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
