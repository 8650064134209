import { Header } from '../../../../components/common/table/Header/Header';
import { HeaderCell } from '../../../../components/common/table/HeaderCell/HeaderCell';
import '../DomainRestrictionList.scss';

export interface DomainRestrictionListHeaderProps {}

export function DomainRestrictionListHeader() {
  return (
    <Header>
      <HeaderCell>{`domain name`}</HeaderCell>
      <HeaderCell>{`created by`}</HeaderCell>
      <HeaderCell>{`date created`}</HeaderCell>
      <HeaderCell>{`description`}</HeaderCell>
      <HeaderCell>{`action `}</HeaderCell>
    </Header>
  );
}
