import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AngleLeft } from '../../icons/AngleLeft';
import './MachineSpec.scss';

export interface MachineSpecProps {}

export function MachineSpec(props: MachineSpecProps) {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const navigate = useNavigate();

  const body = <div>This is Machine Spec content</div>;

  return (
    <div
      className="machine-spec-container"
      data-testid="machine-spec-container"
    >
      <Card
        title={'Machine Spec'}
        body={isExpanded ? body : <></>}
        onExpand={handleExpanded}
        expandable={true}
        expanded={isExpanded}
      />
      <div className="page-controls">
        <Button
          label={'Back'}
          className={'go-back-button'}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
}
