import { Check } from '../../../../icons/Check';
import './CheckBox.scss';

export interface CheckboxProps {
  checked: boolean;
}

export function Checkbox({ checked }: CheckboxProps) {
  return (
    <div className={`checkbox ${checked ? 'checked' : 'unchecked'}`}>
      {checked && <Check />}
    </div>
  );
}
