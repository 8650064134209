import './DomainRestrictionList.scss';
import { Table } from '../../../components/common/table/Table/Table';
import { TBody } from 'src/components/common/table/TBody/TBody';
import { DomainRestrictionRow } from './listRow/DomainRestrictionRow';
import { DomainRestrictionListHeader } from './listHeader/DomainRestrictionListHeader';
import { convertDateTime } from 'src/components/common/convertDateTime/convertDateTime';

export interface DomainRestrictionListProps {
  domainRestrictionList: any[];
  setDomainRestrctionList: any;
  domainRestrictionListLength: number;
}

export function DomainRestrictionList({
  domainRestrictionList,
  setDomainRestrctionList,
  domainRestrictionListLength,
}: DomainRestrictionListProps) {
  return (
    <div className="domain-restriction-list">
      <Table>
        <DomainRestrictionListHeader />
        <TBody className="domain-restriction-table-body">
          {domainRestrictionList.map((data, index: number) => (
            <DomainRestrictionRow
              domain={data?.encrypt_domain}
              index={index}
              userName={data?.user?.name}
              createdDateTime={convertDateTime(data?.createdDateTime)}
              description={data?.description}
              key={`domain_${data?._id}`}
              setDomainRestrctionList={setDomainRestrctionList}
              domainRestrictionListLength={
                domainRestrictionListLength
              }
            />
          ))}
        </TBody>
      </Table>
    </div>
  );
}
