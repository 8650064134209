import { useContext } from 'react';
import { CommissioningIcon } from '../../../icons/CommissioningIcon';
import { MachineSpecIcon } from '../../../icons/MachineSpecs';
import { PencilIcon } from '../../../icons/Pencil';
import { Records } from '../../../icons/Records';
import { ServiceIcon } from '../../../icons/ServiceIcon';
import { SyringeIcon } from '../../../icons/SyringeIcon';
import { Section } from '../../../shared/types/types';
import './DashboardMain.scss';
import { SectionButton } from './sectionButton/SectionButton';
import { GlobalUserContext } from '../../../shared/contexts/GlobalUserContext';
import { LoadingSpinner } from '../../../components/common/loader/LoadingSpinner';
import { largeSpinner } from '../../../components/common/loader/LoadingSpinner';

export interface DashboardMainProps {
  onSectionClick: (section: Section) => void;
}

export function DashboardMain({
  onSectionClick,
}: DashboardMainProps) {
  const { userInfo } = useContext(GlobalUserContext);
  const NAV_ICON_SIZE = 42;

  const handleRecordsClick = () => {
    onSectionClick('records');
  };

  const handleInfusionClick = () => {
    onSectionClick('infusion');
  };

  const handleCommissioningClick = () => {
    onSectionClick('commissioning');
  };

  const handleMachineSpecClick = () => {
    onSectionClick('machine-spec');
  };

  const handleAuditTrailClick = () => {
    onSectionClick('audit-trail');
  };

  const handleServiceClick = () => {
    onSectionClick('service');
  };

  const isAdmin = !userInfo
    ? false
    : (userInfo as string[]).includes('Admin');

  const isNurse = !userInfo
    ? false
    : (userInfo as string[]).includes('Nurse');

  const isPhysician = !userInfo
    ? false
    : (userInfo as string[]).includes('Physician');

  const isService = !userInfo
    ? false
    : (userInfo as string[]).includes('Service');

  return (
    <div className="dashboard-main-container">
      <div className="title">
        Welcome to the AlphaBeam Cloud Admin System!
      </div>
      <div className="sub-header">
        {
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas eu ullamcorper quam. Aliquam ac sollicitudin augue. Vivamus sit` amet nulla ac ex pretium ullamcorper tempus eu enim. Sed in sodales ante. Ut id lacinia mi. Aenean sed augue ac nibh vehicula pretium. Ut felis risus, consectetur tincidunt egestas sit amet, venenatis ac tellus.'
        }
      </div>
      <div className="section-button-list-container">
        {userInfo && (
          <div className="section-button-list">
            {(isAdmin || isNurse || isPhysician) && (
              <SectionButton
                onClick={handleRecordsClick}
                icon={
                  <Records
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Records"
              />
            )}
            {(isAdmin || isNurse || isPhysician) && (
              <SectionButton
                onClick={handleInfusionClick}
                icon={
                  <SyringeIcon
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Infusion"
              />
            )}
            {(isAdmin || isPhysician) && (
              <SectionButton
                onClick={handleCommissioningClick}
                icon={
                  <CommissioningIcon
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Commissioning"
              />
            )}
            {(isAdmin || isPhysician || isService) && (
              <SectionButton
                onClick={handleMachineSpecClick}
                icon={
                  <MachineSpecIcon
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Machine Spec"
              />
            )}
            {(isAdmin || isService) && (
              <SectionButton
                onClick={handleAuditTrailClick}
                icon={
                  <PencilIcon
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Audit Trail"
              />
            )}
            {(isAdmin || isService) && (
              <SectionButton
                onClick={handleServiceClick}
                icon={
                  <ServiceIcon
                    width={NAV_ICON_SIZE}
                    height={NAV_ICON_SIZE}
                  />
                }
                label="Service"
              />
            )}
          </div>
        )}
        {!userInfo && (
          <div className="loading-roles-container">
            <LoadingSpinner
              classes="loading-spinnger"
              width={largeSpinner}
              height={largeSpinner}
            />
            <div className="loading-text">Loading Role Access...</div>
          </div>
        )}
      </div>
    </div>
  );
}
