import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AngleLeft } from '../../icons/AngleLeft';
import './AuditTrail.scss';
import { AuditTrailFilter } from './fitler/AuditTrailFilter';
import { Search } from '../../components/common/search/Search';
import { Calendar } from '../../icons/Calendar';
import {
  neutral400,
  primaryBlue,
  white,
} from '../../shared/styles/variables';
import { ExclamationOutlined } from '../../icons/ExclamationOutlined';
import { EventList } from './eventList/EventList';
import {
  AduitTrailAjaxResult,
  AuditTrailEvent,
  SortDirection,
} from '../../shared/types/types';
import { toggleSortDirection } from '../../shared/util';
import {
  LoadingSpinner,
  largeSpinner,
  mediumSpinner,
} from '../../components/common/loader/LoadingSpinner';
import { dateCompare } from '../../shared/util/compareFunctions';
import { DateRangePicker, FocusedInputShape } from 'react-dates';
import { v4 } from 'uuid';
import moment, { Moment } from 'moment';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import {
  decryptObject,
  encryptObject,
} from 'src/components/Encryption/obfuscationHandler';
import { fetchAuthenticated } from 'src/controllers';
import { BannerMessageContext } from 'src/shared/contexts/BannerMessageContext';
import { ExportEventList } from './exportEventList/ExportEventList';

export interface AuditTrailProps {}

export function AuditTrail(props: AuditTrailProps) {
  const [isExpanded, setIsExpanded] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [activitySortDirection, setActivitySortDirection] =
    useState<SortDirection>('none');
  const [applicationSortDirection, setApplicationSortDirection] =
    useState<SortDirection>('none');
  const [dateSortDirection, setDateSortDirection] =
    useState<SortDirection>('none');
  const [roomSortDirection, setRoomSortDirection] =
    useState<SortDirection>('none');
  const [userSortDirection, setUserSortDirection] =
    useState<SortDirection>('none');

  const [applicationFilters, setApplicationFilters] = useState<
    Set<string>
  >(new Set());
  const [activityFilters, setActivityFilters] = useState<Set<string>>(
    new Set()
  );
  const [roomFilters, setRoomFilters] = useState<Set<string>>(
    new Set()
  );
  const [userFilters, setUserFilters] = useState<Set<string>>(
    new Set()
  );

  const [dateStart, setDateStart] = useState<Moment | null>(null);
  const dateStartId = v4();
  const [dateEnd, setDateEnd] = useState<Moment | null>(null);
  const dateEndId = v4();

  const [dateFocused, setDateFocused] =
    useState<FocusedInputShape | null>(null);

  const [isDatePickerActive, setIsDatePickerActive] = useState(false);

  const [applicationList, setApplicationList] = useState<Set<string>>(
    new Set()
  );
  const [activityList, setActivityList] = useState<Set<string>>(
    new Set()
  );
  const [userList, setUserList] = useState<Set<string>>(new Set());
  const [roomList, setRoomList] = useState<Set<string>>(new Set());
  const [auditTrailListData, setAuditTrailList] = useState([]);
  const [auditTrailListDataError, setAuditTrailListDataError] =
    useState(false);
  const [
    auditTrailListDataIsLoading,
    setAuditTrailListDataIsLoading,
  ] = useState(true);

  const [totalNumberOfDocs, setTotalNumberOfDocs] =
    useState<number>(0);
  const [lastCreatedAtTime, setLastCreatedAtTime] =
    useState<string | null>(null);
  const [lastCreatedAtTimeId, setLastCreatedAtTimeId] =
    useState<string | null>(null);
  const [loadNewData, setLoadNewData] = useState<boolean>(false);

  const { addBannerMessage } = useContext(BannerMessageContext);
  const { token } = useContext(GlobalUserContext);
  const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/get_audittrail`;
  // This applies only to the first request
  const getAuditTrailData = useCallback(async () => {
    const config: RequestInit = {
      method: 'PUT',
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await fetchAuthenticated<AduitTrailAjaxResult>(
        url,
        config
      );
      if (response.ok && response?.parsedBody) {
        const decryptData = await decryptObject(
          response.parsedBody?.result,
          token,
          null
        );
        setAuditTrailListDataIsLoading(false);
        setAuditTrailList(decryptData.documentList);
        setTotalNumberOfDocs(decryptData.totalNumberOfDocs);
        setLastCreatedAtTime(decryptData.lastCreatedAtTime);
        setLastCreatedAtTimeId(decryptData.lastCreatedAtTimeId);
      }
    } catch (error) {
      addBannerMessage({
        id: v4(),
        message:
          'Uh oh! An error occurred downloading the Audit Trail List!',
        type: 'error',
      });
      setAuditTrailListDataIsLoading(false);
      setAuditTrailList([]);
      setTotalNumberOfDocs(0);
      setLastCreatedAtTime(null);
      setLastCreatedAtTimeId(null);
    }
  }, [url, token, addBannerMessage]);

  useEffect(() => {
    if (!auditTrailListData || !auditTrailListDataIsLoading) return;
    try {
      getAuditTrailData();
    } catch (e: any) {
      const error = new Error(
        'Uh oh! An error occurred downloading the Audit Trail List!',
        e
      );
      addBannerMessage({
        id: v4(),
        message:
          'Uh oh! An error occurred downloading the Audit Trail List!',
        type: 'error',
      });
      setAuditTrailListDataIsLoading(false);
      setAuditTrailListDataError(true);
      throw error;
    }
  }, [
    getAuditTrailData,
    auditTrailListData,
    addBannerMessage,
    auditTrailListDataIsLoading,
  ]);

  useEffect(() => {
    setApplicationList(
      new Set(
        auditTrailListData.map((auditTrailEvent: AuditTrailEvent) => {
          return auditTrailEvent.application;
        })
      )
    );

    setActivityList(
      new Set(
        auditTrailListData.map((auditTrailEvent: AuditTrailEvent) => {
          return auditTrailEvent.eventDescription;
        })
      )
    );

    setRoomList(
      new Set(
        auditTrailListData.map((auditTrailEvent: AuditTrailEvent) => {
          return auditTrailEvent.room;
        })
      )
    );

    setUserList(
      new Set(
        auditTrailListData.map((auditTrailEvent: AuditTrailEvent) => {
          return auditTrailEvent.userInfo.email;
        })
      )
    );
  }, [auditTrailListData]);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const resetSorts = () => {
    setActivitySortDirection('none');
    setApplicationSortDirection('none');
    setDateSortDirection('none');
    setRoomSortDirection('none');
    setUserSortDirection('none');
  };

  const navigate = useNavigate();

  const handleSelectApplicationFilter = (value: string) => {
    const newApplicationFilters = new Set(applicationFilters);
    if (!newApplicationFilters.delete(value)) {
      newApplicationFilters.add(value);
    }
    setApplicationFilters(newApplicationFilters);
  };

  const handleSelectActivityFilter = (value: string) => {
    const newActivityFilters = new Set(activityFilters);
    if (!newActivityFilters.delete(value)) {
      newActivityFilters.add(value);
    }
    setActivityFilters(newActivityFilters);
  };

  const handleSelectRoomFilter = (value: string) => {
    const newRoomFilters = new Set(roomFilters);
    if (!newRoomFilters.delete(value)) {
      newRoomFilters.add(value);
    }
    setRoomFilters(newRoomFilters);
  };

  const handleSelectUserFilter = (value: string) => {
    const newUserFilters = new Set(userFilters);
    if (!newUserFilters.delete(value)) {
      newUserFilters.add(value);
    }
    setUserFilters(newUserFilters);
  };

  const handleSearchOnChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSearchValue(event.currentTarget.value);
  };

  const handleSearchKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'enter') {
      // add search value to filter list
    }
  };

  const handleDateSortClick = () => {
    if (dateSortDirection === 'none') {
      resetSorts();
      setDateSortDirection('descending');
    } else {
      setDateSortDirection(toggleSortDirection(dateSortDirection));
    }
  };

  const handleActivitySortClick = () => {
    if (activitySortDirection === 'none') {
      resetSorts();
      setActivitySortDirection('descending');
    } else {
      setActivitySortDirection(
        toggleSortDirection(activitySortDirection)
      );
    }
  };

  const handleApplicationSortClick = () => {
    if (applicationSortDirection === 'none') {
      resetSorts();
      setApplicationSortDirection('descending');
    } else {
      setApplicationSortDirection(
        toggleSortDirection(applicationSortDirection)
      );
    }
  };

  const handleRoomSortClick = () => {
    if (roomSortDirection === 'none') {
      resetSorts();
      setRoomSortDirection('descending');
    } else {
      setRoomSortDirection(toggleSortDirection(roomSortDirection));
    }
  };

  const handleUserSortClick = () => {
    if (userSortDirection === 'none') {
      resetSorts();
      setUserSortDirection('descending');
    } else {
      setUserSortDirection(toggleSortDirection(userSortDirection));
    }
  };

  const handleEventClick = (eventId: string) => {
    navigate(`/audit-trail/${eventId}`);
  };

  const filteredAuditTrailEventList: AuditTrailEvent[] =
    auditTrailListData
      .filter((auditEvent: AuditTrailEvent) => {
        if (activityFilters.size === 0) return true;
        return activityFilters.has(auditEvent.eventDescription);
      })
      .filter((auditEvent: AuditTrailEvent) => {
        if (applicationFilters.size === 0) return true;
        return applicationFilters.has(auditEvent.application);
      })
      .filter((auditEvent: AuditTrailEvent) => {
        if (roomFilters.size === 0) return true;
        return roomFilters.has(auditEvent.room);
      })
      .filter((auditEvent: AuditTrailEvent) => {
        if (userFilters.size === 0) return true;
        return userFilters.has(auditEvent.userInfo.email);
      })
      .filter((auditEvent: AuditTrailEvent) => {
        if (searchValue === '') return auditEvent;
        return (
          auditEvent.eventDescription.includes(searchValue) ||
          auditEvent.application.includes(searchValue) ||
          auditEvent.room.includes(searchValue) ||
          auditEvent.userInfo.email.includes(searchValue)
        );
      })
      .filter((auditEvent: AuditTrailEvent) => {
        if (!(dateStart && dateEnd)) return auditEvent;
        return moment(new Date(auditEvent.createdDateTime)).isBetween(
          dateStart,
          dateEnd
        );
      })
      .sort((a: AuditTrailEvent, b: AuditTrailEvent) => {
        if (activitySortDirection !== 'none') {
          if (activitySortDirection === 'ascending') {
            return b.eventDescription.localeCompare(
              a.eventDescription
            );
          } else if (activitySortDirection === 'descending') {
            return a.eventDescription.localeCompare(
              b.eventDescription
            );
          }
        } else if (applicationSortDirection !== 'none') {
          if (applicationSortDirection === 'ascending') {
            return b.application.localeCompare(a.application);
          } else if (applicationSortDirection === 'descending') {
            return a.application.localeCompare(b.application);
          }
        } else if (dateSortDirection !== 'none') {
          if (dateSortDirection === 'ascending') {
            return dateCompare(
              new Date(b.createdDateTime),
              new Date(a.createdDateTime)
            );
          } else if (dateSortDirection === 'descending') {
            return dateCompare(
              new Date(a.createdDateTime),
              new Date(b.createdDateTime)
            );
          }
        } else if (roomSortDirection !== 'none') {
          if (roomSortDirection === 'ascending') {
            return b.room.localeCompare(a.room);
          } else if (roomSortDirection === 'descending') {
            return a.room.localeCompare(b.room);
          }
        } else if (userSortDirection !== 'none') {
          if (userSortDirection === 'ascending') {
            return b.userInfo.email.localeCompare(a.userInfo.email);
          } else if (userSortDirection === 'descending') {
            return a.userInfo.email.localeCompare(b.userInfo.email);
          }
        }

        return 0;
      });

  const listError = (
    <div className="error">
      There was an error loading the Audit Trail Event List. Please
      refresh to try again.
    </div>
  );

  const handleClearFilters = () => {
    setActivityFilters(new Set<string>());
    setApplicationFilters(new Set<string>());
    setRoomFilters(new Set<string>());
    setUserFilters(new Set<string>());
    setDateStart(null);
    setDateEnd(null);
    setSearchValue('');
  };

  const handleDatesChange = ({
    startDate,
    endDate,
  }: {
    startDate: Moment | null;
    endDate: Moment | null;
  }) => {
    setDateStart(startDate);
    setDateEnd(endDate);
    if (startDate && endDate) {
      setIsDatePickerActive(false);
    }
  };

  const handleDateFocusChange = (
    focusedInput: FocusedInputShape | null
  ) => {
    setDateFocused(focusedInput);
  };

  const handleDateRangeClick = () => {
    setIsDatePickerActive(!isDatePickerActive);
  };

  const handleNewAuditTrailGet = async () => {
    if (auditTrailListData.length >= totalNumberOfDocs) return;
    setLoadNewData(true);

    const body = JSON.stringify(
      await encryptObject(
        {
          latestTime: lastCreatedAtTime,
          latestTimeId: lastCreatedAtTimeId,
          totalCount: totalNumberOfDocs,
        },
        token,
        null
      )
    );
    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<AduitTrailAjaxResult>(
      url,
      config
    );
    if (response.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      setAuditTrailListDataIsLoading(false);
      const newList = JSON.parse(JSON.stringify(auditTrailListData));
      newList.push(...decryptData.documentList);
      setLoadNewData(false);
      setAuditTrailList(newList);
      setLastCreatedAtTime(decryptData.lastCreatedAtTime);
      setLastCreatedAtTimeId(decryptData.lastCreatedAtTimeId);
    }
  };

  const datePicker = (
    <div className={`date-picker ${!dateFocused ? 'compact' : ''}`}>
      <DateRangePicker
        startDate={dateStart}
        startDateId={dateStartId}
        endDate={dateEnd}
        endDateId={dateEndId}
        onDatesChange={handleDatesChange}
        focusedInput={dateFocused}
        onFocusChange={handleDateFocusChange}
        isOutsideRange={() => false} // a hack to show all dates in the past
      />
    </div>
  );

  const filtersEmpty =
    applicationFilters.size === 0 &&
    activityFilters.size === 0 &&
    userFilters.size === 0 &&
    roomFilters.size === 0 &&
    !dateStart &&
    !dateEnd &&
    !searchValue;

  const body = (
    <div className="audit-trail-content-container">
      <div className="filters-container">
        <div className="section-header">filters</div>
        <div className="filters-list">
          <AuditTrailFilter
            category="application"
            values={Array.from(applicationList)}
            selectedValues={Array.from(applicationFilters)}
            onSelectValue={handleSelectApplicationFilter}
            onClearSelectedValues={() =>
              setApplicationFilters(new Set<string>())
            }
            capitalized
          />
          <AuditTrailFilter
            category="event description"
            values={Array.from(activityList)}
            selectedValues={Array.from(activityFilters)}
            onSelectValue={handleSelectActivityFilter}
            onClearSelectedValues={() =>
              setActivityFilters(new Set<string>())
            }
            capitalized
          />
          <AuditTrailFilter
            category="room"
            values={Array.from(roomList)}
            selectedValues={Array.from(roomFilters)}
            onSelectValue={handleSelectRoomFilter}
            onClearSelectedValues={() =>
              setRoomFilters(new Set<string>())
            }
            capitalized
          />
          <AuditTrailFilter
            category="user"
            values={Array.from(userList)}
            selectedValues={Array.from(userFilters)}
            onSelectValue={handleSelectUserFilter}
            onClearSelectedValues={() =>
              setUserFilters(new Set<string>())
            }
            className="user-filter"
            searchEnabled
          />
        </div>
      </div>
      <div className="event-list-container">
        <div className="section-header">event list</div>
        <div className="filter-controls-container">
          <Search
            onChange={handleSearchOnChange}
            onKeyDown={handleSearchKeyDown}
            placeholder="Search"
            value={searchValue}
          />
          <div className="date-picker-container">
            <Button
              label="Date Range"
              icon={<Calendar color={white} />}
              iconPlacement="left"
              className="date-range-button primary"
              onClick={handleDateRangeClick}
            />
            {isDatePickerActive && datePicker}
          </div>

          <Button
            label="Reset Filters"
            icon={
              <ExclamationOutlined
                color={filtersEmpty ? neutral400 : primaryBlue}
              />
            }
            iconPlacement="left"
            className={`secondary ${
              !dateEnd && !dateStart ? 'disabled' : ''
            }`}
            onClick={handleClearFilters}
          />
        </div>
        {auditTrailListDataError ? (
          listError
        ) : auditTrailListDataIsLoading ? (
          <div className="load-spinner">
            <LoadingSpinner
              width={largeSpinner}
              height={largeSpinner}
            />
          </div>
        ) : (
          <EventList
            eventList={filteredAuditTrailEventList}
            dateSortDirection={dateSortDirection}
            activitySortDirection={activitySortDirection}
            userSortDirection={userSortDirection}
            applicationSortDirection={applicationSortDirection}
            roomSortDirection={roomSortDirection}
            loadingNewData={loadNewData}
            handleNewAuditTrailGet={handleNewAuditTrailGet}
            onDateSort={handleDateSortClick}
            onActivitySort={handleActivitySortClick}
            onUserSort={handleUserSortClick}
            onApplicationSort={handleApplicationSortClick}
            onRoomSort={handleRoomSortClick}
            onEventClick={handleEventClick}
          />
        )}
        <div className="load-spinner">
          {loadNewData ? (
            <LoadingSpinner
              width={mediumSpinner}
              height={mediumSpinner}
            />
          ) : null}
        </div>
      </div>
      <ExportEventList />
    </div>
  );

  return (
    <div className="audit-trail-container" data-testid="audit-trail">
      <Card
        title={'Audit Trail Events'}
        body={isExpanded ? body : <></>}
        onExpand={handleExpanded}
        expandable
        expanded={isExpanded}
      />
      <div className="page-controls">
        <Button
          label={'Back'}
          className={'go-back-button'}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
}
