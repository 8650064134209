import { SingleDatePicker, toMomentObject } from 'react-dates';
import { Calendar } from '../../../../icons/Calendar';
import { format } from 'date-fns';
import {
  NavNextIcon,
  NavPrevIcon,
  renderMonthElement,
} from '../../../../components/common/calendar';
import { Moment } from 'moment';
import { useEffect, useState } from 'react';
import { PatientAvatar } from '../avatar/PatientAvatar';
import './EditPatientInfo.scss';
import '../../../../components/common/calendar/Calendar.scss';
import { PatientRecord } from 'src/shared/types/types';

export interface EditPatientInfoProps {
  archived: boolean | undefined;
  dateOfBirth: string | undefined;
  familyName: string | undefined;
  gender: string | undefined;
  givenName: string | undefined;
  medicalId: string | undefined;
  patientType: string | undefined;
  profilePhotoString: string | undefined;
  isEditingPatientInfo: boolean;
  tempPatientInfo: Partial<PatientRecord>;
  setTempPatientInfo: (data: Partial<PatientRecord>) => void;
}

export function EditPatientInfo({
  archived,
  dateOfBirth,
  givenName,
  familyName,
  gender,
  medicalId,
  patientType,
  profilePhotoString,
  tempPatientInfo,
  isEditingPatientInfo,
  setTempPatientInfo,
}: EditPatientInfoProps) {
  const [calendarFocused, setCalendarFocused] = useState(false);

  useEffect(() => {
    const tempPatientRecord: Partial<PatientRecord> = {
      encrypt_familyName: familyName,
      encrypt_givenName: givenName,
      encrypt_dateOfBirth: dateOfBirth,
      encrypt_gender: gender,
      encrypt_type: patientType,
      encrypt_profilePhotoImageString: profilePhotoString,
    };
    setTempPatientInfo(tempPatientRecord);
  }, [
    familyName,
    givenName,
    dateOfBirth,
    gender,
    patientType,
    profilePhotoString,
    setTempPatientInfo,
  ]);

  const handlePatientInfoUpdate = async (
    dataKey: string | undefined,
    valueToUpdate: any
  ) => {
    if (!dataKey || !valueToUpdate) return;
    let updatedData: any = { ...tempPatientInfo };
    updatedData[dataKey] = valueToUpdate;
    setTempPatientInfo(updatedData);
  };

  return (
    <div className="edit-patient-info-container">
      <PatientAvatar
        profilePhotoString={profilePhotoString}
        editing={true}
        tempPatientInfo={tempPatientInfo}
        isEditingPatientInfo={isEditingPatientInfo}
        onUploadImageClick={handlePatientInfoUpdate}
      />
      <div className="patient-info">
        <div className="row">
          <div className="column">
            <div className="label-info-container">
              <div className="label">first name:</div>
              <input
                className="textfield"
                placeholder={givenName}
                onChange={(e) => {
                  handlePatientInfoUpdate(
                    'encrypt_givenName',
                    e.target.value
                  );
                }}
                value={tempPatientInfo?.encrypt_givenName}
              ></input>
            </div>
            <div className="label-info-container">
              <div className="label">last name:</div>
              <input
                className="textfield"
                placeholder={familyName}
                onChange={(e) => {
                  handlePatientInfoUpdate(
                    'encrypt_familyName',
                    e.target.value
                  );
                }}
                value={tempPatientInfo?.encrypt_familyName}
              ></input>
            </div>
            <div className="label-info-container">
              <div className="label">profile type:</div>
              <select
                className="select"
                onChange={(e) => {
                  handlePatientInfoUpdate(
                    'encrypt_type',
                    e.target.value
                  );
                }}
                value={tempPatientInfo?.encrypt_type}
                placeholder={patientType}
              >
                <option value="patient">Patient</option>
                <option value="phantom">Phantom</option>
              </select>
            </div>
            <div className="label-info-container">
              <div className="label">patient status:</div>
              {archived ? (
                <div className="archived">ARCHIVED</div>
              ) : (
                <div className="unarchived">ACTIVE</div>
              )}
            </div>
          </div>
          <div className="column">
            <div className="label-info-container">
              <div className="label">gender:</div>
              <select
                className="select"
                value={tempPatientInfo?.encrypt_gender}
                placeholder={gender}
                onChange={(e) => {
                  handlePatientInfoUpdate(
                    'encrypt_gender',
                    e.target.value
                  );
                }}
              >
                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Unspecified">Unspecified</option>
              </select>
            </div>
            <div className="label-info-container">
              <div className="label">date of birth:</div>
              <div className="date-picker">
                <SingleDatePicker
                  placeholder={
                    dateOfBirth
                      ? format(new Date(dateOfBirth), 'dd-MMM-yyyy')
                      : ''
                  }
                  date={toMomentObject(dateOfBirth)}
                  onDateChange={(t: Moment | null) => {
                    handlePatientInfoUpdate(
                      'encrypt_dateOfBirth',
                      t?.toISOString()
                    );
                    setCalendarFocused(false);
                  }}
                  focused={calendarFocused}
                  onFocusChange={() => {
                    if (!calendarFocused) setCalendarFocused(true);
                  }}
                  id="singleDatePicker"
                  numberOfMonths={1}
                  displayFormat="dd-MMM-yyyy"
                  small
                  customInputIcon={<Calendar />}
                  inputIconPosition={'after'}
                  noBorder
                  isOutsideRange={() => false}
                  renderMonthElement={renderMonthElement}
                  navNext={<NavNextIcon />}
                  navPrev={<NavPrevIcon />}
                  readOnly
                />
              </div>
            </div>
            <div className="label-info-container">
              <div className="label">medical ID:</div>
              <input
                className="textfield disabled"
                disabled
                placeholder={medicalId}
              />
            </div>
            <div className="label-info-container"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
