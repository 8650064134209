import { useEffect, useState } from 'react';
import { CloudIcon } from '../../shared/assets/cloud-icon-green';
import './Navbar.scss';
import { format } from 'date-fns';
import { Section } from '../../shared/types/types';
import { InfusionButton } from './InfusionButton';
import { RecordsButton } from './RecordsButton';
export interface NavbarProps {
  section: Section;
}

const Navbar = ({ section }: NavbarProps) => {
  const dayMonthYear = format(new Date(), 'dd-LLL-yyyy');
  const hoursMinsSeconds = format(new Date(), 'HH:mm:ss');
  // eslint-disable-next-line
  const [time, setTime] = useState<number>(Date.now());

  useEffect(() => {
    const interval = setInterval(() => setTime(Date.now()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div data-testid="navbar" className="navbar-container">
      {section === 'infusion' ? (
        <InfusionButton data-testid="infusion-button" />
      ) : section === 'records' ? (
        <RecordsButton data-testid="records-button" />
      ) : (
        <div className="navbar-item" data-testid="navbar-stub" />
      )}
      <div className="navbar-item">
        <CloudIcon />
        <span className="nbs-status">NBS: Ready</span>
      </div>
      <div className="navbar-item time-container">
        <span className="current-time">{`${dayMonthYear} ${hoursMinsSeconds}`}</span>
      </div>
    </div>
  );
};

export default Navbar;
