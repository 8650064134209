// colors
export const activeBlue: string = '#2cc0e8';
export const grey: string = '#c5c5c5';
export const lightGrey: string = '#e7e7e7';
export const offWhite: string = '#FBFBFB';
export const silver: string = 'C5C5C5';

export const neutral50: string = '#fbfbfb';
export const neutral100: string = '#f6f6f6';
export const neutral200: string = '#f1f1f1';
export const neutral300: string = '#e7e7e7';
export const neutral400: string = '#c5c5c5';
export const neutral600: string = '#7d7d7d';
export const neutral700: string = '#686868';
export const neutral800: string = '#494949';
export const neutral900: string = '#272727';

export const errorRed50: string = '#FBEAED';
export const errorRed100: string = '#F6CBCF';
export const errorRed200: string = '#E19897';
export const errorRed300: string = '#DB4537';
export const errorRed400: string = '#D9534F';
export const errorRed500: string = '#D27170';
export const errorRed600: string = '#CD3D36';
export const errorRed700: string = '#BB3430';
export const errorRed800: string = '#AE2E29';
export const errorRed900: string = '#A0241E';

export const successGreen50: string = '#E7F5E7';
export const successGreen100: string = '#C5E5C4';
export const successGreen200: string = '#A0D49F';
export const successGreen300: string = '#7AC479';
export const successGreen400: string = '#5CB85C';
export const successGreen500: string = '#3EAB3E';
export const successGreen600: string = '#359D36';
export const successGreen700: string = '#288B2B';
export const successGreen800: string = '#1C7A20';
export const successGreen900: string = '#025B0E';

export const warningOrange50: string = '#FCF1E0';
export const warningOrange100: string = '#F9DBB1';
export const warningOrange200: string = '#F4C47F';
export const warningOrange300: string = '#F0AD4E';
export const warningOrange400: string = '#ED9B2B';
export const warningOrange500: string = '#EB8B0C';
export const warningOrange600: string = '#E7800B';
export const warningOrange700: string = '#E17109';
export const warningOrange800: string = '#DA6207';
export const warningOrange900: string = '#D14804';

export const neutralGrey: string = '#494949';
export const primaryBlue: string = '#0091b2';
export const primaryBlue300: string = '#5fcbe9';
export const primaryBlue400: string = '#2cc0e8';
export const primaryBlue500: string = '#1ab5e1';
export const successGreen: string = '#359d36';
export const tealBlue: string = '#007e99';
export const warning: string = '#e7800b';
export const white: string = '#fff';
export const black: string = '#000';
