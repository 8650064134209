import { useContext, useEffect, useState, useCallback } from 'react';
import { useAuth0, IdToken } from '@auth0/auth0-react';

import auth0 from 'auth0-js';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import { HttpResponse, fetchAuthenticated } from 'src/controllers';
import {
  ApproverAjaxResult,
  ApproverResult,
  UserSession,
  ExternalUserVerifyContextResult,
  WebAuthConfig,
} from 'src/shared/types/types';

import { GlobalUserContext } from '../../contexts/GlobalUserContext';

export interface UseAuthentication {
  loginWithRedirect: () => void;
  logOut: () => void;
  checkAuthorization: () => Promise<ExternalUserVerifyContextResult> | null;
  postLoginUser: (result: IdToken, isApprover: boolean) => void;
  webAuthConfig: WebAuthConfig;
  webAuth: auth0.WebAuth;
}

export function useAuthentication(): UseAuthentication {
  const KARAN_TESTAUTH = true;
  const [authState, setAuthState] = useState<IdToken | null>(null);
  // TODO: keep this commented code to test future authentication features
  // const [approverState, setApproverState] = useState<ApproverInfo | null>(null);
  // const [approvalBanner, setApprovalBanner] = useState<BannerMessage | null>(null);
  const [token, setToken] = useState<string | null>(null);
  const {
    isAuthenticated,
    logout,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();
  const [dbuser, setDbuser] = useState<ApproverResult | undefined>();

  const globalUserState = useContext(GlobalUserContext);
  const { loginWithRedirect } = useAuth0();

  const webAuthConfig: WebAuthConfig = {
    clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
    domain: `${process.env.REACT_APP_AUTH0_DOMAIN}`,
    audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN}/api/v2/`,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URI}`,
    responseType: 'token id_token',
    returnTo: `${window.origin}/handlelogin`,
  };

  const webAuth = new auth0.WebAuth({
    domain: webAuthConfig.domain,
    clientID: webAuthConfig.clientID,
    responseType: webAuthConfig.responseType,
    redirectUri: webAuthConfig.redirectUri,
  });

  const systemId = navigator.userAgent;

  const filteredSession = (userSessions: UserSession[]) =>
    userSessions.filter((session) => {
      return session.loginId === authState?.sid;
    });

  const getIDtoken = useCallback(async () => {
    const tokenClaim = await getIdTokenClaims();
    tokenClaim && setAuthState(tokenClaim);
  }, [getIdTokenClaims]);

  const getSilentToken = useCallback(async () => {
    try {
      const tokenClaim = await getAccessTokenSilently();
      tokenClaim && setToken(tokenClaim);
    } catch (error) {
      // TODO: Error handling for not authenticated from SAML
    }
  }, [getAccessTokenSilently]);

  const setAuthContext = useCallback(async () => {
    if (!isAuthenticated || !authState) {
      globalUserState?.setIsLoggedIn?.(false);
      globalUserState?.setFirstName?.('');
      globalUserState.setLastName?.('');
      globalUserState.setUserName?.('');
      globalUserState.setUserInfo?.(null);
      globalUserState.setToken?.('');
    } else {
      globalUserState.setIsLoggedIn?.(true);
      !KARAN_TESTAUTH &&
        globalUserState.setFirstName?.(
          authState?.name?.split(' ')[0]
        );
      !KARAN_TESTAUTH &&
        globalUserState.setLastName?.(authState?.name?.split(' ')[1]);
      globalUserState.setUserName?.(authState?.email);
      globalUserState.setToken?.(authState?.__raw);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState, isAuthenticated]);

  const removeUserSession = async (dbSessionId: string) => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/authuserdelete/${dbSessionId}`;
    const config: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${authState?.__raw}`,
      },
    };
    const response = await fetchAuthenticated<ApproverAjaxResult>(
      url,
      config
    );
    response.ok && logout({ returnTo: window.location.origin });
  };

  const postLoginUser = async (
    result: IdToken,
    isApprover: boolean
  ) => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/authapprover`;
    const body = JSON.stringify({
      ...result,
      isApprover,
      systemId,
    });
    const config: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${result.__raw}`,
      },
      body,
    };
    const response = await fetchAuthenticated<ApproverAjaxResult>(
      url,
      config
    );
    setDbuser(response.parsedBody?.result);
    return response.ok ? response.parsedBody?.result : null;
  };

  const removeApproverInfo = async (dbSessionId: string) => {
    const tokenIDClaim = await getIdTokenClaims();
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/authapproverdelete/${dbSessionId}`;
    const config: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${tokenIDClaim?.__raw}`,
      },
    };
    await fetchAuthenticated<ApproverAjaxResult>(url, config);
  };

  const handleApproverSession = (existingSession: UserSession[]) => {
    const { _id } = existingSession[0];
    removeApproverInfo(`${_id}`);

    // TODO: keep this commented code to test future authentication features
    //setApproverState(approverInfo);
    /*if (approverInfo.groups) {
            // invoke approval callback if a proper role appears
            approverInfo.groups[0] === 'Physician'
                ? setApprovalBanner({
                    id: approverInfo.sub,
                    message: 'Approved',
                    type: 'success',
                })
                : setApprovalBanner({
                    id: approverInfo.sub,
                    message: 'Not Approved',
                    type: 'warning',
                });
        }*/
  };

  const handleUserLoginInformation = (
    existingSession: UserSession[]
  ) => {
    const { _id, loginInfo } = existingSession[0];
    globalUserState.setUserId?.(_id);
    globalUserState.setFirstName?.(loginInfo.name?.split(' ')[0]);
    globalUserState.setLastName?.(loginInfo.name?.split(' ')[1]);
    globalUserState.setUserName?.(loginInfo.upn);
    globalUserState.setUserInfo(loginInfo.groups);
  };

  useEffect(() => {
    isAuthenticated && KARAN_TESTAUTH && verifyAuthorizer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    getSilentToken();
    getIDtoken();
    setAuthContext();
  }, [
    getIDtoken,
    getIdTokenClaims,
    getSilentToken,
    isAuthenticated,
    setAuthContext,
    token,
  ]);

  useEffect(() => {
    // When dbuser is updated, check to see if the user session is the current user session.
    if (dbuser != null) {
      const existingSession = filteredSession(dbuser.userSession);
      if (existingSession.length >= 1) {
        // if we have an existing user session, then we're seeing if a user has approval status.
        existingSession.length >= 1 &&
          existingSession[0].approverInfo &&
          handleApproverSession(existingSession);
        existingSession.length >= 1 &&
          existingSession[0].loginInfo &&
          handleUserLoginInformation(existingSession);
      } else {
        // otherwise, no one has logged in yet. Log them in.
        authState && postLoginUser(authState, false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dbuser]);

  const authorizePopup = (
    webAuthConfig: auth0.AuthOptions
  ): Promise<ExternalUserVerifyContextResult> => {
    // This creates a popup to allow the user to authenticate, and then calls a verifyAuthorizer callback.
    return new Promise<ExternalUserVerifyContextResult>(
      (resolve, reject) => {
        webAuth.popup.authorize(
          {
            redirectUri: `${window.origin}/handleauth`,
            responseType: `${webAuthConfig.responseType}`,
            domain: webAuthConfig.domain,
          },
          async () => {
            verifyAuthorizer()
              .then((result) => {
                return resolve({
                  verified: true,
                  userInfo: result.parsedBody
                    ? filteredSession(
                        result.parsedBody.result.userSession
                      )
                    : [],
                  errors: [],
                });
              })
              .catch((error) => {
                return reject({
                  verified: false,
                  userInfo: null,
                  errors: [error],
                });
              });
          }
        );
      }
    );
  };

  const logOutUser = async () => {
    globalUserState?.setIsLoggedIn?.(false);
    globalUserState?.setFirstName?.('');
    globalUserState.setLastName?.('');
    globalUserState.setUserName?.('');
    globalUserState.setUserId?.('');

    KARAN_TESTAUTH && dbuser
      ? removeUserSession(filteredSession(dbuser.userSession)[0]._id)
      : logout({ returnTo: window.location.origin });
  };

  const verifyAuthorizer = async (): Promise<
    HttpResponse<ApproverAjaxResult>
  > => {
    // This calls a custom backend API endpoint to validate the user token and return their user info
    // to check their role without messing with session data.
    return new Promise<HttpResponse<ApproverAjaxResult>>(
      async (resolve, reject) => {
        const tokenIDClaim = await getIdTokenClaims();
        const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/authenticateapproververify`;
        const config: RequestInit = {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${tokenIDClaim?.__raw}`,
          },
        };
        const response = await fetchAuthenticated<ApproverAjaxResult>(
          url,
          config
        );
        if (response.ok && response.parsedBody) {
          setDbuser(response.parsedBody?.result);
          return resolve(response);
        } else {
          return reject(response);
        }
      }
    );
  };

  return {
    checkAuthorization: () => authorizePopup(webAuthConfig),
    logOut: logOutUser,
    loginWithRedirect,
    postLoginUser,
    webAuthConfig,
    webAuth,
  };
}
