import { IconTypeProps } from '../shared/types/types';

export function CarrotDown({
  width = 15,
  height = 9,
  color = 'currentColor',
  ...props
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 15 9"
      fill="none"
      {...props}
    >
      <path
        d="M13.7108 1.20986C13.6178 1.11613 13.5072 1.04174 13.3854 0.990969C13.2635 0.9402 13.1328 0.914062 13.0008 0.914062C12.8688 0.914062 12.7381 0.9402 12.6162 0.990969C12.4944 1.04174 12.3838 1.11613 12.2908 1.20986L7.7108 5.78985C7.61783 5.88358 7.50723 5.95797 7.38537 6.00874C7.26351 6.05951 7.13281 6.08565 7.0008 6.08565C6.86878 6.08565 6.73808 6.05951 6.61622 6.00874C6.49436 5.95797 6.38376 5.88358 6.2908 5.78985L1.71079 1.20986C1.61783 1.11613 1.50723 1.04174 1.38537 0.990969C1.26351 0.9402 1.13281 0.914062 1.00079 0.914062C0.868781 0.914062 0.738075 0.9402 0.616216 0.990969C0.494357 1.04174 0.383756 1.11613 0.290793 1.20986C0.104542 1.39722 0 1.65067 0 1.91486C0 2.17904 0.104542 2.4325 0.290793 2.61986L4.8808 7.20985C5.4433 7.77165 6.20579 8.08721 7.0008 8.08721C7.7958 8.08721 8.5583 7.77165 9.1208 7.20985L13.7108 2.61986C13.8971 2.4325 14.0016 2.17904 14.0016 1.91486C14.0016 1.65067 13.8971 1.39722 13.7108 1.20986Z"
        fill={color}
      />
    </svg>
  );
}
