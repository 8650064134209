import { createContext } from 'react';

export type MessageType = 'success' | 'warning' | 'error' | 'info';

export interface BannerMessage {
  id: string;
  message: string;
  type: MessageType;
}

export interface BannerProps {
  addBannerMessage: (message: BannerMessage) => void;
}

export const BannerMessageContext = createContext<BannerProps>({
  addBannerMessage: () => {},
});
