import { XClose } from '../../../../../icons/XClose';
import './CloseButton.scss';

export interface CloseButtonProps {
  onClick: () => void;
}

export function CloseButton({ onClick }: CloseButtonProps) {
  return (
    <div className="close-button-container" onClick={onClick}>
      <XClose width={16} height={16} />
    </div>
  );
}
