import { format, parseISO, isValid } from 'date-fns';

export function convertDateTime(utcDateTime: string): string {
  let text = 'N/A';

  if (utcDateTime && isValid(parseISO(utcDateTime))) {
    const parsedTime = parseISO(utcDateTime);
    text = format(new Date(parsedTime), 'dd-MMM-yyyy HH:mm:ss');
  }

  return text;
}

export function convertDate(utcDateTime: string): string {
  let text = 'N/A';

  if (utcDateTime && isValid(parseISO(utcDateTime))) {
    const parsedTime = parseISO(utcDateTime);
    text = format(new Date(parsedTime), 'dd-MMM-yyyy');
  }

  return text;
}

export function convertTime(utcDateTime: string): string {
  let text = 'N/A';

  if (utcDateTime && isValid(parseISO(utcDateTime))) {
    const parsedTime = parseISO(utcDateTime);
    text = format(new Date(parsedTime), 'HH:mm:ss');
  }

  return text;
}
