import { useCallback, useContext, useEffect, useState } from 'react';
import { CarrotRight } from '../../shared/assets/carrot-right';
import { Logout } from '../../shared/assets/logout';
import { Button } from '../Button/Button';
import './IdlePopup.scss';
import {
  ApproverAjaxResult,
  IdlePopupProps,
} from '../../shared/types/types';
import { useAuth0 } from '@auth0/auth0-react';
import { fetchAuthenticated } from 'src/controllers';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import {
  LoadingSpinner,
  largeSpinner,
} from '../common/loader/LoadingSpinner';

const IdlePopup = ({ show, onClose }: IdlePopupProps) => {
  const globalUserState = useContext(GlobalUserContext);
  const { token, userId } = globalUserState;
  // initialize logout countdown
  const secondsToAutomaticLogout = Number(
    process.env.REACT_APP_SECONDS_LEFT_BEFORE_IDLE_LOGOUT
  );

  const minutesToAutomaticLogout = Number(
    process.env.REACT_APP_MINUTES_LEFT_BEFORE_IDLE_LOGOUT
  );

  const [secondsToLogout, setSecondsToLogout] = useState<number>(
    secondsToAutomaticLogout
  );
  const [minutesToLogout, setMinutesToLogout] = useState<number>(
    minutesToAutomaticLogout
  );
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const addZero = secondsToLogout < 10 ? '0' : '';
  const { logout } = useAuth0();

  const removeUserSession = useCallback(async () => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/authuserdelete/${userId}`;
    const config: RequestInit = {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await fetchAuthenticated<ApproverAjaxResult>(
      url,
      config
    );
    response.ok && logout({ returnTo: window.location.origin });
  }, [logout, token, userId]);

  useEffect(() => {
    const updateCountdown = (
      secondsToLogout: number,
      minutesToLogout: number
    ) => {
      setSecondsToLogout(secondsToLogout - 1);
      if (secondsToLogout === 0) {
        setMinutesToLogout(minutesToLogout - 1);
        setSecondsToLogout(59);
      }
      if (secondsToLogout === 1 && minutesToLogout === 0) {
        removeUserSession();
        onClose(true);
      }
    };

    while (secondsToLogout > 0 || minutesToLogout > 0) {
      const interval = setInterval(
        () => updateCountdown(secondsToLogout, minutesToLogout),
        1000
      );
      return () => {
        clearInterval(interval);
      };
    }
  }, [
    minutesToLogout,
    secondsToLogout,
    onClose,
    logout,
    removeUserSession,
  ]);

  const handleIdleLogout = () => {
    setIsLoggingOut(true);

    removeUserSession()
      .then(() => {
        onClose(true);
      })
      .finally(() => {
        setIsLoggingOut(false);
      });
  };

  const handleOnMouseOver = (event: any) => {
    event.stopPropagation();
  };

  if (!show) {
    return null;
  } else {
    return (
      <div
        className="idle-popup-container"
        onMouseMove={handleOnMouseOver}
      >
        <div className="idle-popup-parent">
          {isLoggingOut ? (
            <div className="closing-spinner">
              <LoadingSpinner
                width={largeSpinner}
                height={largeSpinner}
              />
            </div>
          ) : (
            <>
              <span className="popup-header">
                Are you still there?
              </span>
              <span className="popup-body-text">
                You will be automatically logged out in
              </span>
              <span className="popup-countdown">
                {minutesToLogout}:{addZero}
                {secondsToLogout}
              </span>
              <div className="popup-buttons">
                <Button
                  label="Log Out"
                  className="logout-button"
                  icon={<Logout fill="#0091b2" />}
                  iconPlacement="left"
                  onClick={() => handleIdleLogout()}
                />
                <Button
                  className="continue-button"
                  label="Continue"
                  icon={<CarrotRight />}
                  iconPlacement="right"
                  onClick={() => onClose(true)}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
};

export default IdlePopup;
