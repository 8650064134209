import { useEffect } from 'react';
import { WebAuthConfig } from '../../../../shared/types/types';
import { Button } from '../../../Button/Button';
import './PopupLogin.scss';
import { TAELogoIcon } from '../../../../icons/TAELogo';

export interface PopupLoginProps {
  webAuth: auth0.WebAuth;
  webAuthConfig: WebAuthConfig;
}

export function PopupLogin({
  webAuth,
  webAuthConfig,
}: PopupLoginProps) {
  const handleClickAuthenticate = () => {
    webAuth.authorize({
      clientID: `${process.env.REACT_APP_AUTH0_CLIENT_ID}`,
      redirectUri: `${window.origin}/handleapprove`,
    });
  };

  // just check the session once with this useEffect
  useEffect(() => {
    webAuth.checkSession(
      {
        audience: webAuthConfig.audience,
        scope: 'read:current_user read:token',
        redirectUri: webAuthConfig.redirectUri,
      },
      (err, result) => {
        if (result) {
          webAuth.logout({
            returnTo: webAuthConfig.returnTo,
            clientID: webAuthConfig.clientID,
          });
        } else {
        }
      }
    );
  }, [webAuth, webAuthConfig]);

  return (
    <div className="popup-login-container">
      <TAELogoIcon className="logo" />
      <div className="title">Approval Required</div>
      <div className="description">
        Please click the approve button to authorize this action
        through SSO
      </div>
      <Button
        className="primary"
        label="Approve"
        onClick={handleClickAuthenticate}
      ></Button>
    </div>
  );
}
