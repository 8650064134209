import { Button } from '../../../Button/Button';
import {
  LoadingSpinner,
  smallSpinner,
} from '../../../common/loader/LoadingSpinner';
import { Trash } from '../../../../icons/Trash';
import './NoteDeleteVerify.scss';

export interface NoteDeleteVerifyProps {
  onCancelClick: () => void;
  onOkClick: () => void;
  isDeleting: boolean;
  warningLabel: string;
}

export function NoteDeleteVerify({
  onCancelClick,
  onOkClick,
  isDeleting,
  warningLabel,
}: NoteDeleteVerifyProps) {
  const handleCancelClick = () => {
    onCancelClick();
  };

  const handleOkClick = () => {
    onOkClick();
  };

  return (
    <div className="note-delete-verify-container">
      <div className="note-delete-verify-title">{warningLabel}</div>
      <div className="note-delete-verify-controls">
        <Button
          className="ok-button"
          label={isDeleting ? '' : 'Delete'}
          onClick={handleOkClick}
          icon={
            isDeleting ? (
              <LoadingSpinner
                width={smallSpinner}
                height={smallSpinner}
                color={'white'}
              />
            ) : (
              <Trash />
            )
          }
          iconPlacement={'left'}
        />
        <Button
          className="cancel-button"
          label="Cancel"
          onClick={handleCancelClick}
        />
      </div>
    </div>
  );
}
