import { useEffect, useState } from 'react';
import './AddNote.scss';
import '../SendNoteControls.scss';
import { WysiwygEditor } from '../../WysiwygEditor';
import { Button } from '../../../../components/Button/Button';
import { convertToRaw, EditorState } from 'draft-js';
import {
  LoadingSpinner,
  smallSpinner,
} from '../../../../components/common/loader/LoadingSpinner';
import { Floppy } from '../../../../icons/Floppy';

export interface AddNoteProps {
  onSubmit: (body: string) => Promise<boolean>;
  isLoadingNewNote: boolean;
}

export function AddNote({
  onSubmit,
  isLoadingNewNote,
}: AddNoteProps) {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const [sendable, setSendable] = useState(true);

  useEffect(() => {
    setSendable(editorState.getCurrentContent().hasText());
  }, [editorState]);

  const handleSubmitClick = async () => {
    if (isLoadingNewNote) return;
    await onSubmit(
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    ).then(() => {
      setEditorState(EditorState.createEmpty());
    });
  };

  const handleCancelClick = () => {
    if (isLoadingNewNote) return;
    setEditorState(EditorState.createEmpty());
  };

  return (
    <div className="add-edit-note-container">
      <WysiwygEditor
        editorState={editorState}
        setEditorState={setEditorState}
      />
      <div className="add-edit-note-controls">
        <Button
          icon={
            isLoadingNewNote ? (
              <LoadingSpinner
                width={smallSpinner}
                height={smallSpinner}
                color={'white'}
              />
            ) : (
              <Floppy />
            )
          }
          iconPlacement="left"
          className={`send-button ${
            sendable ? 'active' : 'disabled'
          }`}
          label={isLoadingNewNote ? '' : 'Save'}
          onClick={handleSubmitClick}
          disabled={!sendable}
        />
        {sendable && (
          <Button
            className={`cancel-button`}
            label="Cancel"
            onClick={handleCancelClick}
          />
        )}
      </div>
    </div>
  );
}
