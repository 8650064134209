import { TBody } from '../../../components/common/table/TBody/TBody';
import { Table } from '../../../components/common/table/Table/Table';
import {
  AuditTrailEvent,
  SortDirection,
} from '../../../shared/types/types';
import { EventListHeader } from './listHeader/EventListHeader';
import { EventListRow } from './listRow/EventListRow';
import './EventList.scss';

export interface EventListProps {
  eventList: AuditTrailEvent[];
  dateSortDirection: SortDirection;
  activitySortDirection: SortDirection;
  applicationSortDirection: SortDirection;
  roomSortDirection: SortDirection;
  userSortDirection: SortDirection;
  loadingNewData: boolean;
  onDateSort: () => void;
  onActivitySort: () => void;
  onApplicationSort: () => void;
  onRoomSort: () => void;
  onUserSort: () => void;
  onEventClick: (eventId: string) => void;
  handleNewAuditTrailGet: () => void;
}

export function EventList({
  eventList,
  dateSortDirection,
  onDateSort,
  activitySortDirection,
  onActivitySort,
  applicationSortDirection,
  onApplicationSort,
  roomSortDirection,
  onRoomSort,
  userSortDirection,
  onUserSort,
  onEventClick,
  handleNewAuditTrailGet,
  loadingNewData,
}: EventListProps) {
  const handleScroll = (event: any) => {
    if (loadingNewData) return;
    if (event?.target?.clientHeight === 0) return;
    if (
      Math.floor(
        event?.target?.clientHeight + event?.target?.scrollTop
      ) +
        5 >=
      event?.target?.scrollHeight
    ) {
      handleNewAuditTrailGet();
    }
  };

  return (
    <div className="event-list">
      <Table>
        <EventListHeader
          dateSortDirection={dateSortDirection}
          onDateSort={onDateSort}
          activitySortDirection={activitySortDirection}
          onActivitySort={onActivitySort}
          applicationSortDirection={applicationSortDirection}
          onApplicationSort={onApplicationSort}
          roomSortDirection={roomSortDirection}
          onRoomSort={onRoomSort}
          userSortDirection={userSortDirection}
          onUserSort={onUserSort}
        />
        <TBody
          className="event-list-table-body"
          onScroll={handleScroll}
        >
          {eventList.map((listItem, index) => (
            <EventListRow
              index={index}
              id={listItem._id}
              createdDateTime={listItem.createdDateTime}
              eventDescription={listItem.eventDescription}
              userInfo={listItem.userInfo}
              application={listItem.application}
              room={listItem.room}
              onDetailsClick={onEventClick}
              key={`event-row-${index}-${listItem._id}`}
            />
          ))}
        </TBody>
      </Table>
    </div>
  );
}
