import { IconButtonProps } from '.';
import { SortOutlined } from '../../../../icons/SortOutlined';
import { SortOutlinedAscending } from '../../../../icons/SortOutlinedAscending';
import { SortOutlinedDescending } from '../../../../icons/SortOutlinedDescending';
import { SortDirection } from '../../../../shared/types/types';
import './IconButton.scss';

export interface SortButtonInterface {
  sortDirection: SortDirection;
}
export type SortButtonProps = IconButtonProps & SortButtonInterface;

export function SortButton({
  onClick,
  width,
  height,
  color,
  sortDirection,
}: SortButtonProps) {
  return (
    <div className={`icon-button`} onClick={onClick}>
      {sortDirection === 'ascending' ? (
        <SortOutlinedAscending
          width={width}
          height={height}
          color={color}
        />
      ) : sortDirection === 'descending' ? (
        <SortOutlinedDescending
          width={width}
          height={height}
          color={color}
        />
      ) : (
        <SortOutlined width={width} height={height} color={color} />
      )}
    </div>
  );
}
