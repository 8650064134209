import { ReactNode } from 'react';
import './SectionButton.scss';

export interface SectionButtonProps {
  onClick: () => void;
  icon: ReactNode | JSX.Element;
  label: string;
}

export function SectionButton({
  onClick,
  icon,
  label,
}: SectionButtonProps) {
  return (
    <div className="section-button-container" onClick={onClick}>
      <div className="icon">{icon}</div>
      <div className="label">{label}</div>
    </div>
  );
}
