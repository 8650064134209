import { Cell } from '../../../../components/common/table/Cell/Cell';
import { Row } from '../../../../components/common/table/Row/Row';
import './DomainRestrictionRow.scss';
import '../DomainRestrictionList.scss';
import { Button } from 'src/components/Button/Button';
import { Pencil } from 'src/shared/assets/pencil';
import {
  neutral300,
  primaryBlue400,
} from 'src/shared/styles/variables';
import { useContext, useState } from 'react';
import { XClose } from 'src/icons/XClose';
import { Trash } from 'src/icons/Trash';
import {
  LoadingSpinner,
  smallSpinner,
} from 'src/components/common/loader/LoadingSpinner';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import {
  decryptObject,
  encryptObject,
} from 'src/components/Encryption/obfuscationHandler';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { fetchAuthenticated } from 'src/controllers';
import { BannerMessageContext } from 'src/shared/contexts/BannerMessageContext';
import { v4 } from 'uuid';

export interface DomainRestrictionRowProps {
  domain: string;
  index: number;
  userName: string;
  createdDateTime: string;
  description: string;
  setDomainRestrctionList: (list: any) => void;
  domainRestrictionListLength: number;
}

export function DomainRestrictionRow({
  domain,
  index,
  userName,
  createdDateTime,
  description,
  setDomainRestrctionList,
  domainRestrictionListLength,
}: DomainRestrictionRowProps) {
  const [edit, setEdit] = useState(false);
  const [deleteDomainSpinner, setDeleteDomainSpinner] =
    useState<boolean>(false);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const { token } = useContext(GlobalUserContext);

  const handleShortDomainList = async () => {
    if (domainRestrictionListLength <= 1) {
      addBannerMessage({
        id: v4(),
        message:
          'Only 1 domain is available, please add a valid domain before attempting to delete this domain.',
        type: 'error',
      });
      return;
    }

    setEdit(true);
  };

  const handleDeleteDomain = async () => {
    setDeleteDomainSpinner(true);
    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/removeallowabledomains`;

    const body = JSON.stringify(
      await encryptObject(
        {
          encrypt_removeDomainList: [domain],
        },
        token,
        null
      )
    );

    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      setDomainRestrctionList(decryptData?.updatedDomainList);
      setDeleteDomainSpinner(false);
    }
  };

  return (
    <Row index={index} classes="row-container">
      <Cell>{domain}</Cell>
      <Cell capitalized>{userName}</Cell>
      <Cell capitalized>{createdDateTime}</Cell>
      <Cell capitalized>{description}</Cell>
      <Cell capitalized>
        {!edit ? (
          <Button
            icon={<Pencil fill={primaryBlue400} />}
            onClick={() => {
              handleShortDomainList();
            }}
            width={'40px'}
            height={'40px'}
            className={'square-icon-button edit-button-enabled'}
          ></Button>
        ) : deleteDomainSpinner ? (
          <LoadingSpinner
            width={smallSpinner}
            height={smallSpinner}
          />
        ) : (
          <div className="domain-restriction-action-buttons">
            <div className="cancel-button-container">
              <Button
                icon={<XClose color={primaryBlue400} />}
                onClick={() => {
                  setEdit(false);
                }}
                width={'40px'}
                height={'40px'}
                className={'square-icon-button cancel-button'}
              ></Button>
            </div>

            <Button
              className="trash-button"
              icon={<Trash color={neutral300} />}
              onClick={() => {
                handleDeleteDomain();
              }}
              width={'40px'}
              height={'40px'}
            ></Button>
          </div>
        )}
      </Cell>
    </Row>
  );
}
