import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function SpinnerIcon({
  width = 102,
  height = 102,
  color = 'currentColor',
  className,
  ...rest
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 102 102"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      {...rest}
    >
      <path
        d="M54.8449 25.3511C71.6873 29.8855 75.2054 45.2425 74.8583 52.4144C74.8583 52.4144 74.77 53.4683 75.6095 52.0986C76.449 50.7289 81.5539 39.0665 76.7687 26.2771C74.7387 22.449 67.6185 6.341 48.6094 4.90169C31.6866 3.62035 21.6787 12.7205 17.1717 17.3158C12.6648 21.911 11.5164 28.8582 14.0106 31.3812C16.5048 33.9043 19.4914 32.4289 24.3065 29.7467C29.1215 27.0646 38.0025 20.8166 54.8449 25.3511Z"
        fill={color}
      />
      <path
        d="M72.3349 67.8097C59.9867 80.1284 44.9281 75.4966 38.8906 71.6101C38.8906 71.6101 38.022 71.0066 38.7885 72.4185C39.555 73.8304 47.1024 84.0826 60.571 86.3332C64.9013 86.4892 82.4113 88.3769 93.1623 72.6342C102.733 58.6193 99.8563 45.4021 98.1302 39.2013C96.4041 33.0006 90.9619 28.5325 87.5297 29.431C84.0976 30.3295 83.8821 33.6537 83.7973 39.1647C83.7126 44.6758 84.683 55.4909 72.3349 67.8097Z"
        fill={color}
      />
      <path
        d="M26.7921 61.2312C22.2978 44.378 33.8384 33.6528 40.2229 30.3674C40.2229 30.3674 41.1798 29.917 39.5738 29.8748C37.9679 29.8326 25.3155 31.2428 16.6321 41.7816C14.3319 45.4538 3.94208 59.674 12.2002 76.856C19.5519 92.1523 32.4368 96.2693 38.6699 97.8748C44.903 99.4803 51.4935 97.0012 52.4315 93.5797C53.3695 90.1581 50.5984 88.3094 45.8681 85.4805C41.1377 82.6516 31.2863 78.0844 26.7921 61.2312Z"
        fill={color}
      />
    </svg>
  );
}
