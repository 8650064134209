import React, { useContext, useEffect, useState } from 'react';
import { CarrotLeft } from '../../shared/assets/carrot-left';
import { TAELogo } from '../../shared/assets/tae-logo-white';
import { Button } from '../Button/Button';
import { Records } from '../../icons/Records';
import { white } from '../../shared/styles/variables';
import { Section } from '../../shared/types/types';
import { CommissioningIcon } from '../../icons/CommissioningIcon';
import { MachineSpecIcon } from '../../icons/MachineSpecs';
import { HomeIcon } from '../../icons/Home';
import { SyringeIcon } from '../../icons/SyringeIcon';
import { ServiceIcon } from '../../icons/ServiceIcon';
import { PencilIcon } from '../../icons/Pencil';
import { useAuth0 } from '@auth0/auth0-react';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import './Sidebar.scss';
import { Logout } from '../../shared/assets/logout';
export interface SidebarProps {
  onSidebarSectionClick: (sectionName: Section) => void;
  onLogout: () => void;
}
export interface loggedInUser {
  firstName?: string;
  lastName?: string;
}

const Sidebar = ({
  onSidebarSectionClick,
  onLogout,
}: SidebarProps) => {
  const [sidebarExpanded, setSidebarExpanded] = useState(false);
  const auth = useAuth0();
  const { userInfo } = useContext(GlobalUserContext);

  useEffect(() => {
    if (!auth.isAuthenticated) setSidebarExpanded(false);
  }, [auth, auth.user, setSidebarExpanded]);

  const toggleSidebar = (): void => {
    setSidebarExpanded(!sidebarExpanded);
  };

  const handleRecordsButtonClick = () => {
    onSidebarSectionClick('records');
  };

  const handleInfusionsButtonClick = () => {
    onSidebarSectionClick('infusion');
  };

  const handleCommissioningButtonClick = () => {
    onSidebarSectionClick('commissioning');
  };

  const handleMachineSpecButtonClick = () => {
    onSidebarSectionClick('machine-spec');
  };

  const handleHomeClick = () => {
    onSidebarSectionClick('dashboard');
  };

  const handleServiceClick = () => {
    onSidebarSectionClick('service');
  };

  const handleAuditTrailClick = () => {
    onSidebarSectionClick('audit-trail');
  };

  const isAdmin = !userInfo
    ? false
    : (userInfo as string[]).includes('Admin');

  const isNurse = !userInfo
    ? false
    : (userInfo as string[]).includes('Nurse');

  const isPhysician = !userInfo
    ? false
    : (userInfo as string[]).includes('Physician');

  const isService = !userInfo
    ? false
    : (userInfo as string[]).includes('Service');

  const buttonsExpanded = userInfo ? (
    <>
      <Button
        label="Home"
        className="sidebar-button"
        icon={<HomeIcon color={white} />}
        iconPlacement="left"
        onClick={handleHomeClick}
        data-testid="home-button"
      />

      {(isAdmin || isNurse || isPhysician) && (
        <Button
          label="Records"
          className="sidebar-button"
          icon={<Records color={white} />}
          iconPlacement="left"
          onClick={handleRecordsButtonClick}
          data-testid="records-button"
        />
      )}
      {(isAdmin || isNurse || isPhysician) && (
        <Button
          label="Infusion"
          className="sidebar-button"
          icon={<SyringeIcon color={white} />}
          iconPlacement="left"
          onClick={handleInfusionsButtonClick}
          data-testid="infusion-button"
        />
      )}
      {(isAdmin || isPhysician) && (
        <Button
          label="Commissioning"
          className="sidebar-button"
          icon={<CommissioningIcon color={white} />}
          iconPlacement="left"
          onClick={handleCommissioningButtonClick}
          data-testid="commissioning-button"
        />
      )}
      {(isAdmin || isPhysician || isService) && (
        <Button
          label="Machine Spec"
          className="sidebar-button"
          icon={<MachineSpecIcon color={white} />}
          iconPlacement="left"
          onClick={handleMachineSpecButtonClick}
          data-testid="machine-spec-button"
        />
      )}
      {(isAdmin || isService) && (
        <Button
          label="Audit Trail"
          className="sidebar-button"
          icon={<PencilIcon color={white} />}
          iconPlacement="left"
          onClick={handleAuditTrailClick}
          data-testid="audit-trail-button"
        />
      )}
      {(isAdmin || isService) && (
        <Button
          label="Service"
          className="sidebar-button"
          icon={<ServiceIcon color={white} />}
          iconPlacement="left"
          onClick={handleServiceClick}
          data-testid="service-button"
        />
      )}
    </>
  ) : (
    <></>
  );

  const buttonsMinimized = userInfo ? (
    <>
      <Button
        label=""
        className="sidebar-button"
        icon={<HomeIcon color={white} />}
        onClick={handleHomeClick}
        iconPlacement="left"
        title={'Home'}
        data-testid="records-button"
      />
      {(isAdmin || isNurse || isPhysician) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<Records color={white} />}
          onClick={handleRecordsButtonClick}
          iconPlacement="left"
          title={'Records'}
          data-testid="records-button"
        />
      )}
      {(isAdmin || isNurse || isPhysician) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<SyringeIcon color={white} />}
          onClick={handleInfusionsButtonClick}
          iconPlacement="left"
          title={'Infusion'}
          data-testid="infusion-button"
        />
      )}
      {(isAdmin || isPhysician) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<CommissioningIcon color={white} />}
          iconPlacement="left"
          title={'Commissioning'}
          onClick={handleCommissioningButtonClick}
          data-testid="commissioning-button"
        />
      )}
      {(isAdmin || isPhysician || isService) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<MachineSpecIcon color={white} />}
          iconPlacement="left"
          title={'Machine Spec'}
          onClick={handleMachineSpecButtonClick}
          data-testid="machine-spec-button"
        />
      )}
      {(isAdmin || isService) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<PencilIcon color={white} />}
          iconPlacement="left"
          title={'Audit Trail'}
          onClick={handleAuditTrailClick}
          data-testid="audit-trail-button"
        />
      )}
      {(isAdmin || isService) && (
        <Button
          label=""
          className="sidebar-button"
          icon={<ServiceIcon color={white} />}
          iconPlacement="left"
          title={'Service'}
          onClick={handleServiceClick}
          data-testid="service-button"
        />
      )}
    </>
  ) : (
    <></>
  );

  const minimizeButton = (
    <span onClick={() => toggleSidebar()} className="carrot">
      <CarrotLeft />
    </span>
  );

  const userName =
    auth.user && auth.user.name
      ? sidebarExpanded
        ? auth.user.name
        : auth.user.name.split(' ').map((word) => word[0][0])
      : '';
  const globalUserState = useContext(GlobalUserContext);

  return (
    <div
      data-testid="sidebar-container"
      className={`${
        sidebarExpanded
          ? 'sidebar-container'
          : 'sidebar-container-collapsed'
      }`}
      data-cy="sidebar"
    >
      <div className="sidebar-content">
        <div className="sidebar-header">
          <span className="tae-logo">
            <TAELogo />
            {auth.isAuthenticated && minimizeButton}
          </span>

          {auth.isAuthenticated &&
            (sidebarExpanded ? buttonsExpanded : buttonsMinimized)}
        </div>

        <div className="sidebar-footer">
          <div className="logged-in-user">{userName}</div>
          {globalUserState.isLoggedIn && (
            <Button
              label={sidebarExpanded ? 'Logout' : ''}
              className={`sidebar-button ${
                !sidebarExpanded ? 'minimized' : ''
              }`}
              icon={<Logout fill="#fff" />}
              iconPlacement="left"
              onClick={onLogout}
              title={'Log Out'}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
