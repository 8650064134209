import { useState } from 'react';
import { Button } from '../../../components/Button/Button';
import { DownloadIcon } from '../../../icons/Download';
import { validateEmail } from '../../../shared/util/validations';
import './BreakGlassLogin.scss';

export interface BreakGlassLoginProps {
  onBreakGlassLoginClick: (email: string) => void;
  onCancelClick: () => void;
}

export function BreakGlassLogin({
  onBreakGlassLoginClick,
  onCancelClick,
}: BreakGlassLoginProps) {
  const handleLoginClick = () => {
    onBreakGlassLoginClick(email);
  };
  const [email, setEmail] = useState('');
  const [validationError, setValidationError] = useState(false);
  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!validateEmail(e.currentTarget.value)) {
      setValidationError(true);
    } else {
      setValidationError(false);
    }

    setEmail(e.currentTarget.value);
  };

  return (
    <div className="break-glass-login-container">
      <div className="title">Break Glass Login</div>
      <div className="sub-header">
        This workflow is intended for emergency use only. Supervising
        administrators will be notified immediately. All actions are
        audited.
      </div>
      <input
        className="email-input"
        placeholder={'Please enter your email'}
        value={email}
        onChange={(e) => onChangeEmail(e)}
      />

      <div className="validation-error">
        {validationError ? 'Invalid email! Please try again.' : ' '}
      </div>

      <div className="controls">
        <Button
          className="login-button primary"
          label="log in"
          onClick={handleLoginClick}
          icon={<DownloadIcon />}
          iconPlacement="left"
        />
        <Button
          label="cancel"
          className="cancel-button secondary"
          onClick={onCancelClick}
        />
      </div>
    </div>
  );
}
