import { MouseEvent, useState } from 'react';
import { ExclamationOutlined } from '../../icons/ExclamationOutlined';
import './ResetFiltersButton.scss';

export interface ResetFiltersButtonProps {
  onClick?: () => void;
  disabled?: boolean;
}

export function ResetFiltersButton({
  onClick,
  disabled = false,
}: ResetFiltersButtonProps) {
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = (event: MouseEvent<HTMLDivElement>) => {
    setHovering(true);
  };
  const handleMouseLeave = (event: MouseEvent<HTMLDivElement>) => {
    setHovering(false);
  };

  const classes = `reset-filters-button-container ${
    disabled ? 'disabled' : hovering ? 'hover' : ''
  }`;

  return (
    <div
      className={`${classes}`}
      onClick={disabled ? undefined : onClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <ExclamationOutlined />
      <div>reset filters</div>
    </div>
  );
}
