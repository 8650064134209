import { IconTypeProps } from '../shared/types/types';

export function CarrotRight({
  width = 8,
  height = 15,
  color = 'currentColor',
  ...props
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 14"
      fill="none"
      {...props}
    >
      <path
        d="M6.40005 4.88L1.81005 0.289999C1.62268 0.103748 1.36923 -0.000793457 1.10505 -0.000793457C0.840861 -0.000793457 0.587409 0.103748 0.400046 0.289999C0.306318 0.382962 0.231924 0.493563 0.181155 0.615423C0.130387 0.737282 0.104248 0.867988 0.104248 1C0.104248 1.13201 0.130387 1.26272 0.181155 1.38458C0.231924 1.50644 0.306318 1.61704 0.400046 1.71L5.00005 6.29C5.09378 6.38297 5.16817 6.49357 5.21894 6.61543C5.26971 6.73729 5.29585 6.86799 5.29585 7C5.29585 7.13202 5.26971 7.26272 5.21894 7.38458C5.16817 7.50644 5.09378 7.61704 5.00005 7.71L0.400046 12.29C0.211743 12.477 0.105428 12.7311 0.10449 12.9965C0.103553 13.2618 0.20807 13.5167 0.395047 13.705C0.582025 13.8933 0.836147 13.9996 1.10151 14.0006C1.36687 14.0015 1.62174 13.897 1.81005 13.71L6.40005 9.12C6.96185 8.5575 7.27741 7.79501 7.27741 7C7.27741 6.205 6.96185 5.4425 6.40005 4.88Z"
        fill={color}
      />
    </svg>
  );
}
