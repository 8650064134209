import clsx from 'clsx';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from '../../components/Button/Button';
import { Card } from '../../components/Card/Card';
import { AngleLeft } from '../../icons/AngleLeft';
import './Service.scss';
import {
  LoadingSpinner,
  largeSpinner,
} from 'src/components/common/loader/LoadingSpinner';
import { DomainRestrictionList } from './domainRestriction/DomainRestrictionList';
import { AdminEmailList } from './adminEmail/AdminEmailList';
import { GlobalUserContext } from 'src/shared/contexts/GlobalUserContext';
import { fetchAuthenticated } from 'src/controllers';
import { CAS_BACK_END_API_URL, URL_VERSION } from 'src/constants';
import {
  decryptObject,
  encryptObject,
} from 'src/components/Encryption/obfuscationHandler';
import { TextInput } from 'src/components/TextInput/TextInput';
import { Plus } from 'src/shared/assets/plus';
import { BannerMessageContext } from 'src/shared/contexts/BannerMessageContext';
import { v4 } from 'uuid';
import { Check } from 'src/icons/Check';
import { Key } from 'src/shared/assets/key';
import { offWhite, silver } from 'src/shared/styles/variables';

export interface ServiceProps {}

export function Service(props: ServiceProps) {
  const [isAdminEmailListExpanded, setAdminEmailListIsExpanded] =
    useState(true);
  const handleAdminEmailListExpanded = () => {
    setAdminEmailListIsExpanded(!isAdminEmailListExpanded);
  };
  const [isExpanded, setIsExpanded] = useState(true);
  const [adminListCurrentVersion, setAdminListCurrentVersion] =
    useState(null);
  const [
    isDomainRestrictionExpanded,
    setDomainRestrictionIsExpanded,
  ] = useState(true);

  const [isAccessKeysExpanded, setAccessKeysIsExpanded] =
    useState(true);

  const [addDomainEnabled, setAddDomainEnabled] = useState(true);
  const [addAdminEnabled, setAddAdminEnabled] = useState(true);
  const [domain, setDomain] = useState<string>('');
  const [adminEmail, setAdminEmail] = useState<string>('');
  const [checkingCurrentDomain, setCheckingCurrentDomain] =
    useState<boolean>(false);
  const [sendingFireCallEmail, setSendingFireCallEmail] =
    useState<boolean>(false);
  const [domainDescription, setDomainDescription] =
    useState<string>('');
  const [adminName, setAdminName] = useState<string>('');
  const { token } = useContext(GlobalUserContext);
  const { addBannerMessage } = useContext(BannerMessageContext);

  const handleExpanded = () => {
    setIsExpanded(!isExpanded);
  };
  const handleDomainRestrictionExpanded = () => {
    setDomainRestrictionIsExpanded(!isDomainRestrictionExpanded);
  };
  const handleAccessKeysExpanded = () => {
    setAccessKeysIsExpanded(!isAccessKeysExpanded);
  };
  const [domainRestrctionList, setDomainRestrctionList] =
    useState<any>([]);
  const [domainRestrctionListError, setDomainRestrctionListError] =
    useState<boolean>(false);
  const [
    domainRestrctionListIsLoading,
    setDomainRestrctionListIsLoading,
  ] = useState<boolean>(false);

  const [adminEmailList, setAdminEmailList] = useState<any>([]);
  const [adminEmailListError, setAdminEmailListError] =
    useState<boolean>(false);
  const [adminEmailListIsLoading, setAdminEmailListIsLoading] =
    useState<boolean>(false);

  const navigate = useNavigate();

  const getDomainRestrctionList = useCallback(async () => {
    if (domainRestrctionList.length >= 1) return;
    if (domainRestrctionListError) return;
    setDomainRestrctionListIsLoading(true);
    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/domainrestriction`;

    const config: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await fetchAuthenticated<any>(url, config);

      if (response?.ok) {
        if (!response?.parsedBody?.result) return;
        await decryptObject(
          response?.parsedBody?.result,
          token,
          null
        );
        setDomainRestrctionList(
          response?.parsedBody?.result?.domains
        );
        setDomainRestrctionListIsLoading(false);
      }
    } catch (error) {
      setDomainRestrctionListError(true);
      setDomainRestrctionList([]);
      setDomainRestrctionListIsLoading(false);
      addBannerMessage({
        id: v4(),
        message:
          'Uh oh! An error occurred downloading the Domain Restriction List!',
        type: 'error',
      });
    }
  }, [
    token,
    domainRestrctionList,
    domainRestrctionListError,
    setDomainRestrctionListIsLoading,
    addBannerMessage,
  ]);

  const getAdminEmailList = useCallback(async () => {
    // This is set to the domainRestrictionList once the page loads intentionally.
    // The admin list could be 0 and can't be used as a reference for page load
    if (adminEmailList.length >= 1) return;
    if (adminEmailListError) return;
    setAdminEmailListIsLoading(true);

    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/getall_firecall_emails`;

    const config: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    try {
      const response = await fetchAuthenticated<any>(url, config);

      if (response?.ok) {
        if (!response?.parsedBody?.result) return;
        await decryptObject(
          response?.parsedBody?.result,
          token,
          null
        );
        setAdminListCurrentVersion(
          response.parsedBody.result?.items?._version
        );
        setAdminEmailList(
          response.parsedBody.result?.items?.adminList
        );
        setAdminEmailListIsLoading(false);
      }
    } catch (error) {
      setAdminEmailListError(true);
      setAdminListCurrentVersion(null);
      setAdminEmailList([]);
      setAdminEmailListIsLoading(false);
      addBannerMessage({
        id: v4(),
        message:
          'Uh oh! An error occurred downloading the Administrator Email List!',
        type: 'error',
      });
    }
  }, [
    token,
    adminEmailList,
    adminEmailListError,
    setAdminEmailListIsLoading,
    addBannerMessage,
    setAdminEmailListError,
  ]);

  useEffect(() => {
    if (domainRestrctionListIsLoading) return;
    if (domainRestrctionListError) return;
    try {
      getDomainRestrctionList();
    } catch (error: any) {
      setDomainRestrctionListError(true);
    }
  }, [
    domainRestrctionListIsLoading,
    getDomainRestrctionList,
    domainRestrctionListError,
  ]);

  useEffect(() => {
    if (adminEmailListIsLoading) return;
    if (adminEmailListError) return;
    try {
      getAdminEmailList();
    } catch (error: any) {
      setAdminEmailListError(true);
    }
  }, [
    adminEmailListIsLoading,
    getAdminEmailList,
    adminEmailListError,
    setAdminEmailListError,
  ]);

  const addNewDomain = async () => {
    if (domain.length === 0 || domainDescription.length === 0) {
      addBannerMessage({
        id: v4(),
        message:
          'Please input a Domain and Description inorder to make an addition to the Domain Restriction List.',
        type: 'error',
      });
      return;
    }

    setAddDomainEnabled(false);

    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/addallowabledomains`;

    const body = JSON.stringify(
      await encryptObject(
        {
          encrypt_addDomainList: [domain],
          description: domainDescription,
        },
        token,
        null
      )
    );

    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      setDomainRestrctionList(decryptData?.domains);
      setDomain('');
      setDomainDescription('');
    }

    setAddDomainEnabled(true);
  };

  const downloadFile = (data: string, fileName: string) => {
    const blob = new Blob([data], { type: 'application/env' });
    const a = document.createElement('a');
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
  };

  const downloads3OrthancCredentials = async () => {
    const url = CAS_BACK_END_API_URL + '/servicepage/accesskeys';
    const config: RequestInit = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      const {
        AccessKeyId,
        CreateDate,
        SecretAccessKey,
        s3BucketName,
      } = decryptData?.data;
      const dataString = `AccessKeyId=${AccessKeyId}\nCreateDate=${CreateDate}\nSecretAccessKey=${SecretAccessKey}\ns3BucketName=${s3BucketName}`;
      downloadFile(dataString, `.env`);
    }
  };

  const addNewAdminEmail = async () => {
    if (adminEmail.length === 0 || adminName.length === 0) {
      addBannerMessage({
        id: v4(),
        message:
          'Please input an Administrator Name & Email inorder to make an addition to the Administrator Fire Call List.',
        type: 'error',
      });
      return;
    }

    setAddAdminEnabled(false);

    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/add_firecall_admin`;

    const adminInfo = adminListCurrentVersion
      ? {
          adminEmail: adminEmail,
          adminName: adminName,
          _version: adminListCurrentVersion,
        }
      : { adminEmail: adminEmail, adminName: adminName };

    const body = JSON.stringify(
      await encryptObject(adminInfo, token, null)
    );

    const config: RequestInit = {
      method: 'PUT',
      body: body,
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );
      setAdminEmailList(decryptData?.fireCallAdminList?.adminList);
      setAdminListCurrentVersion(
        decryptData?.fireCallAdminList?._version
      );
      setAdminName('');
      setAdminEmail('');
    } else {
      addBannerMessage({
        id: v4(),
        message:
          'Error in making an addition to the Administrator Fire Call List.',
        type: 'error',
      });
    }
    setAddAdminEnabled(true);
  };

  const checkCurrentDomain = async () => {
    setCheckingCurrentDomain(true);
    const url = CAS_BACK_END_API_URL + `${URL_VERSION}/currentdomain`;
    const config: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      const decryptData = await decryptObject(
        response.parsedBody?.result,
        token,
        null
      );

      addBannerMessage({
        id: v4(),
        message: `The Current Domain is "${decryptData?.encrypt_domainName}".`,
        type: 'info',
      });
    }
    setCheckingCurrentDomain(false);
  };

  const sendFireCallEmail = async () => {
    setSendingFireCallEmail(true);
    const url =
      CAS_BACK_END_API_URL + `${URL_VERSION}/firecall_email`;
    const config: RequestInit = {
      method: 'GET',
      headers: { Authorization: `Bearer ${token}` },
    };
    const response = await fetchAuthenticated<any>(url, config);

    if (response?.ok && response?.parsedBody) {
      addBannerMessage({
        id: v4(),
        message:
          'A Fire Call e-mail was sent to the registered Administrators .',
        type: 'info',
      });
    }
    setSendingFireCallEmail(false);
  };

  const listError = (
    <div className="error">
      There was an error loading the Domain Restriction List. Please
      refresh to try again.
    </div>
  );

  const accessKeysBody = (
    <div className="access-keys">
      <div className="header-info">
        Click on buttons to download access keys for setting up on
        systems
      </div>
      <div className="access-keys-button-container">
        <Button
          label="Orthanc S3 Bucket"
          icon={<Key fill={offWhite} />}
          iconPlacement="left"
          width="192px"
          onClick={() => downloads3OrthancCredentials()}
          className={clsx(
            'access-keys-button',
            'access-keys-button-enabled'
          )}
        />
        <Button
          label="AWS CI/CD Credentials"
          icon={<Key fill={offWhite} />}
          iconPlacement="left"
          width="192px"
          onClick={() => downloads3OrthancCredentials()}
          className={clsx(
            'access-keys-button',
            'access-keys-button-enabled'
          )}
        />
        <Button
          label="ElasticSearch Credentials"
          icon={<Key fill={offWhite} />}
          iconPlacement="left"
          width="192px"
          onClick={() => downloads3OrthancCredentials()}
          className={clsx(
            'access-keys-button',
            'access-keys-button-enabled'
          )}
        />
      </div>
    </div>
  );

  const domainRestrictionBody = (
    <div className="domain-restriction-content-container">
      <div className="domain-resitrction-list-container">
        <div className="add-domain">
          <div className="domain-restriction-input-container">
            <TextInput
              value={domain}
              placeHolder={'Input Valid Domain Name Here'}
              width={286}
              onChange={(e) => setDomain(e.target.value)}
              disabled={false}
            />
          </div>
          <div className="domain-restriction-input-container">
            <TextInput
              value={domainDescription}
              placeHolder={'Input Domain Description Here'}
              width={586}
              onChange={(e) => setDomainDescription(e.target.value)}
              disabled={false}
            />
          </div>
          <div className="add-button-container">
            <Button
              label="Add Valid Domain"
              icon={<Plus fill={!true ? silver : offWhite} />}
              iconPlacement="left"
              width="192px"
              onClick={() => addNewDomain()}
              className={clsx(
                'add-button',
                addDomainEnabled && 'add-button-enabled'
              )}
              disabled={!addDomainEnabled}
            />
          </div>
        </div>
        {domainRestrctionListError ? (
          listError
        ) : domainRestrctionListIsLoading ? (
          <div className="loading-container">
            <LoadingSpinner
              width={largeSpinner}
              height={largeSpinner}
            />
          </div>
        ) : (
          <DomainRestrictionList
            domainRestrictionList={domainRestrctionList}
            setDomainRestrctionList={setDomainRestrctionList}
            domainRestrictionListLength={domainRestrctionList.length}
          />
        )}
        <div className="check-domain-button-container">
          <Button
            label="Check Current Domain"
            icon={<Check />}
            iconPlacement="left"
            width="252px"
            onClick={() => checkCurrentDomain()}
            className={clsx(
              'check-domain-button',
              !checkingCurrentDomain && 'check-domain-button-enabled'
            )}
            disabled={checkingCurrentDomain}
          />
        </div>
      </div>
    </div>
  );

  const adminEmailListBody = (
    <div className="domain-restriction-content-container">
      <div className="domain-resitrction-list-container">
        <div className="add-domain">
          <div className="domain-restriction-input-container">
            <TextInput
              value={adminName}
              placeHolder={'Administrator Name'}
              width={286}
              onChange={(e) => setAdminName(e.target.value)}
              disabled={false}
            />
          </div>
          <div className="domain-restriction-input-container">
            <TextInput
              value={adminEmail}
              placeHolder={'Administrator Email'}
              width={586}
              onChange={(e) => setAdminEmail(e.target.value)}
              disabled={false}
            />
          </div>
          <div className="add-button-container">
            <Button
              label="Add Administrator to Fire Call List"
              icon={<Plus fill={!true ? '#C5C5C5' : offWhite} />}
              iconPlacement="left"
              width="300px"
              onClick={() => addNewAdminEmail()}
              className={clsx(
                'add-button',
                addAdminEnabled && 'add-button-enabled'
              )}
              disabled={!addAdminEnabled}
            />
          </div>
        </div>
        {adminEmailListError ? (
          listError
        ) : adminEmailListIsLoading ? (
          <div className="loading-container">
            <LoadingSpinner
              width={largeSpinner}
              height={largeSpinner}
            />
          </div>
        ) : (
          <AdminEmailList
            adminEmailList={adminEmailList}
            setAdminEmailList={setAdminEmailList}
            adminListCurrentVersion={adminListCurrentVersion}
          />
        )}
        <div className="check-domain-button-container">
          <Button
            label="Send Fire Call Email to Admin List"
            icon={<Check />}
            iconPlacement="left"
            width="325px"
            onClick={() => sendFireCallEmail()}
            className={clsx(
              'check-domain-button',
              !sendingFireCallEmail && 'check-domain-button-enabled'
            )}
            disabled={sendingFireCallEmail}
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className="service-container" data-testid="service">
      <Card
        title={'Service'}
        body={
          isExpanded ? <div>This is the Service Content</div> : <></>
        }
        onExpand={handleExpanded}
        expandable={true}
        expanded={isExpanded}
      />

      <Card
        title={'Access and Secret Keys'}
        body={isDomainRestrictionExpanded ? accessKeysBody : <></>}
        onExpand={handleAccessKeysExpanded}
        expandable={true}
        expanded={isAccessKeysExpanded}
      />

      <Card
        title={'Domain Restriction List'}
        body={
          isDomainRestrictionExpanded ? domainRestrictionBody : <></>
        }
        onExpand={handleDomainRestrictionExpanded}
        expandable={true}
        expanded={isDomainRestrictionExpanded}
      />

      <Card
        title={'Administrator Email List'}
        body={isAdminEmailListExpanded ? adminEmailListBody : <></>}
        onExpand={handleAdminEmailListExpanded}
        expandable={true}
        expanded={isAdminEmailListExpanded}
      />
      <div className="page-controls">
        <Button
          label={'Back'}
          className={'go-back-button'}
          onClick={() => {
            navigate('/');
          }}
          icon={<AngleLeft />}
          iconPlacement={'left'}
        />
      </div>
    </div>
  );
}
