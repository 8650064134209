import { Button } from '../../../components/Button/Button';
import { Edit } from '../../../icons/Edit';
import { useEffect, useState } from 'react';
import {
  ContentState,
  convertFromHTML,
  convertFromRaw,
  convertToRaw,
  EditorState,
} from 'draft-js';
import { NoteDeleteVerify } from './NoteDeleteVerify/NoteDeleteVerify';
import { NoteDTO } from '.';
import './Note.scss';
import {
  convertDate,
  convertTime,
} from '../../common/convertDateTime/convertDateTime';
import { WysiwygEditor } from '../../../components/WysiwygEditor/WysiwygEditor';
import {
  LoadingSpinner,
  smallSpinner,
} from '../../../components/common/loader/LoadingSpinner';
import { Trash } from '../../../icons/Trash';
import { Floppy } from '../../../icons/Floppy';

export interface NoteProps {
  data: NoteDTO;
  isLoadingEditNote: boolean;

  onNoteEdit?: (
    noteId: string,
    version: number,
    noteBody: string
  ) => Promise<boolean>;

  onNoteDelete?: (
    noteId: string,
    version: number
  ) => Promise<boolean>;
}

export function Note({
  data,
  isLoadingEditNote,
  onNoteEdit,
  onNoteDelete,
}: NoteProps) {
  const [editorState, setEditorState] = useState<EditorState>(() =>
    EditorState.createEmpty()
  );
  const {
    key,
    _version,
    author,
    createdDateTime,
    editedDateTime,
    encrypt_note,
  } = data;
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [prevEditorState, setPrevEditorState] = useState<EditorState>(
    EditorState.createEmpty()
  );
  const [deleteVerify, setDeleteVerify] = useState(false);

  const sendable = editorState.getCurrentContent().hasText();
  const handleEditClick = () => {
    if (!isEditing) {
      setPrevEditorState(editorState);
    }
    setIsEditing(!isEditing);
  };

  const handleSubmitClick = async () => {
    if (!onNoteEdit || isLoadingEditNote) return;
    await onNoteEdit(
      key,
      _version,
      JSON.stringify(convertToRaw(editorState.getCurrentContent()))
    );

    setIsEditing(false);
  };

  const handleCancelClick = () => {
    if (isLoadingEditNote) return;
    setEditorState(prevEditorState);
    setIsEditing(false);
  };

  const handleDeleteClick = () => {
    setDeleteVerify(true);
  };

  const handleDeleteVerifyCancel = () => {
    if (!isDeleting) setDeleteVerify(false);
  };

  const handleDeleteVerifyOk = async () => {
    if (!onNoteDelete || isDeleting) return;
    setIsDeleting(true);

    await onNoteDelete(key, _version).then(() => {
      setDeleteVerify(false);
      setIsDeleting(false);
    });
  };

  useEffect(() => {
    try {
      // Attempt to parse this as draft-js raw content.
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(encrypt_note))
        )
      );
    } catch (e) {
      // if an error occurs in parsing, this is probably just text and will cause draft-js to crash, so parse it as html
      const blocksFromHTML = convertFromHTML(encrypt_note);
      const content = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorState(EditorState.createWithContent(content));
    }
  }, [encrypt_note]);

  const EditorControls = (
    <div className="edit-controls">
      <Button
        icon={
          isLoadingEditNote ? (
            <LoadingSpinner
              width={smallSpinner}
              height={smallSpinner}
              color={'white'}
            />
          ) : (
            <Floppy />
          )
        }
        iconPlacement="left"
        className={`send-button ${sendable ? 'active' : 'disabled'}`}
        label={!isLoadingEditNote ? 'Save' : ''}
        onClick={handleSubmitClick}
      />
      <Button
        className={`cancel-button`}
        label="Cancel"
        onClick={handleCancelClick}
      />
    </div>
  );

  const date = convertDate(createdDateTime);
  const time = convertTime(createdDateTime);
  return (
    <div
      key={key}
      className={`notes-item ${isEditing ? 'highlighted' : ''}`}
    >
      <div className="note-header">
        <div className="note-header-left">
          <div className="note-author">{author}</div>
          <div className="note-date">{date}</div>
          <div className="note-time">{time}</div>
          <div
            className={
              editedDateTime ? 'note-edited' : 'note-not-edited'
            }
          >
            (edited)
            <span className="note-edited-tooltip">
              {editedDateTime}
            </span>
          </div>
        </div>
        <div>
          {!isEditing && (
            <Button
              className={`control-button ${
                isEditing ? 'highlighted' : ''
              }`}
              icon={<Edit />}
              onClick={handleEditClick}
              width={'40px'}
              height={'40px'}
            />
          )}
          {isEditing && (
            <Button
              className={`control-button ${
                isEditing ? 'highlighted' : ''
              }`}
              icon={<Trash />}
              onClick={handleDeleteClick}
              width={'40px'}
              height={'40px'}
            />
          )}
        </div>
      </div>
      <div className="note-body">
        <WysiwygEditor
          readOnly={!isEditing}
          editorState={editorState}
          setEditorState={setEditorState}
        />
        {isEditing && EditorControls}
        {/* {body} */}
      </div>
      {deleteVerify && (
        <NoteDeleteVerify
          isDeleting={isDeleting}
          onOkClick={handleDeleteVerifyOk}
          onCancelClick={handleDeleteVerifyCancel}
          warningLabel={'Delete this note?'}
        />
      )}
    </div>
  );
}
