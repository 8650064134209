import { IconTypeProps } from '../shared/types/types';
import '../shared/styles/variables.scss';

export function BreakGlassIcon({
  width = 38,
  height = 24,
  color = 'currentColor',
}: IconTypeProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 38 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4788 1.54375H15.8836C15.4694 1.54375 15.1336 1.87953 15.1336 2.29375V21.8889C15.1336 22.3031 15.4694 22.6389 15.8836 22.6389H35.4788C35.893 22.6389 36.2288 22.3031 36.2288 21.8889V2.29375C36.2288 1.87954 35.893 1.54375 35.4788 1.54375ZM15.8836 0.293747C14.7791 0.293747 13.8836 1.18918 13.8836 2.29375V21.8889C13.8836 22.9934 14.7791 23.8889 15.8836 23.8889H35.4788C36.5833 23.8889 37.4788 22.9934 37.4788 21.8889V2.29375C37.4788 1.18918 36.5833 0.293747 35.4788 0.293747H15.8836Z"
        fill={color}
      />
      <path
        d="M32.3926 4.84175L28.4381 7.64458C28.3329 7.7191 28.1858 7.67619 28.1372 7.55683L26.7618 4.17917C26.6931 4.01059 26.4533 4.0139 26.3893 4.18431L25.0979 7.62447C25.0561 7.736 24.9266 7.78643 24.8203 7.7326L22.65 6.63325C22.5078 6.56126 22.3427 6.67626 22.3609 6.83454L22.6864 9.66217C22.6997 9.77772 22.6119 9.88021 22.4957 9.88488L16.6419 10.1199C16.4447 10.1278 16.3769 10.3864 16.5449 10.49L20.5744 12.9745C20.6597 13.027 20.6929 13.1346 20.6521 13.2261L18.0406 19.0828C17.9633 19.2562 18.1485 19.4297 18.3165 19.3412L23.4908 16.6158C23.59 16.5635 23.7128 16.603 23.7629 16.7033L25.895 20.9674C25.9714 21.1203 26.1921 21.1131 26.2583 20.9554L27.8924 17.0647C27.9354 16.9623 28.0537 16.9146 28.1558 16.9584L32.5514 18.8478C32.7251 18.9224 32.8958 18.7366 32.8068 18.5698L30.3804 14.0251C30.3174 13.9072 30.3856 13.7619 30.5165 13.735L33.634 13.0931C33.8105 13.0568 33.8527 12.8237 33.7 12.7278L30.7494 10.8746C30.6696 10.8245 30.6356 10.7254 30.6679 10.6368L32.6962 5.07342C32.7634 4.88912 32.5527 4.72831 32.3926 4.84175Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.732837 7.14998C0.548482 7.38626 0.479812 7.69276 0.545733 7.98511L0.984829 9.93241C1.03929 10.1739 1.18143 10.3867 1.38372 10.5295L2.96014 11.642C3.19129 11.8052 3.48073 11.8628 3.75672 11.8005L4.65833 11.5972L6.93927 21.7133C7.06075 22.252 7.59598 22.5903 8.13474 22.4688L10.9436 21.8355C11.4823 21.714 11.8206 21.1788 11.6991 20.64L9.41817 10.524L11.127 10.1387C11.6657 10.0172 12.004 9.482 11.8825 8.94324L10.8982 4.57769C10.7767 4.03892 10.2415 3.70065 9.70271 3.82215L2.31712 5.48768C2.09171 5.53851 1.89085 5.66584 1.7487 5.84802L0.732837 7.14998ZM5.87772 11.3223L8.19879 10.799L10.4247 20.6711L8.10367 21.1944L5.87772 11.3223Z"
        fill={color}
      />
    </svg>
  );
}
