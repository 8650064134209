import { ReactNode } from 'react';

export interface TableProps {
  children?: ReactNode | JSX.Element | string | undefined;
}

export function Table({ children, ...rest }: TableProps) {
  return (
    <table className="table-container" {...rest}>
      {children}
    </table>
  );
}
