import { createContext } from 'react';
import { ExternalUserVerifyContextResult } from '../types/types';

export interface ExternalUserVerifyContextProps {
  checkAuthorization: () => Promise<ExternalUserVerifyContextResult> | null;
}

export const ExternalUserVerifyContext =
  createContext<ExternalUserVerifyContextProps>({
    checkAuthorization: () => {
      return null;
    },
  });
