import { SortDirection } from "../types/types";

export const sortStrings = (
  aa: string,
  bb: string,
  currentSortDirection: SortDirection
): number => {
  const val =
    currentSortDirection === 'none' ? 0 : currentSortDirection === 'descending' ? 1 : -1;
  return aa > bb ? val : aa < bb ? -val : 0;
};

export const sortBooleans = (
  aa: boolean,
  bb: boolean,
  currentSortDirection: SortDirection
): number => {
  const val =
    currentSortDirection === 'none' ? 0 : currentSortDirection === 'descending' ? 1 : -1;
  return aa > bb ? val : aa < bb ? -val : 0;
};

export const toggleSortDirection = (sortDir: SortDirection):SortDirection => {
  return sortDir === "ascending" ?
    "descending" :
    sortDir === "descending" ?
      "ascending" :
      sortDir === "none" ?
        "descending" :
        "none";
}