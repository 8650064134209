import { useContext, useState } from 'react';
import { ResetFiltersButton } from './ResetFiltersButton';
import { Link } from 'react-router-dom';
import {
  PatientListSortType,
  PatientRecord,
  Section,
  Sort,
} from '../../shared/types/types';
import { Privacy } from '../../components/Privacy/Privacy';
import {
  largeSpinner,
  LoadingSpinner,
} from '../../components/common/loader/LoadingSpinner';
import './PatientList.scss';
import '../../components/common/loader/LoadingSpinner.scss';
import { usePatientList } from '../../controllers/patient';
import {
  sortBooleans,
  sortStrings,
  toggleSortDirection,
} from '../../shared/util';
import { PatientListHeader } from './header/PatientListHeader';
import { Checkbox } from '../../components/common/buttons/checkbox/CheckBox';
import { Card } from '../../components/Card/Card';
import { Search } from '../../components/common/search/Search';
import { GlobalUserContext } from '../../shared/contexts/GlobalUserContext';
import { PrivacyContext } from '../../shared/contexts/PrivacyContext';
import { convertDateTime } from '../../components/common/convertDateTime/convertDateTime';
import { Table } from '../../components/common/table/Table/Table';
import { TBody } from '../../components/common/table/TBody/TBody';
import { Row } from '../../components/common/table/Row/Row';
import { Cell } from '../../components/common/table/Cell/Cell';

export interface PatientListProps {
  section: Section;
}

type InfusionFilter = 'none' | 'exclude_na' | 'only_na';

export function PatientList({ section }: PatientListProps) {
  //********* Contexts ************
  //*******************************

  const { setPatientInfo } = useContext(GlobalUserContext);
  const privacyContext = useContext(PrivacyContext);

  //********* State ************
  //****************************

  const [expanded, setExpanded] = useState(true);
  const [searchText, setSearchText] = useState<string>('');
  const [infusionFilter, setInfusionFilter] =
    useState<InfusionFilter>('none');
  const [patientListSort, setPatientListSort] = useState<Sort>({
    type: 'lastName',
    direction: 'descending',
  });
  const [showArchived, setShowArchived] = useState(false);

  //********* Data and functions ************
  //*****************************************

  const { patientListData, patientListDataIsLoading } =
    usePatientList(showArchived);

  const sort = (
    unsortedPatientList: PatientRecord[]
  ): PatientRecord[] => {
    let sortedList: PatientRecord[] = [];

    if (patientListSort.type === 'firstName') {
      sortedList = unsortedPatientList.sort((aa, bb) =>
        sortStrings(
          aa.encrypt_givenName,
          bb.encrypt_givenName,
          patientListSort.direction
        )
      );
    }

    if (patientListSort.type === 'lastName') {
      sortedList = unsortedPatientList.sort((aa, bb) =>
        sortStrings(
          aa.encrypt_familyName,
          bb.encrypt_familyName,
          patientListSort.direction
        )
      );
    }

    if (patientListSort.type === 'patientId') {
      sortedList = unsortedPatientList.sort((aa, bb) =>
        sortStrings(
          aa.encrypt_medicalId,
          bb.encrypt_medicalId,
          patientListSort.direction
        )
      );
    }

    if (patientListSort.type === 'archived') {
      sortedList = unsortedPatientList.sort((aa, bb) =>
        sortBooleans(
          aa.archived,
          bb.archived,
          patientListSort.direction
        )
      );
    }
    return sortedList;
  };

  const filterPatientRecord = (pat: PatientRecord): boolean => {
    if (
      (infusionFilter === 'only_na' && pat.lastInfusionDateTime) ||
      (infusionFilter === 'exclude_na' && !pat.lastInfusionDateTime)
    ) {
      return false;
    }
    if (!searchText) {
      return true;
    }
    const targetText =
      pat.lastInfusionDateTime === null
        ? 'N/A'
        : convertDateTime(pat.lastInfusionDateTime ?? '') +
          ' ' +
          pat.encrypt_givenName +
          ' ' +
          pat.encrypt_familyName +
          ' ' +
          pat.encrypt_medicalId;
    const targetLowerCaseText = targetText.toLocaleLowerCase();
    let allFound = true;
    searchText
      .toLowerCase()
      .split(' ')
      .forEach((word) => {
        if (!targetLowerCaseText.includes(word)) {
          allFound = false;
          return;
        }
      });
    return allFound;
  };

  let sortedPatientList = !patientListData
    ? []
    : sort(
        section === 'infusion'
          ? patientListData.filter(
              (patient) => patient.lastInfusionDateTime !== null
            )
          : patientListData
      ).filter((patient) => filterPatientRecord(patient));

  const handleFilterDateClick = () => {
    if (infusionFilter === 'none') {
      setInfusionFilter('exclude_na');
    } else if (infusionFilter === 'exclude_na') {
      setInfusionFilter('only_na');
    } else {
      setInfusionFilter('none');
    }
  };

  const handleSort = (type: PatientListSortType) => {
    const newDirection =
      patientListSort.type === type
        ? toggleSortDirection(patientListSort.direction)
        : 'descending';

    setPatientListSort({
      type: type,
      direction: newDirection,
    });
  };

  const handleResetFiltersClick = () => {
    setSearchText('');
    setInfusionFilter('none');
  };

  const handleNavigateToPatient = (data: PatientRecord) => {
    setPatientInfo(data);
  };

  const handleShowArchivedClick = () => {
    const newShowArchived = !showArchived;
    setShowArchived(newShowArchived);
  };

  const toggleArchived = (
    <div
      className="toggle-archived-container"
      onClick={handleShowArchivedClick}
    >
      <div className="label">Show Archived</div>
      <Checkbox checked={showArchived} />
    </div>
  );

  const content = (
    <div className="patient-list-container">
      <div className="list-container">
        <div className="search-header">
          <Search
            onChange={(evt) => setSearchText(evt.target.value)}
            value={searchText}
          />
          {toggleArchived}
          <ResetFiltersButton onClick={handleResetFiltersClick} />
        </div>
        <Table>
          <PatientListHeader
            onFilterDateClick={handleFilterDateClick}
            onSortClick={handleSort}
            showArchived={showArchived}
            sort={patientListSort}
          />
          <TBody>
            {sortedPatientList.map(
              (data: PatientRecord, index: number) => (
                <Row index={index} key={data._id}>
                  <Cell>
                    {data.lastInfusionDateTime === null
                      ? 'N/A'
                      : convertDateTime(
                          data.lastInfusionDateTime ?? ''
                        )}
                  </Cell>
                  <Cell>{data.encrypt_givenName}</Cell>
                  <Cell>{data.encrypt_familyName}</Cell>
                  <Cell>{data.encrypt_medicalId}</Cell>
                  {showArchived && (
                    <Cell>{data.archived ? 'ARCHIVED' : ''}</Cell>
                  )}
                  <Cell>
                    <Link
                      to={`/patients/${data._id}`}
                      onClick={() => handleNavigateToPatient(data)}
                    >
                      View Details
                    </Link>
                  </Cell>
                </Row>
              )
            )}
          </TBody>
        </Table>
        <div className={'loading-list-container'}>
          {patientListDataIsLoading && (
            <LoadingSpinner
              width={largeSpinner}
              height={largeSpinner}
              classes="loading-list-spinning-icon"
            />
          )}
        </div>
      </div>

      {privacyContext.active && (
        <Privacy resetPrivacy={privacyContext.resetPrivacy} />
      )}
    </div>
  );

  return (
    <Card
      title={'Patient List'}
      body={content}
      expandable={true}
      expanded={expanded}
      onExpand={(exp) => setExpanded(exp)}
    />
  );
}
