import { useEffect } from 'react';
import { WebAuthConfig } from '../../../../shared/types/types';
import { LoadingSpinner } from '../../../common/loader/LoadingSpinner';
import { mediumSpinner } from '../../../common/loader/LoadingSpinner';
import './PopupAuthenticating.scss';

export interface PopupAuthenticatingProps {
  webAuth: auth0.WebAuth;
  webAuthConfig: WebAuthConfig;
}

export function PopupAuthenticating({
  webAuth,
  webAuthConfig,
}: PopupAuthenticatingProps) {
  // just log out once with this useEffect
  useEffect(() => {
    webAuth.logout({
      returnTo: webAuthConfig.returnTo, // returns to the PopupLogin component
      clientID: webAuthConfig.clientID,
    });
  }, [webAuth, webAuthConfig]);

  return (
    <div className="popup-authenticating-container">
      <div className={'title'}>Authenticating...</div>
      <LoadingSpinner
        width={mediumSpinner}
        height={mediumSpinner}
        classes={'loading-spinner'}
      />
    </div>
  );
}
