import { useState } from 'react';
import { Card } from '../../../components/Card/Card';
import {
  PatientRecord,
  TreatmentPlanRecord,
} from '../../../shared/types/types';
import { TreatmentPlansContent } from './content/TreatmentPlansContent';
import { TreatmentPlansList } from './plansList/TreatmentPlansList';
import './TreatmentPlans.scss';
import {
  LoadingSpinner,
  mediumSpinner,
} from '../../../components/common/loader/LoadingSpinner';

export interface TreatmentPlansServiceResult {
  status: string;
  executionTime?: number;
  patients: Array<TreatmentPlanRecord>;
  errors: string[];
}
export interface TreatmentPlanAjaxResult {
  result: TreatmentPlansServiceResult;
}
export interface TreatmentPlansProps {
  patientData: PatientRecord | undefined;
  treatmentPlanData: TreatmentPlanRecord[];
  onUpdateTreatmentPlan: () => {};
  isLoading: boolean;
  isUpdatingTreatmentPlan: boolean;
  error?: any;
}

export function TreatmentPlans({
  patientData,
  treatmentPlanData,
  error,
  isLoading,
  isUpdatingTreatmentPlan,
  onUpdateTreatmentPlan,
}: TreatmentPlansProps) {
  const [expanded, setExpanded] = useState(true);

  const handleOnExpand = (expanded: boolean) => {
    setExpanded(expanded);
  };
  const [selectedTreatmentPlanIndex, setSelectedTreatmentPlanIndex] =
    useState<number>(-1);

  const handleSelectTreatmentPlan = (planIndex: number) => {
    setSelectedTreatmentPlanIndex(planIndex);
  };

  const body =
    expanded && patientData ? (
      isLoading ? (
        <div className="loading-container">
          <LoadingSpinner
            width={mediumSpinner}
            height={mediumSpinner}
          />
        </div>
      ) : (
        <div className="treatment-plans-container">
          <TreatmentPlansList
            onSelect={handleSelectTreatmentPlan}
            patientData={patientData}
            treatmentPlanData={treatmentPlanData}
          />
          {selectedTreatmentPlanIndex !== -1 && (
            <TreatmentPlansContent
              patientData={patientData}
              planData={treatmentPlanData[selectedTreatmentPlanIndex]}
              onUpdateTreatmentPlan={onUpdateTreatmentPlan}
              isUpdatingTreatmentPlan={isUpdatingTreatmentPlan}
            />
          )}
        </div>
      )
    ) : (
      <></>
    );

  return (
    <div className="treatment-plans-card">
      <Card
        alignTitle="start"
        title={'PLAN INFORMATION'}
        body={body}
        expandable
        expanded={expanded}
        onExpand={handleOnExpand}
      />
    </div>
  );
}
