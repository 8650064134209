import { useContext, useEffect, useState } from 'react';
import { DeleteNoteProps, UpdateNoteProps } from '.';
import {
  decryptObject,
  encryptObject,
} from '../../../../../components/Encryption/obfuscationHandler';
import { AddNote } from '../../../../../components/WysiwygEditor/note/addNote/AddNote';
import {
  CAS_BACK_END_API_URL,
  URL_VERSION,
  WEB_OWNER,
} from '../../../../../constants';
import { fetchAuthenticated } from '../../../../../controllers';
import { GlobalUserContext } from '../../../../../shared/contexts/GlobalUserContext';
import {
  SetupNoteRecord,
  SetupNotesAjaxResult,
} from '../../../../../shared/types/types';
import { SetupNoteList } from './list/SetupNoteList';
import './SetupNotes.scss';

export interface SetupNotesProps {
  patientId: string;
}

export function SetupNotes({ patientId }: SetupNotesProps) {
  const [setupNoteListData, setSetupNoteListData] = useState<
    SetupNoteRecord[]
  >([]);
  const [setupNoteListIsLoading, setSetupNoteListIsLoading] =
    useState<boolean>(false);
  const [setupNoteListError, setSetupNoteListError] = useState<any>();
  const [isUpdatingNote, setIsUpdatingNote] = useState(false);
  const { token } = useContext(GlobalUserContext);
  const [isLoadingNewNote, setIsLoadingNewNote] = useState(false);

  const getSetupNoteListData = async () => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/setup-notes`;
    const config: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setSetupNoteListIsLoading(true);
      const response = await fetchAuthenticated<SetupNotesAjaxResult>(
        url,
        config
      );

      if (response.ok) {
        if (
          response.parsedBody?.result &&
          response.parsedBody?.result.setupNotes.length > 0
        ) {
          const encrypedData = {
            ...response.parsedBody?.result,
          };
          await decryptObject(encrypedData, token, null);
          setSetupNoteListData(encrypedData.setupNotes);
        }
      }
      setSetupNoteListIsLoading(false);
    } catch (error: any) {
      setSetupNoteListError(error);
      setSetupNoteListIsLoading(false);
    }
  };

  const updateSetupNote = async (
    id: string,
    newContent: string,
    version: number
  ) => {
    if (!id) return;
    if (!version) return;
    if (!newContent) return;
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/setup-notes/${id}`;
    const bodyData = {
      encrypt_setupNote: newContent,
      _version: version,
    };

    const config: RequestInit = {
      method: 'PATCH',
      body: JSON.stringify(
        await encryptObject(bodyData, token, null)
      ),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      setIsUpdatingNote(true);
      const response = await fetchAuthenticated<SetupNotesAjaxResult>(
        url,
        config
      );
      if (response.ok) {
        if (
          response.parsedBody?.result &&
          response.parsedBody?.result.setupNotes.length > 0
        ) {
          const result = await decryptObject(
            response.parsedBody.result,
            token,
            null
          );
          const patchedSetupNote = result.setupNotes[0];
          let newSetupNoteList = [];
          const patchedSetupNoteId = patchedSetupNote._id;
          for (let i = 0; i < setupNoteListData?.length; i++) {
            if (setupNoteListData[i]._id !== patchedSetupNoteId) {
              newSetupNoteList.push(setupNoteListData[i]);
            } else {
              newSetupNoteList.push(patchedSetupNote);
            }
          }
          setSetupNoteListData(newSetupNoteList);
        }
      }
      setIsUpdatingNote(false);
    } catch (error: any) {
      setSetupNoteListError(error);
      setIsUpdatingNote(false);
    }
  };

  const deleteSetupNote = async (id: string, version: number) => {
    if (!id) return;
    if (!version) return;
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/setup-notes/${id}`;
    const bodyData = {
      _version: version,
      archive: true,
      owner: WEB_OWNER,
    };

    const config: RequestInit = {
      method: 'DELETE',
      body: JSON.stringify(
        await encryptObject(bodyData, token, null)
      ),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setIsLoadingNewNote(true);
      const response = await fetchAuthenticated<SetupNotesAjaxResult>(
        url,
        config
      );

      if (response.ok) {
        if (
          response.parsedBody?.result &&
          response.parsedBody?.result.setupNotes.length > 0
        ) {
          const encrypedData = {
            ...response.parsedBody?.result,
          };
          await decryptObject(encrypedData, token, null);
          const newFilteredList = [...setupNoteListData].filter(
            (note) => note._id !== id
          );
          setSetupNoteListData(newFilteredList);
        }
      }
      setIsLoadingNewNote(false);
    } catch (error: any) {
      setSetupNoteListError(error);
      setIsLoadingNewNote(false);
    }
  };

  const createSetupNote = async (body: string) => {
    const url = `${CAS_BACK_END_API_URL}${URL_VERSION}/patients/${patientId}/setup-notes`;
    const bodyData = {
      encrypt_setupNote: body,
      owner: WEB_OWNER,
    };

    const config: RequestInit = {
      method: 'PUT',
      body: JSON.stringify(
        await encryptObject(bodyData, token, null)
      ),
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      setIsLoadingNewNote(true);
      const response = await fetchAuthenticated<SetupNotesAjaxResult>(
        url,
        config
      );

      if (response.ok) {
        if (
          response.parsedBody?.result &&
          response.parsedBody?.result.setupNotes.length > 0
        ) {
          const encrypedData = {
            ...response.parsedBody?.result,
          };
          await decryptObject(encrypedData, token, null);
          const newNote = encrypedData.setupNotes[0];
          setSetupNoteListData([...setupNoteListData, newNote]);
        }
      }
      setIsLoadingNewNote(false);
    } catch (error: any) {
      setSetupNoteListError(error);
      setIsLoadingNewNote(false);
    }
  };

  const handleUpdateNote = ({
    id,
    newContent,
    version,
  }: UpdateNoteProps) => {
    updateSetupNote(id, newContent, version);
  };

  const handleDeleteNote = ({ id, version }: DeleteNoteProps) => {
    deleteSetupNote(id, version);
  };

  const handleCreateNote = (body: string) => {
    return new Promise<boolean>(async (resolve) => {
      await createSetupNote(body);
      resolve(true);
    });
  };

  useEffect(() => {
    getSetupNoteListData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="setup-notes-container" data-cy="setup-notes">
      <SetupNoteList
        data={setupNoteListData}
        isLoading={setupNoteListIsLoading}
        error={setupNoteListError}
        onUpdateNote={handleUpdateNote}
        onDeleteNote={handleDeleteNote}
        isUpdatingNote={isUpdatingNote}
      />
      <AddNote
        isLoadingNewNote={isLoadingNewNote}
        onSubmit={handleCreateNote}
      />
    </div>
  );
}
