import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '../../pages/dashboard/Dashboard';
import { PatientList } from '../../pages/patientList/PatientList';
import { Commissioning } from '../../pages/commissioning/Commissioning';
import { MachineSpec } from '../../pages/machineSpec/MachineSpec';
import { Patient } from '../../pages/patient/Patient';
import { Service } from '../../pages/service/Service';
import { AuditTrail } from '../../pages/auditTrail/AuditTrail';
import { Section } from '../../shared/types/types';
import { BannerMessages } from '../errors/banners/BannerMessages';
import { BannerMessage } from '../../shared/contexts/BannerMessageContext';
import './Main.scss';

export interface MainProps {
  section: Section;
  onSectionClick: (section: Section) => void;
  onInfusionLoad: () => any;
  onLoginClick: () => void;
  bannerMessages: BannerMessage[];
  onCloseBanner: (id: string) => void;
}

export function Main({
  section,
  onLoginClick,
  onSectionClick,
  onInfusionLoad,
  bannerMessages,
  onCloseBanner,
}: MainProps) {
  return (
    <section className="main-content">
      <BannerMessages
        messages={bannerMessages}
        onCloseBanner={onCloseBanner}
      />
      <Routes>
        <Route
          path="/"
          element={
            <Dashboard
              onSectionClick={onSectionClick}
              onLoginClick={onLoginClick}
            />
          }
        />
        <Route
          path="/records"
          element={<PatientList section={section} />}
        />

        <Route
          path="/infusion"
          element={<PatientList section={section} />}
          loader={onInfusionLoad}
        />
        <Route path="/commissioning/" element={<Commissioning />} />
        <Route path="/machine-spec/" element={<MachineSpec />} />
        <Route
          path="/patients/:id"
          element={<Patient section={section} />}
        />
        <Route path="/service" element={<Service />} />
        <Route path="/audit-trail" element={<AuditTrail />} />
      </Routes>
    </section>
  );
}
